import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet-async';
import { withTheme } from 'styled-components';

import { Centered, Img, Wrapper } from '../../../utils/styles/misc';
import { Body, H1 } from '../../../utils/styles/text';
import ContactForm from '../../misc/ContactForm';

class WorkWithUs extends Component {
  render() {
    return (
        <>
        <Wrapper style={{paddingBottom: "0"}}>
        {/* <FullWidthHeaderImg src={require("../../../assets/images/banners/banner6.jpg")} /> */}
            <Helmet>
                <title>Work With Us {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
            </Helmet>
            <Grid fluid>
                <Row center="xs">
                    <Col xs={12}>
                        <H1>Work With Us</H1>
                        <Body>Send us an email at info@clikclak.com, or fill out the form below.</Body>
                    </Col>
                </Row>
                
            </Grid>
        </Wrapper>
        <Grid fluid style={{padding: "0 0 0 0", marginTop: "50px"}}>
            <Row style={{padding: "0"}}>
                <Col lg={12} xl={6} style={{padding: "0"}}>
                    <Centered>
                        <Img 
                            src={require("../../../assets/images/misc/WORK-WITH-US2.jpg")}
                            style={{maxWidth: "70%"}}
                        />
                    </Centered>
                </Col>
                <Col lg={12} xl={6}>
                    <ContactForm />
                </Col>
            </Row>
        </Grid>
        </>
    );
  }
}

export default withTheme(WorkWithUs)