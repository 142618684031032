import React, { useState, useCallback, useEffect } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { FaSearchPlus } from "react-icons/fa";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { SCHEMES, SIZES } from "../../utils/constants";

import { GalleryImg, Img } from "../../utils/styles/misc";
import { Body } from "../../utils/styles/text";

export default function PhotoGallery(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [sortedPhotos, setSortedPhotos] = useState(false);
    const [focusedImageIndex, setFocusedImageIndex] = useState(0)
    const [width, setWidth] = React.useState(window.innerWidth);
    

    const scrollGal = (direction = "") => {
        const scrollWid = width < 900 ? 55 : 105;
        
        if (direction === "right") {
            document.getElementById("img-gal").scrollBy({
                top: 0,
                left: scrollWid,
                behavior: "smooth"
            })
        } else if (direction === "left") {
            document.getElementById("img-gal").scrollBy({
                top: 0,
                left: -scrollWid,
                behavior: "smooth"
            })
        } else {
            console.log('Defaulted..')
        }
    }

    useEffect(() => {
        let tempPhotos = [...props.photos];
        var indexOfThumb = tempPhotos.indexOf(props.thumb);
        console.log("indexOfThumb " + indexOfThumb)
        if (indexOfThumb !== -1) {
            tempPhotos.splice(indexOfThumb, 1); // take thumb out
            tempPhotos.unshift(props.thumb); //put thumb back in at beginning
        }
        setSortedPhotos(tempPhotos);
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, [props.photos, props.thumb])
    

    const openLightbox = useCallback((index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };

    return (
      <div style={{marginRight:"5px"}}>
        <div>
            <Img
                style={{ border: `4px solid ${props.theme.colors.primary}`, cursor: "pointer" }} 
                width="500px"
                src={sortedPhotos[focusedImageIndex]}
                onClick={() => openLightbox(focusedImageIndex)} 
            />
            <Body 
                size={SIZES.XS}
                hovercolor={props.theme.colors.primary}
                margin={width > 610 ? "0 0 0 210px" : "0 0 0 28vw"}
                onClick={() => openLightbox(focusedImageIndex)} 
            >
                <FaSearchPlus/>&nbsp;Click to enlarge
            </Body>
        </div>
        <div style={{ marginLeft: "-10px", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <BsChevronLeft
                color={props.theme.value !== SCHEMES.DARK ? props.theme.colors.primary : props.theme.colors.tertiary}
                size={width < 1300 ? 35 : 50} 
                className="no-selection"
                style={{ cursor: "pointer", display: sortedPhotos.length !== 1 ? "inline" : "none" }}
                // Set big image to prev in set, but dont exceed total set
                onClick={() => {
                    setFocusedImageIndex(
                        (focusedImageIndex !== 0)
                        ? 
                        focusedImageIndex - 1 
                        :  
                        focusedImageIndex
                    );
                    scrollGal("left");
                }
                }
            />
            <div id="img-gal" style={{ display: "flex", alignItems: "center", width: (width > 610 ? "420px" : "85%"), overflowX: "hidden" }}>
                {
                    sortedPhotos && sortedPhotos.map((photo, i) => {
                        return (
                            <GalleryImg
                                margin="5px"
                                src={photo} 
                                alt={props.alt}
                                onClick={() => setFocusedImageIndex(i)} 
                                key={i}
                            />
                        )
                    })
                }
            </div>
            <BsChevronRight 
                color={props.theme.value !== SCHEMES.DARK ? props.theme.colors.primary : props.theme.colors.tertiary}
                size={ width < 1300 ? 35 : 50 }
                style={{ marginLeft: (width > 610 ? "475px" : "75vw"), position: 'absolute', cursor: "pointer", display: sortedPhotos.length !== 1 ? "inline" : "none" }}
                className="no-selection"
                // Set big image to next in set, but dont exceed total set
                onClick={() => {
                    setFocusedImageIndex(
                        (focusedImageIndex !== sortedPhotos.length - 1)
                        ? 
                        focusedImageIndex + 1 
                        :  
                        focusedImageIndex
                    );
                    scrollGal("right");
                    }
                }
            />
        </div>

        {viewerIsOpen ? (
            <Lightbox
                mainSrc={sortedPhotos[currentImage]}
                nextSrc={sortedPhotos[(currentImage + 1) % sortedPhotos.length]}
                prevSrc={sortedPhotos[(currentImage + sortedPhotos.length - 1) % sortedPhotos.length]}
                onCloseRequest={closeLightbox}
                onMovePrevRequest={() =>
                    setCurrentImage((currentImage + sortedPhotos.length - 1) % sortedPhotos.length)
                }
                onMoveNextRequest={() =>
                    setCurrentImage((currentImage + 1) % sortedPhotos.length)
                }
            />
        ) : null}
      </div>
    );
  }