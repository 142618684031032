import styled  from 'styled-components';
import { SCHEMES } from '../constants';
import { HeadingFont } from './text';

export const FooterContainer = styled.footer`
    position: absolute;
    z-index: 3;
    bottom: 0;
    width: 100%;
    margin: auto;
    text-align: center;
    display: flex; 
    flex-direction: column;
    font-size: 14px;
    background-color: ${props => props.theme.value !== SCHEMES.DARK ? "white" : "black"};
    a, p, span {
        text-transform: uppercase;
        color: ${props => props.theme.colors.font.body};
    }
    a, span {
        &:hover {
            color: ${props => props.theme.colors.primary} !important;
        }
    }

    #big-link {   
        ${HeadingFont}
        display: inline-block;
        font-size: 20px;
        margin: 5px 0;
        @media (max-width: 900px) {
            font-size: 22px;
        }
    }
`;