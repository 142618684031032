import React, { Component } from 'react';
import { FaSearch } from 'react-icons/fa';
import { withTheme } from 'styled-components'
import { SCHEMES } from '../../utils/constants';
import { withRouter } from '../../utils/hocs';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Burger, BurgerNavLink, NavLogo, HeaderContainer, BurgerNavContainer, BrandContainer, BrandLink, BurgerNavLeft, BurgerNavRight, BgOverlay, HSearchContainer, HeaderSearchDiv2, HeaderSearchDiv1, TopNav, SearchIcon, CloseIcon, NavTitle } from '../../utils/styles/header';
import { SearchField } from '../../utils/styles/forms';

const NAV_HEADINGS = {
    HOME: {
        LABEL: "Home",
        PATH: "/",
    },
    PRODUCTS: {
        LABEL: "Products",
        PATH: "products",
    },
    WHAT_WE_DO: {
        LABEL: "What We Do",
        PATH: "what-we-do",
    },
    WHO_WE_ARE: {
        LABEL: "Who We Are",
        PATH: "who-we-are",
    },
    WORK_WITH_US : {
        LABEL: "Work With Us",
        PATH: "work-with-us",
    }
};

class Header extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        isBurgerMenuOpen: false,
        leftNavBg: "",
        changingCss: false,
        submittingSearch: false,
        isSearchHidden: true,
      }
    }
    
    componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);

        var searchIconElement = document.getElementById("search-icon");
        var closeIconElement = document.getElementById("close-icon");
        var navElement = document.querySelector("header");
        var brandingElement = document.getElementById("branding");
        if(this.props.location.pathname === NAV_HEADINGS.HOME.PATH || this.state.isBurgerMenuOpen){
            window.addEventListener("scroll", this.changeCss);
            // Setting to not show initially, without this you can see the fading 
            navElement.style.background = "transparent";
            brandingElement.style.display = "none";
            navElement.style.borderBottom = `none`;
            document.querySelectorAll(".bar").forEach((bar) => {
                bar.style.backgroundColor = "white";
            })
            searchIconElement.style.color = "white";
            closeIconElement.style.color = "white";
        } else {
            window.removeEventListener("scroll", this.changeCss);
            this.changeCss();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.pathname !== prevProps.location.pathname || this.state.isBurgerMenuOpen !== prevState.isBurgerMenuOpen) {
            this.changeCss();
            if(this.props.location.pathname === NAV_HEADINGS.HOME.PATH){
                window.addEventListener("scroll", this.changeCss);
            } else {
                window.removeEventListener("scroll", this.changeCss);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.changeCss);
        window.removeEventListener('resize', this.updateDimensions);
    } 

    submitSearch = (values) => {
        if(values.querySet.length < 2) {
            toast.error("Search must be 2 or more characters!");
        } else if(values.querySet.length > 150) {
            toast.error("Search must be 150 or less characters!");
        } else {
            this.props.navigate(`/products/query/search=${values.querySet}=page_0`);
            // window.location.reload();
        }
    } 

    setBurgerMenuOpen = (isBurgerMenuOpen) => {
        this.setState({
            isBurgerMenuOpen: isBurgerMenuOpen
        });
    };

    changeCss = () => {
        var searchIconElement = document.getElementById("search-icon");
        var closeIconElement = document.getElementById("close-icon");
        var navElement = document.querySelector("header");
        var brandingElement = document.getElementById("branding");
        if(this.props.location.pathname === NAV_HEADINGS.HOME.PATH){
            (window.scrollY > 700 || this.state.isBurgerMenuOpen) ? navElement.style.background = (this.props.theme.value !== SCHEMES.DARK ? "white" : "black") : navElement.style.background = "transparent";
            (window.scrollY > 700 || this.state.isBurgerMenuOpen) ? navElement.style.borderBottom = `5px solid ${this.props.theme.colors.primary}` : navElement.style.borderBottom = "none";
            (window.scrollY > 700 || this.state.isBurgerMenuOpen) ? brandingElement.style.display = "inline-flex" : brandingElement.style.display = "none";
            (window.scrollY > 700 || this.state.isBurgerMenuOpen)  ?
                document.querySelectorAll(".bar").forEach((bar) => {
                    bar.style.backgroundColor = this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : "white";
                })
                :
                document.querySelectorAll(".bar").forEach((bar) => {
                    bar.style.backgroundColor = "white";
                });
            (window.scrollY > 700 || this.state.isBurgerMenuOpen)  ? 
                (searchIconElement.style.color = this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : "white")
                :
                searchIconElement.style.color = "white";
            (window.scrollY > 700 || this.state.isBurgerMenuOpen)  ? 
                (closeIconElement.style.color = this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : "white")
                :
                closeIconElement.style.color = "white";
        } else {
            navElement.style.background = (this.props.theme.value !== SCHEMES.DARK ? "white" : "black");
            navElement.style.borderBottom = `5px solid ${this.props.theme.colors.primary}`
            brandingElement.style.display = "inline-flex";
            document.querySelectorAll(".bar").forEach((bar) => {
                bar.style.backgroundColor = (this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : "white");
            })
            searchIconElement.style.color = (this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : "white");
            closeIconElement.style.color = (this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : "white");
        }
    }
      

    render() {
        return (
            <>
            <HeaderContainer>
                <TopNav isSearchHidden={this.state.isSearchHidden}>
                    <BrandContainer>
                        <BrandLink to="/" id="branding">
                            <NavLogo 
                                width={this.props.site.logo.width}
                                // src={this.props.site.logo.url} 
                                onClick={() => this.setBurgerMenuOpen(false)}
                                src={this.props.theme.value !== SCHEMES.DARK ? require("../../assets/images/logos/cc-logo-v4.png") : require("../../assets/images/logos/cc-logo-white-v4.png")}  
                            />
                            <NavTitle>From conception to completion, <b>we make it happen.</b></NavTitle>
                        </BrandLink>
                    </BrandContainer>

                    <HeaderSearchDiv1>
                        <Formik
                            initialValues={{
                                querySet: "",
                            }}
                            onSubmit={(values, actions) => {
                                this.setState({ submittingSearch: true })
                                this.submitSearch(values);
                                actions.resetForm();
                                this.setBurgerMenuOpen(false)
                            }}
                            enableReinitialize={true}
                        >
                            {props => (
                                <Form style={{ padding: "0 25px 0 0"}}>
                                    <div>
                                        <HSearchContainer>
                                            <button type="submit" style={{background: "none", padding: "0px", border: "none"}}>
                                                <FaSearch size={15} /> 
                                            </button>
                                            
                                            <SearchField
                                                type="text"
                                                required
                                                onChange={props.handleChange}
                                                placeholder={`Search for a product`}
                                                name="querySet"
                                                value={props.values.querySet || ''}
                                                // onKeyUp={() => this.setState({ errors: { querySet: false } })}
                                                // onClick={() => this.setState({ errors: { querySet: false } })}
                                                error={ ((props.errors.querySet && props.touched.querySet) || this.state?.errors?.querySet) ? 1 : 0 }
                                            />
                                        </HSearchContainer>
                                    </div>
                                    
                                </Form>
                            )}
                        </Formik>
                    </HeaderSearchDiv1>
                    <div>
                        <SearchIcon 
                            id="search-icon" 
                            size={45} 
                            onClick={() => this.setState({ isSearchHidden: !this.state.isSearchHidden })}
                            hidden={!this.state.isSearchHidden}
                            ispathhome={this.props.location.pathname === NAV_HEADINGS.HOME.PATH ? true : undefined}
                        />
                        <Burger 
                            type="button"
                            open={this.state.isBurgerMenuOpen} 
                            onClick={() => this.setBurgerMenuOpen(!this.state.isBurgerMenuOpen)}
                        >
                            <div className="bar" />
                            <div className="bar" />
                            <div className="bar" />
                        </Burger>
                    </div>
                    
                    <HeaderSearchDiv2 hidden={this.state.isSearchHidden}>
                        <Formik
                            initialValues={{
                                querySet: "",
                            }}
                            onSubmit={(values, actions) => {
                                this.setState({ submittingSearch: true })
                                this.submitSearch(values);
                                actions.resetForm();
                            }}
                            enableReinitialize={true}
                        >
                            {props => (
                                <Form>
                                    <div>
                                        <HSearchContainer>
                                            <button type="submit" style={{background: "none", padding: "0px", border: "none"}}>
                                                <FaSearch size={15} /> 
                                            </button>
                                            
                                            <SearchField
                                                type="text"
                                                required
                                                onChange={props.handleChange}
                                                placeholder={`Search for a product`}
                                                name="querySet"
                                                value={props.values.querySet || ''}
                                                // onKeyUp={() => this.setState({ errors: { querySet: false } })}
                                                // onClick={() => this.setState({ errors: { querySet: false } })}
                                                error={ ((props.errors.querySet && props.touched.querySet) || this.state?.errors?.querySet) ? 1 : 0 }
                                            />
                                        </HSearchContainer>
                                    </div>
                                    
                                </Form>
                            )}
                        </Formik>
                        <CloseIcon
                            id="close-icon" 
                            size={25} 
                            onClick={() => this.setState({ isSearchHidden: !this.state.isSearchHidden })}
                            hidden={this.state.isSearchHidden}
                            ispathhome={this.props.location.pathname === NAV_HEADINGS.HOME.PATH ? true : undefined}
                        />
                    </HeaderSearchDiv2>
                </TopNav>
                
                <BgOverlay open={this.state.isBurgerMenuOpen}>
                    <BurgerNavContainer>
                        <BurgerNavLeft 
                            bgUrl={require("../../assets/images/product-shots/5.png")} 
                            open={this.state.isBurgerMenuOpen}
                            onClick={() => this.setBurgerMenuOpen(false)} 
                        >   
                            <div className="bg-section" />
                        </BurgerNavLeft>
                        <BurgerNavRight open={this.state.isBurgerMenuOpen} color="black"> 
                            <BurgerNavLink 
                                to={NAV_HEADINGS.HOME.PATH} 
                                onClick={() => this.setBurgerMenuOpen(false)}
                            >
                                <span>{NAV_HEADINGS.HOME.LABEL}</span>
                            </BurgerNavLink>
                            <BurgerNavLink 
                                to={NAV_HEADINGS.PRODUCTS.PATH} 
                                onClick={() => this.setBurgerMenuOpen(false)}
                            >
                                <span>{NAV_HEADINGS.PRODUCTS.LABEL}</span>
                            </BurgerNavLink>
                            <BurgerNavLink 
                                to={NAV_HEADINGS.WHAT_WE_DO.PATH} 
                                onClick={() => this.setBurgerMenuOpen(false)}
                            >
                                <span>{NAV_HEADINGS.WHAT_WE_DO.LABEL}</span>
                            </BurgerNavLink>
                            <BurgerNavLink 
                                to={NAV_HEADINGS.WHO_WE_ARE.PATH} 
                                onClick={() => this.setBurgerMenuOpen(false)}
                            >
                                <span>{NAV_HEADINGS.WHO_WE_ARE.LABEL}</span>
                            </BurgerNavLink>
                            <BurgerNavLink
                                to={NAV_HEADINGS.WORK_WITH_US.PATH} 
                                onClick={() => this.setBurgerMenuOpen(false)}
                            >
                                <span>{NAV_HEADINGS.WORK_WITH_US.LABEL}</span>
                            </BurgerNavLink>
                            
                            {/* {!this.props.user && (
                                <>
                                    <span>
                                        <BurgerNavLink 
                                            to="/login" 
                                            onClick={() => this.setBurgerMenuOpen(!this.state.isBurgerMenuOpen)}
                                        >
                                            Login
                                        </BurgerNavLink>
                                    </span>
                                    <span>
                                        <BurgerNavLink 
                                            to="/register" 
                                            onClick={() => this.setBurgerMenuOpen(!this.state.isBurgerMenuOpen)}
                                        >
                                            Register
                                        </BurgerNavLink>
                                    </span>
                                </>
                            )} */}
                            {this.props.user && (
                                <>
                                    <BurgerNavLink 
                                        to='/dashboard'
                                        onClick={() => this.setBurgerMenuOpen(false)}
                                    >
                                        Dashboard
                                    </BurgerNavLink>
                                </>
                            )}
                        </BurgerNavRight>
                    </BurgerNavContainer>
                </BgOverlay>
                
                
            </HeaderContainer>
            </>
        );
    }
}

export default withRouter(withTheme(Header));