import React, { Component } from 'react'
import { storage } from "../../Fire";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { withTheme } from "styled-components";
import { BsCloudUpload } from "react-icons/bs";
import { CgAttachment } from "react-icons/cg";
import { FileInput, FileInputLabel } from "../../utils/styles/forms.js";
import { Body, Label } from '../../utils/styles/text';
import { Img, Spinner } from '../../utils/styles/misc';
import { Button } from '../../utils/styles/buttons';
import { toast } from 'react-toastify';

class FileUpload extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            submittingUpdateUser: false,
            submittingFile: false,
            shownModals: [],
            files: [],
            uploadProgress: "",
            thumbIndex: 0,
        }
    }

    handleFileSelect = (e) => {
        this.setState({
            files: e.target.files
        })
    }

    uploadFile = async (incFiles) => {
        this.setState({
            submittingFile: true
        });

        let urls = [];
        let tempThumb = "";
        let files = null;
        if(!Array.from(incFiles).length){
            let dataTransfer = new DataTransfer();
            let file = new File([incFiles], incFiles.name);
            dataTransfer.items.add(file);
            files = dataTransfer.files;
        } else {
            files = incFiles;
        }

        // Put a blank slot in the array for the length of the filelist so we can put the file value back in the correct order when we downloadUrls later in this method
        Array.from(files).forEach(async (file, f, filesArr) => {
            await urls.push("");
        });

        console.log(files)
        Array.from(files).forEach(async (file, f, filesArr) => {
            // https://firebase.google.com/docs/storage/web/upload-files
            // Create the file metadata
            /** @type {any} */
            const metadata = {
                contentType: file.type
            };
            
            // Upload file and metadata to the object
            const storageRef = ref(storage, this.props.firestoreRef + file.name);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);
            // Listen for state changes, errors, and completion of the upload.
            await uploadTask.on("state_changed",
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    this.setState({
                        uploadProgress: Math.trunc(progress)
                    })
                    switch (snapshot.state) {
                        case "paused":
                        console.log("Upload is paused");
                        break;

                        case "running":
                        console.log("Upload is running");
                        break;

                        default:
                        console.log("Default case upload snapshot...");
                        break;
                    }
                }, 
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case "storage/unauthorized":
                        console.log("User doesn't have permission to access the object");
                        break;

                        case "storage/canceled":
                        console.log("User canceled the upload");
                        break;
                
                        case "storage/unknown":
                        console.log("Unknown error occurred, inspect error.serverResponse");
                        break;

                        default:
                        console.log("Default case upload snapshot...");
                        break;
                    }
                }, 
                async () => {
                    // Upload completed successfully, now we can get the download URL
                    await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log("f: " + f)
                        if(f === parseInt(this.state.thumbIndex)){
                            console.log("f does equal thumbIndex!! putting thumb....")
                            tempThumb = downloadURL;
                        } else {
                            console.log("f doesnt equal thumbindex")
                        }
                        urls[f] = downloadURL;
                    });
                    console.log("urls: ");
                    console.log(urls);

                     // Is the array fully complete yet?
                    if ( urls.every(element => element !== "") ) {
                        console.log("tempThumb");
                        console.log(tempThumb);
                        if(tempThumb){
                            this.props.onUploadSuccess(urls, tempThumb);
                            this.setState({
                                submittingFile: false
                            });
                        } else {
                            toast.error("Sorry, but that thumbnail choice doesn't work, please try another file or re-export the file! It may be taking too long to upload...")
                            this.setState({
                                submittingFile: false,
                                files: ""
                            });
                        }
                    }
                }
            );
            

           
        })
    }

    onThumbChange = (event) => {
        this.setState({
            thumbIndex: event.target.value
        })
        console.log("thumbIndex: " + event.target.value)
    }

    render() {
        if(this.state.submittingFile){
            return (<>
                <Body>
                    Submitting files... &nbsp;
                    {this.state.uploadProgress && (
                        <span>{this.state.uploadProgress}%</span>
                    )}&nbsp;
                    <Spinner /> 
                </Body> 
            </>
            )
        } else {
            return (
                <div>
                    <FileInputLabel htmlFor={this.props.name} selected={this.state.files.length > 0 ? true : false}>
                    {this.props.selectBtn ? this.props.selectFileBtn : <><CgAttachment /> Select new file{this.props.allowMultiple ? "s" : ""} </>}
                        <FileInput
                            id={this.props.name} 
                            type="file" 
                            accept={this.props.accepts} 
                            multiple={this.props.allowMultiple ? true : false}
                            onChange={this.handleFileSelect} 
                        />
                    </FileInputLabel>
                        {this.state.files.length > 0 && Array.from(this.state.files).map((file, f) => {
                            const fileSizeMb = (file.size / (1024 ** 2)).toFixed(2);
                            if(this.state.files.length > 1){
                                return (
                                    <div key={f} style={{ margin: "15px 0" }}>
                                        <Body margin="0" bold>
                                            {f + 1}. {file.name} <i>({fileSizeMb}Mb)</i>
                                        </Body>
                                        {this.props.accepts.includes("image") && (
                                            <Body margin="0 0 0 15px">
                                                <Img
                                                    width="100px"
                                                    alt="file preview"
                                                    src={URL.createObjectURL(file)}
                                                />
                                                <br/>
                                                <input 
                                                    type="radio" 
                                                    value={f} 
                                                    id={f}
                                                    name="thumb" 
                                                    defaultChecked={f === 0}
                                                    onChange={e => this.onThumbChange(e)}
                                                /> 
                                                Thumbnail?
                                                
                                            </Body>
                                        )}
                                    </div>
                                )
                            } else {
                                if(this.props.accepts.includes("image")){
                                    return (
                                        <div key={f} style={{ margin: "15px 0" }}>
                                            <Body>{file.name} <i>({fileSizeMb}Mb)</i></Body>
                                            <br />
                                            <Img
                                                width="300px"
                                                alt="file preview"
                                                src={URL.createObjectURL(file)}
                                            />
                                        </div>
                                        
                                    );
                                } else {
                                    return (
                                        <div key={f} style={{ margin: "15px 0" }}>
                                            <Label>{file.name} <i>{fileSizeMb}Mb</i></Label>
                                            <br />
                                            <embed 
                                                key={f}
                                                width="100%"
                                                height="auto"
                                                src={URL.createObjectURL(file)}
                                            />
                                        </div>
                                        
                                    );
                                }
                            }
                        })}
                        {this.state.files.length > 0 && (
                            <Button 
                                type="button" 
                                onClick={() => this.uploadFile(this.state.files.length > 1 ? this.state.files : this.state.files[0])}
                            >
                                Upload &amp; Save {this.props.name} &nbsp;<BsCloudUpload size={20} />
                            </Button>
                        )}
                </div>
            )
        }
       
    }
}

export default withTheme(FileUpload);