import React, { Component } from 'react'
import { doc, updateDoc, getDoc, deleteDoc, setDoc } from "firebase/firestore";  
import { withTheme } from 'styled-components';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Form, Formik } from 'formik';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';

import { Hr, Img, Spinner, Wrapper } from '../../../../utils/styles/misc'
import { Body, H2, Label } from '../../../../utils/styles/text'
import { firestore } from '../../../../Fire';
import { Button } from '../../../../utils/styles/buttons';
import { onKeyDown, urlify } from '../../../../utils/misc';
import { BTYPES, DEFAULT_PRODUCT_IMAGES, SIZES } from '../../../../utils/constants.js';
import { withRouter } from '../../../../utils/hocs';
import { updateProductSchema } from '../../../../utils/formSchemas';
import { FField, RField } from '../../../../utils/styles/forms';
import FormError from '../../../misc/FormError';
import FileUpload from '../../../misc/FileUpload';
import { TagsInput } from '../../../misc/Tags';
import { Tooltip } from '../../../../utils/misc';
import { BsImages } from 'react-icons/bs';

class UpdateProduct extends Component {
    constructor(props) {
        super(props)

        this.state = {
            product: "",
            productMia: false,
            submittingUpdateProduct: false,
            productImagesToUpload: [],
            thumb: "",
            assetsUrl: "",
            uploadImagesComplete: false,
            uploadAssetsComplete: false,
            pickingAssets: false,
            pickingProductPhotos: false,
            loadingProduct: true,
            quillModules: {
                clipboard: {
                    matchVisual: false,
                }
            }
        }
    }

    componentDidMount = async() => {
        const docRef = doc(firestore, "products", this.props.productId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let docWithMore = Object.assign({}, docSnap.data());
            docWithMore.id = docSnap.id;
            console.log("Document data: ", docWithMore);
            this.setState({
                product: docWithMore,
                loadingProduct: false,
            })
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            this.setState({
                productMia: true,
                loadingProduct: false,
            })
        }
    }

    updateProduct = async (values, resetForm) => {
        if(this.state.pickingProductPhotos && !this.state.uploadImagesComplete){
            toast.error(`Upload product images first!`);
        } else if(!this.state.product.description){
            toast.error(`Please enter a description for the product!`);
        } else if(values.videoUrl && !values.videoUrl.includes("app_id")){
            toast.error(`The Vimeo video URL must have an "app_id" in the link to work!`);
        } else if(this.state.pickingAssets && !this.state.uploadAssetsComplete) {
            toast.error(`Please finish uploading Assets or cancel the assets upload!`);
        } else {
            if (values.name !== this.state.product.name) {
                // Name change
                this.setState({ submittingUpdateProduct: true });
                // ** this is not updating the firestore doc ID to be the new name, so there is a chance that someone tries to create a name with the previous name (that doesnt exist anymore really) but it will fail out!
                // In this case the user just needs to delete the old product doc
                const docId = urlify(values.name);

                // Delete old product on firestore and algolia
                deleteDoc(doc(firestore, "products", this.state.product.id)).then(() => {
                    console.log("Successfully deleted product!")
                    this.props.productsIndex.deleteObject(this.state.product.id).then(() => {
                        console.log("Successfully deleted product on algolia")
                    });
                }).catch((error) => {
                    console.error("Error deleting product: " + error)
                });
                
                setDoc(doc(firestore, "products", docId), {
                    name: values.name,
                    sku: values.sku,
                    // For URL uploads like images and assetsUrl, we use the local base state for the incoming change, and the this.state.product.photos as the previous state (current)
                    photos: this.state.productImagesToUpload.length > 0 ? this.state.productImagesToUpload : this.state.product.photos,
                    thumb: (this.state.thumb ? this.state.thumb : (this.state.product.thumb ? this.state.product.thumb : (this.state.product.photos ? this.state.product.photos[0] : DEFAULT_PRODUCT_IMAGES.COMING_SOON))),
                    assetsUrl: this.state.assetsUrl || this.state.product.assetsUrl,
                    description: this.state.product.description,
                    _tags: this.state.product._tags || [],
                    isShown: values.isShown || false,
                    priceIncludes: values.priceIncludes || "",
                    runCharge: values.runCharge || "",
                    setupCharge: values.setupCharge || "",
                    sampleFee: values.sampleFee || "",
                    qtyPrice50: values.qtyPrice50 || "",
                    qtyPrice100: values.qtyPrice100 || "",
                    qtyPrice250: values.qtyPrice250 || "",
                    qtyPrice500: values.qtyPrice500 || "",
                    qtyPrice1000: values.qtyPrice1000 || "",
                    qtyPrice2500: values.qtyPrice2500 || "",
                    qtyPrice5000: values.qtyPrice5000 || "",
                    pricingMisc: this.state.product.pricingMisc || "",
                    specsIncludes: values.specsIncludes || "",
                    packagingIncluded: values.packagingIncluded || "",
                    leadTime: values.leadTime || "",
                    materials: values.materials || "",
                    closure: values.closure || "",
                    dimensions: values.dimensions || "",
                    colors: values.colors || "",
                    maxImprint: values.maxImprint || "",
                    maxColors: values.maxColors || "",
                    additionalSpecs: this.state.product.additionalSpecs,
                    addOns: this.state.product.addOns || "",
                    videoUrl: values.videoUrl || "",
                    timestamp: Date.now(),
                }).then(() => {
                    const indexedValues = {
                        objectID: docId,
                        name: values.name,
                        sku: values.sku,
                        description: this.state.product.description,
                        thumb: this.state.thumb || this.state.product.thumb,
                        isShown: values.isShown || false,
                        _tags: this.state.product._tags || [],
                        timestamp: Date.now(),
                    };

                    // Index the product with Algolia
                    this.props.productsIndex.saveObject(indexedValues);
                    this.setState({ 
                        submittingUpdateProduct: false 
                    });

                    this.setState({
                        submittingUpdateProduct: false,
                        productImagesToUpload: [],
                        uploadImagesComplete: false,
                        uploadAssetsComplete: false,
                        assetsUrl: "",
                    })
                    console.log(`Saved: `);
                    console.log(indexedValues);
                    
                    toast.success(`Updated product!`);
                    
                    this.props.toggleUpdateProduct("")
                    window.scrollTo(0, 0);
                }).catch(error => {
                    console.error(`Error submitting product: ${error}`)
                    toast.error(`Error submitting product: ${error}`);
                    this.setState({ submittingUpdateProduct: false });
                });
            } else {
                // No name change
                this.setState({ submittingUpdateProduct: true });
                const docRef = doc(firestore, "products", this.state.product.id);
                // ** this is not updating the firestore doc ID to be the new name, so there is a chance that someone tries to create a name with the previous name (that doesnt exist anymore really) but it will fail out!
                // In this case the user just needs to delete the old product doc
    
                updateDoc(docRef, {
                    name: values.name,
                    sku: values.sku,
                    // For URL uploads like images and assetsUrl, we use the local base state for the incoming change, and the this.state.product.photos as the previous state (current)
                    photos: this.state.productImagesToUpload.length > 0 ? this.state.productImagesToUpload : this.state.product.photos,
                    thumb: (this.state.thumb ? this.state.thumb : (this.state.product.thumb ? this.state.product.thumb : (this.state.product.photos ? this.state.product.photos[0] : DEFAULT_PRODUCT_IMAGES.COMING_SOON))),
                    assetsUrl: this.state.assetsUrl || this.state.product.assetsUrl,
                    description: this.state.product.description,
                    _tags: this.state.product._tags || [],
                    isShown: values.isShown || false,
                    priceIncludes: values.priceIncludes || "",
                    runCharge: values.runCharge || "",
                    setupCharge: values.setupCharge || "",
                    sampleFee: values.sampleFee || "",
                    qtyPrice50: values.qtyPrice50 || "",
                    qtyPrice100: values.qtyPrice100 || "",
                    qtyPrice250: values.qtyPrice250 || "",
                    qtyPrice500: values.qtyPrice500 || "",
                    qtyPrice1000: values.qtyPrice1000 || "",
                    qtyPrice2500: values.qtyPrice2500 || "",
                    qtyPrice5000: values.qtyPrice5000 || "",
                    pricingMisc: this.state.product.pricingMisc || "",
                    specsIncludes: values.specsIncludes || "",
                    packagingIncluded: values.packagingIncluded || "",
                    leadTime: values.leadTime || "",
                    materials: values.materials || "",
                    closure: values.closure || "",
                    dimensions: values.dimensions || "",
                    colors: values.colors || "",
                    maxImprint: values.maxImprint || "",
                    maxColors: values.maxColors || "",
                    additionalSpecs: this.state.product.additionalSpecs,
                    addOns: this.state.product.addOns || "",
                    videoUrl: values.videoUrl || "",
                    timestamp: Date.now(),
                }).then(() => {
                    const indexedValues = {
                        objectID: this.state.product.id,
                        name: values.name,
                        sku: values.sku,
                        description: this.state.product.description,
                        thumb: this.state.thumb || this.state.product.thumb,
                        isShown: values.isShown || false,
                        _tags: this.state.product._tags || [],
                    };
                    // Index the product with Algolia
                    this.props.productsIndex.partialUpdateObject(indexedValues).then(({ objectID }) => {
                        console.log("Updated product on algolia: " + objectID);
                    });
    
                    this.setState({
                        submittingUpdateProduct: false,
                        productImagesToUpload: [],
                        uploadImagesComplete: false,
                        uploadAssetsComplete: false,
                        assetsUrl: "",
                    })
                    console.log(`Saved: `);
                    console.log(indexedValues);
                    
                    toast.success(`Updated product!`);
                    
                    this.props.toggleUpdateProduct("")
                    window.scrollTo(0, 0);
                }).catch(error => {
                    console.error(`Error submitting product: ${error}`)
                    toast.error(`Error submitting product: ${error}`);
                    this.setState({ submittingUpdateProduct: false });
                });
            }
            
        }
    }

    setProductPhotos = (urls, thumb = "") => {
        this.setState({
            productImagesToUpload: urls,
            thumb: thumb,
            uploadImagesComplete: true
        })
    }

    setAssetsUrl = (url) => {
        this.setState({
            assetsUrl: url,
            uploadAssetsComplete: true,
            pickingAssets: false
        })
    }

    getSelectedTags = (tags) => {
        console.log("tags: ")
        console.log(tags)
        this.setState(prevState => {
            let product = Object.assign({}, prevState.product);
            product._tags = tags;              
            return { product };
        })
    }

    handleDescriptionChange = (html) => {
        this.setState(prevState => {
            let product = Object.assign({}, prevState.product);
            product.description = html;              
            return { product };
        })
    }

    handlePricingMiscChange = (html) => {
        this.setState(prevState => {
            let product = Object.assign({}, prevState.product);
            product.pricingMisc = html;              
            return { product };
        })
    }

    handleAddOnsChange = (html) => {
        this.setState(prevState => {
            let product = Object.assign({}, prevState.product);
            product.addOns = html;              
            return { product };
        })
    }

    handleAdditionalSpecsChange = (html) => {
        this.setState(prevState => {
            let product = Object.assign({}, prevState.product);
            product.additionalSpecs = html;              
            return { product };
        })
    }

    render() {
        if (this.state.loadingProduct) {
            return (
                <Wrapper>
                     <H2>Loading... <Spinner /></H2>
                </Wrapper>
            )
        } else if(this.state.productMia){
            return (
                <Wrapper>
                     <H2>Product missing! Please try again or recreate the product entirely.</H2>
                </Wrapper>
            )
        } else {
            return (
                    <div>
                        <Hr/>
                        <H2>Update product</H2>
                        <Formik
                            initialValues={{
                                name: this.state.product.name || "",
                                sku: this.state.product.sku || "",
                                isShown: this.state.product.isShown || false,
                                priceIncludes: this.state.product.priceIncludes || "",
                                runCharge: this.state.product.runCharge || "",
                                setupCharge: this.state.product.setupCharge || "",
                                sampleFee: this.state.product.sampleFee || "",
                                qtyPrice50: this.state.product.qtyPrice50 || "",
                                qtyPrice100: this.state.product.qtyPrice100 || "",
                                qtyPrice250: this.state.product.qtyPrice250 || "",
                                qtyPrice500: this.state.product.qtyPrice500 || "",
                                qtyPrice1000: this.state.product.qtyPrice1000 || "",
                                qtyPrice2500: this.state.product.qtyPrice2500 || "",
                                qtyPrice5000: this.state.product.qtyPrice5000 || "",
                                specsIncludes: this.state.product.specsIncludes || "",
                                packagingIncluded: this.state.product.packagingIncluded || "",
                                leadTime: this.state.product.leadTime || "",
                                materials: this.state.product.materials || "",
                                closure: this.state.product.closure || "",
                                dimensions: this.state.product.dimensions || "",
                                colors: this.state.product.colors || "",
                                maxImprint: this.state.product.maxImprint || "",
                                maxColors: this.state.product.maxColors || "",
                                assetsUrl: this.state.product.assetsUrl || "",
                                videoUrl: this.state.product.videoUrl || "",
                            }}
                            onSubmit={(values, actions) => {
                                this.updateProduct(values, actions.resetForm);
                            }}
                            enableReinitialize={false}
                            validationSchema={updateProductSchema}
                        >
                            {props => (
                            <Form>
                                <Grid fluid>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Label>Product name:</Label>&nbsp;
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"Awesome Mug"}
                                                name="name"
                                                value={props.values.name || ''}
                                                onKeyUp={() => this.setState({ errors: { name: false } })}
                                                onClick={() => this.setState({ errors: { name: false } })}
                                                error={ ((props.errors.name && props.touched.name) || this.state?.errors?.name) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.name}
                                                formikTouched={props.touched.name}
                                                stateError={this.state?.errors?.name}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Label>SKU:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"AWESOME-MUG"}
                                                name="sku"
                                                value={props.values.sku || ''}
                                                onKeyUp={() => this.setState({ errors: { sku: false } })}
                                                onClick={() => this.setState({ errors: { sku: false } })}
                                                error={ ((props.errors.sku && props.touched.sku) || this.state?.errors?.sku) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.sku}
                                                formikTouched={props.touched.sku}
                                                stateError={this.state?.errors?.sku}
                                            /> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <Label>Description:</Label>&nbsp;
                                            <br/>
                                            <ReactQuill 
                                                style={{color: this.props.theme.colors.font.body}}
                                                theme="snow"
                                                modules={this.state.quillModules}
                                                onChange={this.handleDescriptionChange}
                                                placeholder={"Write your description here!"}
                                                value={this.state.product.description}
                                            />
                                            <FormError
                                                yupError={props.errors.description}
                                                formikTouched={props.touched.description}
                                                stateError={this.state?.errors?.description}
                                            /> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={6} onKeyDown={onKeyDown}>
                                            <Label>Tags:</Label>
                                            <TagsInput
                                                selectedTags={this.getSelectedTags} 
                                                tags={this.state.product._tags}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Label>Is product shown?:</Label>
                                            <br />
                                            <RField
                                                type="radio"
                                                id="isShown"
                                                name="isShown"
                                                value={true}
                                                checked={props.values.isShown === true}
                                                onChange={() => props.setFieldValue("isShown", true)}
                                            />
                                            <Body display="inline">
                                                Yes
                                            </Body>
                                            <br />
                                            <RField
                                                type="radio"
                                                id="isShown"
                                                name="isShown"
                                                value={false}
                                                checked={props.values.isShown === false}
                                                onChange={() => props.setFieldValue("isShown", false)}
                                            />
                                            <Body display="inline">
                                                No
                                            </Body>
                                        </Col>
                                    </Row>
                                    {props.values.name && (
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <Label>Current Product Images</Label>
                                                <Row middle="xs">
                                                    {
                                                        this.state.product.photos && this.state.product.photos.map((value, index) => {
                                                            return (
                                                                <Col xs key={index}>
                                                                    <Img
                                                                        alt={`product ${this.state.product.name}`} 
                                                                        src={value}
                                                                        width={"100px"}
                                                                    />
                                                                    <br/>
                                                                    {value === this.state.product.thumb && (<Body display="inline" size={SIZES.SM} margin="0" color={this.props.theme.colors.green}>Thumbnail <BsImages /></Body>)}
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                    {this.state.product.photos.length === 0 && <Col xs={12}><Body color={this.props.theme.colors.red} margin="0">No product images!</Body></Col>}
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <Label>Upload new product images</Label>
                                                {(!this.state.uploadImagesComplete) && (
                                                    <FileUpload
                                                        name="product-images"
                                                        firestoreRef={`public/products/${urlify(props.values.name)}`}
                                                        onClick={() => this.setState({pickingProductPhotos: true})}
                                                        selectBtn=""
                                                        allowMultiple={true}
                                                        accepts="image/png, image/jpg, image/jpeg" 
                                                        onUploadSuccess={this.setProductPhotos}
                                                        user={this.props.user}
                                                    />
                                                )}
                                                {this.state.pickingProductPhotos && (
                                                    <Button 
                                                        margin="0"
                                                        size={SIZES.SM}
                                                        btype={BTYPES.INVERTED}
                                                        color={this.props.theme.colors.red}
                                                        onClick={() => this.setState({pickingProductPhotos: false})}
                                                    >
                                                        Never mind, I don't want to upload new photos
                                                    </Button>
                                                )}
                                                {this.state.uploadImagesComplete && (
                                                    <Body margin="0" size={SIZES.LG} color={this.props.theme.colors.green}>
                                                        Product images uploaded successfully!
                                                    </Body>
                                                )}
                                            </Col>
                                          
                                        </Row>
                                    )}
                                    <Hr />
                                    <H2>Pricing</H2>
                                    <Row>
                                        <Col sm={12} md={6} lg={4}>
                                            <Label>Qty Price 50:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"1.00"}
                                                name="qtyPrice50"
                                                value={props.values.qtyPrice50 || ''}
                                                onKeyUp={() => this.setState({ errors: { qtyPrice50: false } })}
                                                onClick={() => this.setState({ errors: { qtyPrice50: false } })}
                                                error={ ((props.errors.qtyPrice50 && props.touched.qtyPrice50) || this.state?.errors?.qtyPrice50) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.qtyPrice50}
                                                formikTouched={props.touched.qtyPrice50}
                                                stateError={this.state?.errors?.qtyPrice50}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <Label>Qty Price 100:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"1.25"}
                                                name="qtyPrice100"
                                                value={props.values.qtyPrice100 || ''}
                                                onKeyUp={() => this.setState({ errors: { qtyPrice100: false } })}
                                                onClick={() => this.setState({ errors: { qtyPrice100: false } })}
                                                error={ ((props.errors.qtyPrice100 && props.touched.qtyPrice100) || this.state?.errors?.qtyPrice100) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.qtyPrice100}
                                                formikTouched={props.touched.qtyPrice100}
                                                stateError={this.state?.errors?.qtyPrice100}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <Label>Qty Price 250:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"1.50"}
                                                name="qtyPrice250"
                                                value={props.values.qtyPrice250 || ''}
                                                onKeyUp={() => this.setState({ errors: { qtyPrice250: false } })}
                                                onClick={() => this.setState({ errors: { qtyPrice250: false } })}
                                                error={ ((props.errors.qtyPrice250 && props.touched.qtyPrice250) || this.state?.errors?.qtyPrice250) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.qtyPrice250}
                                                formikTouched={props.touched.qtyPrice250}
                                                stateError={this.state?.errors?.qtyPrice250}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <Label>Qty Price 500:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"1.75"}
                                                name="qtyPrice500"
                                                value={props.values.qtyPrice500 || ''}
                                                onKeyUp={() => this.setState({ errors: { qtyPrice500: false } })}
                                                onClick={() => this.setState({ errors: { qtyPrice500: false } })}
                                                error={ ((props.errors.qtyPrice500 && props.touched.qtyPrice500) || this.state?.errors?.qtyPrice500) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.qtyPrice500}
                                                formikTouched={props.touched.qtyPrice500}
                                                stateError={this.state?.errors?.qtyPrice500}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <Label>Qty Price 1000:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"2.00"}
                                                name="qtyPrice1000"
                                                value={props.values.qtyPrice1000 || ''}
                                                onKeyUp={() => this.setState({ errors: { qtyPrice1000: false } })}
                                                onClick={() => this.setState({ errors: { qtyPrice1000: false } })}
                                                error={ ((props.errors.qtyPrice1000 && props.touched.qtyPrice1000) || this.state?.errors?.qtyPrice1000) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.qtyPrice1000}
                                                formikTouched={props.touched.qtyPrice1000}
                                                stateError={this.state?.errors?.qtyPrice1000}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <Label>Qty Price 2500:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"2.25"}
                                                name="qtyPrice2500"
                                                value={props.values.qtyPrice2500 || ''}
                                                onKeyUp={() => this.setState({ errors: { qtyPrice2500: false } })}
                                                onClick={() => this.setState({ errors: { qtyPrice2500: false } })}
                                                error={ ((props.errors.qtyPrice2500 && props.touched.qtyPrice2500) || this.state?.errors?.qtyPrice2500) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.qtyPrice2500}
                                                formikTouched={props.touched.qtyPrice2500}
                                                stateError={this.state?.errors?.qtyPrice2500}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={6} lg={4}>
                                            <Label>Qty Price 5000:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"2.25"}
                                                name="qtyPrice5000"
                                                value={props.values.qtyPrice5000 || ''}
                                                onKeyUp={() => this.setState({ errors: { qtyPrice5000: false } })}
                                                onClick={() => this.setState({ errors: { qtyPrice5000: false } })}
                                                error={ ((props.errors.qtyPrice5000 && props.touched.qtyPrice5000) || this.state?.errors?.qtyPrice5000) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.qtyPrice5000}
                                                formikTouched={props.touched.qtyPrice5000}
                                                stateError={this.state?.errors?.qtyPrice5000}
                                            /> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Label>Price Includes:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"1 color print on front of card"}
                                                name="priceIncludes"
                                                value={props.values.priceIncludes || ''}
                                                onKeyUp={() => this.setState({ errors: { priceIncludes: false } })}
                                                onClick={() => this.setState({ errors: { priceIncludes: false } })}
                                                error={ ((props.errors.priceIncludes && props.touched.priceIncludes) || this.state?.errors?.priceIncludes) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.priceIncludes}
                                                formikTouched={props.touched.priceIncludes}
                                                stateError={this.state?.errors?.priceIncludes}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Label>Run Charge:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"vessel: 0.40 (V)/unit"}
                                                name="runCharge"
                                                value={props.values.runCharge || ''}
                                                onKeyUp={() => this.setState({ errors: { runCharge: false } })}
                                                onClick={() => this.setState({ errors: { runCharge: false } })}
                                                error={ ((props.errors.runCharge && props.touched.runCharge) || this.state?.errors?.runCharge) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.runCharge}
                                                formikTouched={props.touched.runCharge}
                                                stateError={this.state?.errors?.runCharge}
                                            /> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Label>Setup $:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"60 (V) per color/location"}
                                                name="setupCharge"
                                                value={props.values.setupCharge || ''}
                                                onKeyUp={() => this.setState({ errors: { setupCharge: false } })}
                                                onClick={() => this.setState({ errors: { setupCharge: false } })}
                                                error={ ((props.errors.setupCharge && props.touched.setupCharge) || this.state?.errors?.setupCharge) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.setupCharge}
                                                formikTouched={props.touched.setupCharge}
                                                stateError={this.state?.errors?.setupCharge}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={6}>
                                            <Label>Sample $:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"31.25 (V)"}
                                                name="sampleFee"
                                                value={props.values.sampleFee || ''}
                                                onKeyUp={() => this.setState({ errors: { sampleFee: false } })}
                                                onClick={() => this.setState({ errors: { sampleFee: false } })}
                                                error={ ((props.errors.sampleFee && props.touched.sampleFee) || this.state?.errors?.sampleFee) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.sampleFee}
                                                formikTouched={props.touched.sampleFee}
                                                stateError={this.state?.errors?.sampleFee}
                                            /> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Label>Pricing Misc:</Label>
                                            <br/>
                                            <ReactQuill 
                                                style={{color: this.props.theme.colors.font.body}}
                                                theme="snow"
                                                modules={this.state.quillModules}
                                                onChange={this.handlePricingMiscChange}
                                                placeholder={"Includes the following candle fills: jelly beans, M&M's, gummy bears, or cinnamon imperials inquire about additional candy fills, upcharges may apply."}
                                                value={this.state.product.pricingMisc}
                                            />
                                        </Col>
                                    </Row>
                                    <Hr />
                                    <H2>Specs</H2>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <Label>Includes:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"sugarfree peppermint or cinnamon mints"}
                                                name="specsIncludes"
                                                value={props.values.specsIncludes || ''}
                                                onKeyUp={() => this.setState({ errors: { specsIncludes: false } })}
                                                onClick={() => this.setState({ errors: { specsIncludes: false } })}
                                                error={ ((props.errors.specsIncludes && props.touched.specsIncludes) || this.state?.errors?.specsIncludes) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.specsIncludes}
                                                formikTouched={props.touched.specsIncludes}
                                                stateError={this.state?.errors?.specsIncludes}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Label>Packaging included:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"white or black 2-piece box"}
                                                name="packagingIncluded"
                                                value={props.values.packagingIncluded || ''}
                                                onKeyUp={() => this.setState({ errors: { packagingIncluded: false } })}
                                                onClick={() => this.setState({ errors: { packagingIncluded: false } })}
                                                error={ ((props.errors.packagingIncluded && props.touched.packagingIncluded) || this.state?.errors?.packagingIncluded) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.packagingIncluded}
                                                formikTouched={props.touched.packagingIncluded}
                                                stateError={this.state?.errors?.packagingIncluded}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Label>Lead Time:</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"5-7 days after art approval"}
                                                name="leadTime"
                                                value={props.values.leadTime || ''}
                                                onKeyUp={() => this.setState({ errors: { leadTime: false } })}
                                                onClick={() => this.setState({ errors: { leadTime: false } })}
                                                error={ ((props.errors.leadTime && props.touched.leadTime) || this.state?.errors?.leadTime) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.leadTime}
                                                formikTouched={props.touched.leadTime}
                                                stateError={this.state?.errors?.leadTime}
                                            /> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <Label>Material(s):</Label>
                                            <br/>
                                            <FField
                                                component="textarea"
                                                onChange={props.handleChange}
                                                placeholder={"coated canvas or pu"}
                                                name="materials"
                                                value={props.values.materials || ''}
                                                onKeyUp={() => this.setState({ errors: { materials: false } })}
                                                onClick={() => this.setState({ errors: { materials: false } })}
                                                error={ ((props.errors.materials && props.touched.materials) || this.state?.errors?.materials) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.materials}
                                                formikTouched={props.touched.materials}
                                                stateError={this.state?.errors?.materials}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Label>Closure:</Label>
                                            <br/>
                                            <FField
                                                component="textarea"
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"zipper"}
                                                name="closure"
                                                value={props.values.closure || ''}
                                                onKeyUp={() => this.setState({ errors: { closure: false } })}
                                                onClick={() => this.setState({ errors: { closure: false } })}
                                                error={ ((props.errors.closure && props.touched.closure) || this.state?.errors?.closure) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.closure}
                                                formikTouched={props.touched.closure}
                                                stateError={this.state?.errors?.closure}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Label>Dimensions:</Label>
                                            <br/>
                                            <FField
                                                component="textarea"
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={`1.75" W x 0.5" H`}
                                                name="dimensions"
                                                value={props.values.dimensions || ''}
                                                onKeyUp={() => this.setState({ errors: { dimensions: false } })}
                                                onClick={() => this.setState({ errors: { dimensions: false } })}
                                                error={ ((props.errors.dimensions && props.touched.dimensions) || this.state?.errors?.dimensions) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.dimensions}
                                                formikTouched={props.touched.dimensions}
                                                stateError={this.state?.errors?.dimensions}
                                            /> 
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <Label>Color(s):</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"white, black, red, blue, yellow, silver"}
                                                name="colors"
                                                value={props.values.colors || ''}
                                                onKeyUp={() => this.setState({ errors: { colors: false } })}
                                                onClick={() => this.setState({ errors: { colors: false } })}
                                                error={ ((props.errors.colors && props.touched.colors) || this.state?.errors?.colors) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.colors}
                                                formikTouched={props.touched.colors}
                                                stateError={this.state?.errors?.colors}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Label>Max Imprint size(s):</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={`1 7/16" round`}
                                                name="maxImprint"
                                                value={props.values.maxImprint || ''}
                                                onKeyUp={() => this.setState({ errors: { maxImprint: false } })}
                                                onClick={() => this.setState({ errors: { maxImprint: false } })}
                                                error={ ((props.errors.maxImprint && props.touched.maxImprint) || this.state?.errors?.maxImprint) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.maxImprint}
                                                formikTouched={props.touched.maxImprint}
                                                stateError={this.state?.errors?.maxImprint}
                                            /> 
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Label>Max Color(s):</Label>
                                            <br/>
                                            <FField
                                                type="text"
                                                onChange={props.handleChange}
                                                placeholder={"4 spot colors"}
                                                name="maxColors"
                                                value={props.values.maxColors || ''}
                                                onKeyUp={() => this.setState({ errors: { maxColors: false } })}
                                                onClick={() => this.setState({ errors: { maxColors: false } })}
                                                error={ ((props.errors.maxColors && props.touched.maxColors) || this.state?.errors?.maxColors) ? 1 : 0 }
                                            />
                                            <FormError
                                                yupError={props.errors.maxColors}
                                                formikTouched={props.touched.maxColors}
                                                stateError={this.state?.errors?.maxColors}
                                            /> 
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col sm={12}>
                                            <Label>Additional Specs:</Label>
                                            <br/>
                                            <ReactQuill 
                                                style={{color: this.props.theme.colors.font.body}}
                                                theme="snow"
                                                modules={this.state.quillModules}
                                                onChange={this.handleAdditionalSpecsChange}
                                                placeholder={"max 1 imprint location"}
                                                value={this.state.product.additionalSpecs}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Label>Add-ons:</Label>
                                            <br/>
                                            <ReactQuill 
                                                style={{color: this.props.theme.colors.font.body}}
                                                theme="snow"
                                                modules={this.state.quillModules}
                                                onChange={this.handleAddOnsChange}
                                                value={this.state.product.addOns}
                                                placeholder={`"1" x 4" white label with full color print: 50 pcs: 0.72 / 100 pcs 0.44 /250 pcs 0.31 /  500 pcs 0.24`}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        {props.values.name && (
                                            <Col xs={12}>
                                                <Label>Assets</Label>
                                                {(!this.state.uploadAssetsComplete) && (
                                                    <FileUpload
                                                        name="assets"
                                                        onClick={() => this.setState({pickingAssets: true})}
                                                        firestoreRef={`public/products/${urlify(props.values.name)}`}
                                                        selectBtn=""
                                                        // allowMultiple={true}
                                                        accepts="*" 
                                                        onUploadSuccess={this.setAssetsUrl}
                                                        user={this.props.user}
                                                    />
                                                )}
                                                {this.state.pickingAssets && (
                                                    <Button 
                                                        margin="0" 
                                                        size={SIZES.SM} 
                                                        btype={BTYPES.INVERTED} 
                                                        color={this.props.theme.colors.red}
                                                        onClick={() => this.setState({pickingAssets: false})}
                                                    >
                                                        Never mind, I don't want to upload assets
                                                    </Button>
                                                )}
                                                {this.state.uploadAssetsComplete && (
                                                    <Body margin="0" size={SIZES.LG} color={this.props.theme.colors.green}>
                                                        Assets uploaded successfully!
                                                    </Body>
                                                )}
                                            </Col>
                                        )}
                                        
                                    </Row>
                                    <Row>
                                        <Col sm={12}>
                                            <Label>Vimeo Video Embedded URL:</Label>
                                            <Tooltip>
                                                <FField
                                                    type="text"
                                                    onChange={props.handleChange}
                                                    placeholder={"https://player.vimeo.com/video/738667227?h=cffc5dbed5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}
                                                    name="videoUrl"
                                                    value={props.values.videoUrl || ''}
                                                    onKeyUp={() => this.setState({ errors: { videoUrl: false } })}
                                                    onClick={() => this.setState({ errors: { videoUrl: false } })}
                                                    error={ ((props.errors.videoUrl && props.touched.videoUrl) || this.state?.errors?.videoUrl) ? 1 : 0 }
                                                />
                                            </Tooltip>
                                            <FormError
                                                yupError={props.errors.videoUrl}
                                                formikTouched={props.touched.videoUrl}
                                                stateError={this.state?.errors?.videoUrl}
                                            /> 
                                        </Col>
                                    </Row>
                                    <Hr/>
                                    <Row center="xs">
                                        <Col xs={12}>
                                            <Button 
                                                type="submit"
                                                disabled={this.state.submittingUpdateProduct}
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Grid>
                            </Form>
                            )}
                        </Formik>
                    </div>
                )
        }
        
    }
}

export default withTheme(withRouter(UpdateProduct))
