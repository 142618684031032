import React, { Component } from 'react'
import { collection, query, orderBy, startAfter, limit, getDocs, onSnapshot, doc, endAt, limitToLast, updateDoc, increment, deleteDoc, where } from "firebase/firestore";  
import { FaChevronLeft, FaChevronRight, FaSearch,  } from 'react-icons/fa';
import { CgClose, CgTrash } from 'react-icons/cg';
import { withTheme } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { Col, Grid, Row } from 'react-flexbox-grid';
import algoliasearch from 'algoliasearch';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Form, Formik } from 'formik';

import { ModalCard, Hr, OverflowXAuto, Spinner, Table, Tbody, Td, Th, Thead, Tr, ModalContainer, Img } from '../../../../utils/styles/misc'
import { Body, H1, H2, H3, LLink } from '../../../../utils/styles/text'
import { firestore } from '../../../../Fire';
import { Button } from '../../../../utils/styles/buttons';
import { readTimestamp, urlify } from '../../../../utils/misc';
import { BTYPES, SIZES } from '../../../../utils/constants.js';
import { withRouter } from '../../../../utils/hocs';
import AddProduct from './AddProduct';
import UpdateProduct from './UpdateProduct';
import ConfirmAlert from '../../../misc/ConfirmAlert';
import { searchManagerSchema } from '../../../../utils/formSchemas';
import FormError from '../../../misc/FormError';
import { FField, SField, SearchContainer } from '../../../../utils/styles/forms';

const algoliaAdmin = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID, 
    (process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_ALGOLIA_LIVE_ADMIN_KEY : process.env.REACT_APP_ALGOLIA_TEST_ADMIN_KEY)
);
const productsIndex = algoliaAdmin.initIndex((process.env.NODE_ENV !== 'development' ? 'products' : 'test_products'));

class ManageProducts extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            products: "",
            currentPage: 0,
            beginCursor: "",
            finalCursor: "",
            loadingProducts: true,
            loadingCounts: true,
            totalProductCount: 0,
            productsPerPage: 10,
            shownProducts: [],
            showNewProductModal: false,
            showUpdateProductModal: "", // I use this as a placeholder for the id that I pass to remember which product we are updating.
            term: "",
            column: "name",
        }
    }
    
    componentDidMount = async () => {
        this.unsubCounts = onSnapshot(doc(firestore, "site", "counts"), (countsDoc) => {
            if(countsDoc.exists()){
                let countsData = countsDoc.data();
                this.setState({
                    totalProductCount: countsData.products,
                    loadingCounts: false
                });
            } else {
                console.log("No custom site set, can't properly count products.")
                this.setState({
                    loadingCounts: false
                });
            }
        });

        // Get first page of products
        const currentPageQuery = query(
            collection(firestore, "products"), 
            orderBy("timestamp", "desc"), 
            limit(this.state.productsPerPage)
        );

        const pageDocSnaps = await getDocs(currentPageQuery);
        // Get the last visible document cursor so we can reference it for the next page
        const finalCursor = pageDocSnaps.docs[ pageDocSnaps.docs.length - 1 ];

        // Get content from each doc on this page 
        let products = [];
        let shownProducts = []
        pageDocSnaps.forEach((doc) => {
            const docWithMore = Object.assign({}, doc.data());
            docWithMore.id = doc.id;
            products.push(docWithMore);
            shownProducts.push(false)
        });

        this.setState({
            products: products,
            finalCursor: finalCursor,
            currentPage: 1,
            loadingProducts: false,
            shownProducts: shownProducts
        })
    }

    componentDidUpdate = async (prevProps, prevState) =>{
        if(prevState.term !== this.state.term && this.state.term.length > 0){
            // Search submitted!
            // Get first page of products
            let currentPageQuery = query(
                collection(firestore, "products"), 
                where(this.state.column, "==", this.state.term),
            );

            const pageDocSnaps = await getDocs(currentPageQuery);

            // Get content from each doc on this page 
            let products = [];
            let shownProducts = []
            pageDocSnaps.forEach((doc) => {
                const docWithMore = Object.assign({}, doc.data());
                docWithMore.id = doc.id;
                products.push(docWithMore);
                shownProducts.push(false)
            });

            this.setState({
                products: products,
                currentPage: 1,
                loadingProducts: false,
                shownProducts: shownProducts
            })
        } else if (prevState.term && !this.state.term && this.state.term.length === 0) {
            // Reset search if cleared
            // Get first page of products
            const currentPageQuery = query(
                collection(firestore, "products"), 
                orderBy("timestamp", "desc"), 
                limit(this.state.productsPerPage)
            );

            const pageDocSnaps = await getDocs(currentPageQuery);
            // Get the last visible document cursor so we can reference it for the next page
            const finalCursor = pageDocSnaps.docs[ pageDocSnaps.docs.length - 1 ];

            // Get content from each doc on this page 
            let products = [];
            let shownProducts = []
            pageDocSnaps.forEach((doc) => {
                const docWithMore = Object.assign({}, doc.data());
                docWithMore.id = doc.id;
                products.push(docWithMore);
                shownProducts.push(false)
            });

            this.setState({
                products: products,
                finalCursor: finalCursor,
                currentPage: 1,
                loadingProducts: false,
                shownProducts: shownProducts
            })
        }
    }

    componentWillUnmount(){
        if(this.unsubCounts){
            this.unsubCounts();
        }
    }
    
    getPrevPage = async () => {
        if(this.state.currentPage !== 1){
            this.setState({
                loadingProducts: true
            })
            // Construct a new query starting at this document,
            const currentPageQuery = query(
                collection(firestore, "products"), 
                orderBy("timestamp", "desc"),
                endAt(this.state.beginCursor),
                limitToLast(this.state.productsPerPage) // Adding this seemed to solve the going abck issue, but now everything is jumbled when going back
            );
            const pageDocSnaps = await getDocs(currentPageQuery);
            const beginCursor = pageDocSnaps.docs[ 0 ];
            const finalCursor = pageDocSnaps.docs[ pageDocSnaps.docs.length - 1 ];
            const prevPage = this.state.currentPage - 1;

            // Set data in docs to state
            let products = [];
            let shownProducts = []
            pageDocSnaps.forEach((doc) => {
                const docWithMore = Object.assign({}, doc.data());
                docWithMore.id = doc.id;
                products.push(docWithMore);
                shownProducts.push(false)
            });

            this.setState({
                products: products,
                beginCursor: beginCursor,
                finalCursor: finalCursor,
                currentPage: prevPage,
                loadingProducts: false,
            })
        }
    }

    getNextPage = async () => {
        if(this.state.currentPage !== Math.ceil(this.state.totalProductCount/this.state.productsPerPage)){
            this.setState({
                loadingProducts: true
            })
            // Construct a new query starting at this document,
            const currentPageQuery = query(
                collection(firestore, "products"), 
                orderBy("timestamp", "desc"),
                startAfter(this.state.finalCursor),
                limit(this.state.productsPerPage)
            );
            const pageDocSnaps = await getDocs(currentPageQuery);
            const beginCursor = pageDocSnaps.docs[ 0 ];
            const finalCursor = pageDocSnaps.docs[ pageDocSnaps.docs.length - 1 ];
            const nextPage = this.state.currentPage + 1;

            // Set data in docs to state
            let products = [];
            let shownProducts = []
            pageDocSnaps.forEach((doc) => {
                const docWithMore = Object.assign({}, doc.data());
                docWithMore.id = doc.id;
                products.push(docWithMore);
                shownProducts.push(false)
            });

            this.setState({
                products: products,
                beginCursor: beginCursor,
                finalCursor: finalCursor,
                currentPage: nextPage,
                loadingProducts: false,
            })
        }
    }

    toggleProduct = (newStatus, index) => {
        let tempShownProducts = this.state.shownProducts
        tempShownProducts[index] = newStatus
        this.setState({
            shownProducts: tempShownProducts
        })
    }

    toggleAddProduct = (newStatus) => {
        this.setState({
            showNewProductModal: newStatus
        })
    }

    toggleUpdateProduct = (newStatus) => {
        this.setState({
            showUpdateProductModal: newStatus
        })
    }

    deleteProduct  = (productId) => {
        deleteDoc(doc(firestore, "products", productId)).then(() => {
            console.log("Successfully deleted product!")
            updateDoc(doc(firestore, "site", "counts"), {
                products: increment(-1)
            }).then(() => {
                console.log(`Incremented product count`)
                toast.success(`Product deleted successfully!`);
            }).catch(error => {
                console.error(`Error incrementing product count: ${error}`)
            })

            productsIndex.deleteObject(productId).then(() => {
                console.log("Successfully deleted product on algolia")
            });
        }).catch((error) => {
            console.error("Error deleting product: " + error)
        });
    }

    submitSearch = (values) => {
        console.log("submitting search...")
        this.setState({
            column: values.column,
            term: values.term,
        })
    }

    render() {
        if(this.state.loadingProducts && this.state.loadingCounts){
            return (
                <>
                    <H2>Loading... <Spinner /> </H2> 
                </>
            )
        } else {
            return (
                <>
                    <Helmet>
                        <title>Manage Products {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
                    </Helmet>
                    <Button size={SIZES.SM} onClick={() => this.props.navigate(-1)}>
                        <FaChevronLeft  />
                        &nbsp; Go back
                    </Button>
                    <H1>Products: {this.state.totalProductCount}</H1>
                    {this.state.totalProductCount === 0 && ( <Body color={this.props.theme.colors.red} bold size={SIZES.LG}>No products yet!</Body>)}
                    {this.state.totalProductCount !== 0 && (
                        <>
                        <Formik
                            initialValues={{
                                term: this.state.term,
                                column: this.state.column,
                            }}
                            onSubmit={(values) => {
                                this.submitSearch(values);
                            }}
                            enableReinitialize={true}
                            validationSchema={searchManagerSchema}
                        >
                            {formProps => (
                                <Form>
                                    <Grid fluid>
                                        <Row middle="xs">
                                            <Col md={12} lg={8}>
                                                <SearchContainer width={"100%"}>
                                                    <FaSearch />
                                                    <FField
                                                        type="text"
                                                        required
                                                        onChange={formProps.handleChange}
                                                        placeholder={`Search by a column title in the table`}
                                                        name="term"
                                                        value={formProps.values.term || ""}
                                                        onKeyUp={() => this.setState({ errors: { term: false } })}
                                                        onClick={() => this.setState({ errors: { term: false } })}
                                                        error={ ((formProps.errors.term && formProps.touched.term) || this.state.errors?.term) ? 1 : 0 }
                                                    />
                                                </SearchContainer>
                                            </Col>
                                            <Col md={12} lg={4}>
                                                <Button 
                                                    type="submit"
                                                >
                                                    Search 
                                                </Button>
                                                <SField
                                                    name="column"
                                                    component="select"
                                                    onChange={formProps.handleChange}
                                                >
                                                    <option value={"name"}>Name</option>
                                                    <option value={"sku"}>SKU</option>
                                                </SField>
                                                {this.state.term && (
                                                    <Button 
                                                        type="button"
                                                        btype={BTYPES.INVERTED}
                                                        color={this.props.theme.colors.yellow}
                                                        onClick={() => this.setState({ term: "" })}
                                                    >
                                                        Clear
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row center="xs">
                                            <Col xs={12}>
                                                <FormError
                                                    yupError={formProps.errors.term}
                                                    formikTouched={formProps.touched.term}
                                                    stateError={this.state.errors?.term}
                                                /> 
                                            </Col>
                                        </Row>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                        {(this.state.products.length === 0) && (
                            <Body color={this.props.theme.colors.red} bold size={SIZES.LG}>No products returned</Body>
                        )}
                        {this.state.products.length !== 0 && (
                            <>
                            <OverflowXAuto>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>Created</Th>
                                            <Th>Name</Th>
                                            <Th>SKU</Th>
                                            <Th>Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        { this.state.products.map((product, i) => {
                                            return (
                                                <Tr key={i}>
                                                    <Td>
                                                        {readTimestamp(product.timestamp).date} @ {readTimestamp(product.timestamp).time}
                                                    </Td>
                                                    <Td>
                                                        {product.name}
                                                    </Td>
                                                    <Td>
                                                        {product.sku}
                                                    </Td>
                                                    <Td>
                                                        <Button
                                                            btype={BTYPES.TEXTED} 
                                                            size={SIZES.SM}
                                                            onClick={() => {this.toggleProduct(true, i)}}         
                                                        >
                                                            View product
                                                        </Button>

                                                        <Button
                                                            btype={BTYPES.INVERTED} 
                                                            size={SIZES.SM}
                                                            color={this.props.theme.colors.red}
                                                            onClick={() =>         
                                                                confirmAlert({
                                                                    customUI: ({ onClose }) => {
                                                                        return (
                                                                            <ConfirmAlert
                                                                                theme={this.props.theme}
                                                                                onClose={onClose} 
                                                                                headingText={`Delete Product`}
                                                                                body={`Are you sure you want to delete <${product.name}>? This operation is not reversible.`}
                                                                                yesFunc={() => this.deleteProduct(product.id)} 
                                                                                yesText={`Yes`} 
                                                                                noFunc={function () {}} 
                                                                                noText={`No`}   
                                                                            />
                                                                        );
                                                                    }
                                                                })}        
                                                        >
                                                            <CgTrash size={18} />
                                                        </Button>
                                                        
                                                        {this.state.shownProducts[i] && (
                                                            <ModalContainer onClick={() => this.toggleProduct(false, i)}>
                                                                <ModalCard onClick={(e) => e.stopPropagation()}>
                                                                    <H3 margin="0" alignLeft="left">{product.name}</H3> <LLink to={`/products/${urlify(product.name)}`}>View Product Page</LLink>
                                                                    <Body margin="0" size={SIZES.SM}><i>Created {readTimestamp(product.timestamp).date} @ {readTimestamp(product.timestamp).time}</i></Body>
                                                                    
                                                                    <Body dangerouslySetInnerHTML={{__html: product.description}}></Body>
                                                                    <Hr/>
                                                                    {product.photos.map((photo, pi) => {
                                                                        return (
                                                                            <Img key={pi} src={photo} alt={`product ${product.name}`} margin="5px"/>
                                                                        )
                                                                    })}
                                                                    { product.photos.length === 0 && <Body color={this.props.theme.colors.red} margin="0">No product images!</Body> }
                                                                    <Hr/>
                                                                    {!this.state.showUpdateProductModal && (
                                                                        <Button 
                                                                            type="button"
                                                                            onClick={() => {
                                                                                this.toggleAddProduct(false);
                                                                                this.toggleUpdateProduct(product.id);
                                                                                this.toggleProduct(false, i);
                                                                            }}
                                                                        >
                                                                            Update this product
                                                                        </Button>
                                                                    )}
                                                                    {this.state.showUpdateProductModal && (
                                                                        <Button 
                                                                            type="button"
                                                                            color={this.props.theme.colors.red}
                                                                            btype={BTYPES.INVERTED}
                                                                            onClick={() => this.toggleUpdateProduct("")}
                                                                        >
                                                                            Close currently updating product
                                                                        </Button>
                                                                    )}
                                                                    <Button 
                                                                        size={SIZES.SM} 
                                                                        color={this.props.theme.colors.red}
                                                                        onClick={() => this.toggleProduct(false, i)}
                                                                    >
                                                                    <CgClose /> Close 
                                                                    </Button>
                                                                </ModalCard>
                                                            </ModalContainer>
                                                            
                                                        )}
                                                    </Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                            </OverflowXAuto>
                            <Hr/>
                            <Grid fluid>
                                <Row center="xs" middle="xs">
                                    <Col xs={12} sm={4}>
                                        {this.state.currentPage !== 1 && (
                                            <Button onClick={() => this.getPrevPage()}>
                                                <FaChevronLeft /> Previous page    
                                            </Button>
                                        )}
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        <Body size={SIZES.LG}>Page {this.state.currentPage} of {Math.ceil(this.state.totalProductCount/this.state.productsPerPage) || 1}</Body>
                                    </Col>
                                    <Col xs={12} sm={4}>
                                        {this.state.currentPage !== Math.ceil(this.state.totalProductCount/this.state.productsPerPage) && (
                                            <Button onClick={() => this.getNextPage()}>
                                                Next page <FaChevronRight /> 
                                            </Button>
                                        )}
                                    
                                    </Col>
                                </Row>
                            </Grid>
                            <Hr/>
                            </>
                        )}
                        </>
                    )}
                    
                    {!this.state.showNewProductModal && (
                        <Button onClick={() => this.setState({showNewProductModal: true, showUpdateProductModal: ""})}>
                            Add a new product
                        </Button>
                    )}
                    
                    {this.state.showNewProductModal && (
                        <Button 
                            btype={BTYPES.INVERTED}
                            color={this.props.theme.colors.red} 
                            onClick={() => this.toggleAddProduct(false)}
                        >
                            Never mind, don't add product
                        </Button>
                    )}
                    {this.state.showNewProductModal && (
                        <AddProduct 
                            productsIndex={productsIndex} 
                            site={this.props.site} 
                            user={this.props.user} 
                            toggleAddProduct={this.toggleAddProduct} 
                        />
                    )}

                    {this.state.showUpdateProductModal && (
                        <Button 
                            btype={BTYPES.INVERTED} 
                            color={this.props.theme.colors.red}
                            onClick={() => this.toggleUpdateProduct("")}
                        >
                            Never mind, don't update product
                        </Button>
                    )}
                    {this.state.showUpdateProductModal && (
                        <UpdateProduct 
                            productsIndex={productsIndex} 
                            site={this.props.site} 
                            user={this.props.user}
                            productId={this.state.showUpdateProductModal}
                            toggleUpdateProduct={this.toggleUpdateProduct}
                        />
                    )}
                    <Hr/>
                    {/* <Body margin="5px 0" size={SIZES.XS}>
                        <b>Note: </b> 
                        Searches are case sensitive, so copy pasting a product name from the product page directly will search an all capitalized name, even if the name wasn't entered as all caps from the product editor on this page! 
                        This is because all headings are capitalized automatically through code, and the product name on the product page is a heading. Searching by SKU instead can sometimes be more guaranteed since that value is not auto-capitalized!
                    </Body> */}
                </>
            ) 
        }
        
        
    }
}

export default withTheme(withRouter(ManageProducts))