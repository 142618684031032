import React, {useState}from 'react';
import { SIZES, TAGS } from '../../utils/constants';
import { TagsContainer } from '../../utils/styles/forms';
import { Body, Li, Ul } from '../../utils/styles/text';

export const TagsInput = (props) => {
	const [tags, setTags] = useState(props.tags);
	const [detailsShown, setDetailsShown] = useState(false);
	const removeTags = indexToRemove => {
        console.log("removing: " + indexToRemove)
		setTags([...tags.filter((_, index) => index !== indexToRemove)]);
        props.selectedTags([...tags.filter((_, index) => index !== indexToRemove)]);
	};
	const addTags = event => {
		if (event.target.value !== "") {
			setTags([...tags, event.target.value]);
			props.selectedTags([...tags, event.target.value]);
			event.target.value = "";
		}
	};
	return (
        <>
        <Body margin="0" hovercolor={"green"} size={SIZES.SM} onClick={() => setDetailsShown(!detailsShown)}>
            {detailsShown ? "Hide" : "Show"} Details
        </Body>
        {detailsShown && (
            <div style={{ border: "2px solid black", padding: "10px" }}>
                <Body margin="0" size={SIZES.SM}>
                    Tags need to be in a specific format to work. 
                    Just replace spaces/&amp;/and with a dash, and lowercase everything. 
                    Quick Turn would be "quick-turn" for example. Both Main and Sub Categories go here! As well as featured product sections you may want me to search.
                </Body>
                <Body margin="0">Existing tags with tag abbreviation:</Body>
                <Ul>
                    {TAGS.map((tag, t) => {
                        return (
                            <Li key={t}>{tag.name} = {tag.abbrev}</Li>
                        )
                    })}
                </Ul>
            </div>
        )}
        
		<TagsContainer>
            <div className="tags-input">
                <ul id="tags">
                    {tags && tags.map((tag, index) => (
                        <li key={index} className="tag">
                            <span className='tag-title'>{tag}</span>
                            <span className='tag-close-icon'
                                onClick={() => removeTags(index)}
                            >
                                x
                            </span>
                        </li>
                    ))}
                </ul>
                <input
                    type="text"
                    onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
                    placeholder="Press enter to add tags"
                />
            </div>
		</TagsContainer>
        </>
	);
};