import styled, { createGlobalStyle }  from 'styled-components';
import { rgb, rgba, transparentize } from 'polished'
import { FaSpinner } from 'react-icons/fa';

// Importing font into CSS global for use around app
// import RobotoRegular from '../../assets/fonts/roboto/Roboto-Regular.ttf';
// import RobotoBold from '../../assets/fonts/roboto/Roboto-Bold.ttf';
import { BodyFont, ClikClakLogoFont, HeadingBoldFont, HeadingFont, LLink } from './text';
import { SCHEMES } from '../constants';
import { bounceArrowAnimation, clakAnimation, clikAnimation, slideFromRightAnimation, spin } from './animations';

export const GlobalStyle = createGlobalStyle`
    // Try to stay away from using this Global Styling mainly for load times 
    // See: https://github.com/styled-components/styled-components/issues/2900
    body {
        margin: 0;
    }

     // ** Loading the font from the file is where the font-face was taking to long to load and flashing times new roman issue is arising from
    // Maybe try and use this lib to load in fonts from Google, etc: https://thabo-ambrose.medium.com/prevent-text-font-flickering-caused-by-using-a-custom-font-family-983c4b8d548d
    // SOLUTION: For now, we will just have to manually load them instead of from the site public doc
`;

export const BodyWrapper = styled.div`
    position: relative;
    z-index: 1;
    min-height: 100vh;
    padding-bottom: 375px;

    background-color: ${props => props.theme.colors.background};
    /* Overflow hidden so side nav can stay hidden, but transition-able */
    overflow-x: hidden;
    
    @media (max-width: 1200px) {
        padding-bottom: 500px; 
    }

    @media (max-width: 900px) {
        padding-bottom: 800px; 
    }

    /* Change notification colors */
    :root{
        --toastify-color-dark: ${props => props.theme?.colors?.background ?? "black"};
        --toastify-color-light: ${props => props.theme?.colors?.background ?? "white"};
        --toastify-color-info: ${props => props?.theme?.colors?.primary};
        --toastify-color-success: ${props => props?.theme?.colors?.green};
        --toastify-color-warning: ${props => props?.theme?.colors?.yellow};
        --toastify-color-error: ${props => props?.theme?.colors?.red};
        --toastify-color-transparent: rgba(255, 255, 255, 0.7);
        --toastify-icon-color-info: var(--toastify-color-info);
        --toastify-icon-color-success: var(--toastify-color-success);
        --toastify-icon-color-warning: var(--toastify-color-warning);
        --toastify-icon-color-error: var(--toastify-color-error);
        --toastify-toast-width: 320px;
        --toastify-toast-background: #fff;
        --toastify-toast-min-height: 64px;
        --toastify-toast-max-height: 800px;
        --toastify-font-family: ${props => props.theme?.fonts?.body ?? "Arial, Helvetica, sans-serif"} !important;
        --toastify-z-index: 9999;
        --toastify-text-color-dark: ${props => props.theme?.colors?.font?.body ?? "white"};
        --toastify-text-color-light: ${props => props.theme?.colors?.font?.body ?? "black"};
        --toastify-text-color-info: ${props => props.theme?.colors?.font?.body ?? "black"};
        --toastify-text-color-success: ${props => props.theme?.colors?.font?.body ?? "black"};
        --toastify-text-color-warning: ${props => props.theme?.colors?.font?.body ?? "black"};
        --toastify-text-color-error: ${props => props.theme?.colors?.font?.body ?? "black"};
        --toastify-spinner-color: #616161;
        --toastify-spinner-color-empty-area: #e0e0e0;
        --toastify-color-progress-dark: #bb86fc;
        --toastify-color-progress-light: linear-gradient( to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55 );
        --toastify-color-progress-info: var(--toastify-color-info);
        --toastify-color-progress-success: var(--toastify-color-success);
        --toastify-color-progress-warning: var(--toastify-color-warning);
        --toastify-color-progress-error: var(--toastify-color-error);
    }
`;

export const Spinner = styled(FaSpinner)`
    animation-name: ${spin};
    animation-duration: 3s;
    animation-iteration-count: infinite;
    transform-origin: center;
    padding: 0 !important;
    animation-timing-function: ease-in-out;
`;

// Alignment //
export const Wrapper = styled.div`
    margin: 0 auto;
    width: 85%;
    padding: ${props => props.padding ? props.padding : "150px 0"};

    @media (max-width: 900px) {
        /* padding: ${props => props.padding ? props.padding : "200px 0"}; */
        width: 90%;
    }
`;

export const ProductsTopWrapper = styled.div`
    margin: 0 auto;
    width: 90%;
    padding: 150px 0 0 0;

    @media (max-width: 900px) {
        width: 95%;
    }
`;

// Containers
export const SmContainer = styled.div`
    margin: auto;
    width: 25%;
    padding: 1%;

    @media (max-width: 900px) {
        width: 70%;
    }
`;

export const MdContainer = styled.div`
    margin: auto;
    width: 50%;
    padding: 1%;

    @media (max-width: 900px) {
        width: 80%;
    }
`;

export const LgContainer = styled.div`
    margin: auto;
    width: 90%;
    padding: 1%;

    @media (max-width: 900px) {
        margin: auto;
        width: 90%;
        padding: 1%;
    }
`;

// Tooltip
export const TooltipContainer = styled.div`
    position: relative;
    /* display: inline-block; */
    z-index: 5;
    // TODO: would be nice if this tooltip could extend past it's child... might pose an issue in the future.
    div:first-child  {
        font-size: 14px !important;
        ${BodyFont};
        position: absolute;
        background: rgba(0, 0, 0, 0.8);
        width: 20vw;
        color: white !important;
        padding: 10px;
        border-radius: 5px;
        top: calc(100% + 5px);
        display: none;
        
        @media (max-width: 768px) {
            width: 20vw;
        }
    }
    &:hover div:first-child {
        display: block;
    }
    img {
        width: 20vw
    }
    span {
        /* Arrow */
        position: absolute;
        top: -10px;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
    }
`;

// Widths
export const SmWidth = styled.div`
    width: 50%;

    @media (max-width: 900px) {
        width: 70%;
    }
`;

export const MdWidth = styled.div`
    width: 75%;

    @media (max-width: 900px) {
        width: 80%;
    }
`;

export const LgWidth = styled.div`
    width: 80%;

    @media (max-width: 900px) {
        width: 90% !important;
    }
`;

// Images (always responsive) //
export const Img = styled.img`
    width: 100%;
    margin: ${props => props.margin ? props.margin : "0"};
    height: auto;
    border-radius: ${props => props.rounded ? "50%" : "0"};
    float: ${props => props.float || "none"};
    max-width: ${props => props.width || "100px"};
    display: ${props => props.display || "inline"};
`;

export const InlineIcon = styled.span`
    font-size: ${props => props.size || "1 em"};
    display: ${props => props.display || "inline"};
    margin: ${props => props.margin || "0"};
`;

export const GalleryImg = styled(Img)`
    width: 100px;
    position: static;
    cursor: pointer;
    height: auto;
    &:hover {
        opacity: .6;
    }
    
    @media (max-width: 900px) {
        width: 50px;
    }
`;

// Table
export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    ${BodyFont}
`;

export const Thead = styled.thead`
    background-color: ${props => props.theme.colors.primary};
`;

export const Th = styled.th`
    padding: 8px;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
    /* border-right: 1px solid ${props => props.theme.colors.lightGrey}; */
    text-align: center;
    color: white;
`;

export const Tr = styled.tr`
    color: ${props => props.theme.colors.font.body};
`;

export const Td = styled.td`
    text-align: center;
    padding: 8px;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
`;

export const TColumnHead = styled.td`
    background-color: ${props => props.theme.colors.primary};
    color: white;
    border-top: 2px solid ${props => props.theme.colors.primary};
    font-weight: bolder;
    text-align: center;
`;

export const Tbody = styled.tbody`
    ${Td}:hover {
        background-color: ${props => rgba(props.theme.colors.primary, 0.15)};
        border-top: 2px solid ${props => props.theme.colors.primary};
        border-bottom: 2px solid ${props => props.theme.colors.primary};
    }

    ${Tr}:hover {
        border-top: 2px solid ${props => props.theme.colors.secondary};
        border-bottom: 2px solid ${props => props.theme.colors.secondary};
    }
`;

// Background with text
export const BgMediaContainer = styled.div`
    height: 100vh;
    width: 100%;
    overflow: hidden;
    ${BodyFont};
    position: relative;
`;

export const BgMedia = styled.img`
    position: relative;
    z-index: -1;
    vertical-align: middle;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: 0.4;
    @media (max-width: 900px), (max-height: 900px) {
        height: ${props => props.bodyLength > 900 ? "120vh" : "100vh"};
    }
`;

export const BgColor = styled.div`
    position: relative;
    z-index: -2;
    width: 100vw;
    /* background-color: ${props => props.bgColor ? props.bgColor : props.theme.colors.primary}; */
    background-color: white;
    @media (max-width: 900px), (max-height: 900px) {
        height: ${props => props.bodyLength > 900 ? "120vh" : "100vh"};
    }
`;

export const BgMediaModal = styled.div`
    ${BodyFont}
    text-align: center;
    background-color: ${props => props.noBg ? "" : rgb(255, 255, 255, 0.95)};
    border-radius: 5px;
    max-width: 70%;
    padding: 3rem 7rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    @media (max-width: 900px), (max-height: 900px) {
        max-width: 80%;
        padding: 10px 25px 25px 25px;
    }
`;

export const BgMediaHeading = styled.h1`
    font-size: 70px;
    font-weight: 900;
    margin-bottom: 1%;
    ${HeadingFont};
    text-transform: uppercase;
    @media (max-width: 900px), (max-height: 900px) {
        font-size: 40px
    }
`;

export const BgMediaBody = styled.div`
    font-size: 35px;
    line-height: 1.6;
    text-align: center;
    margin: 20px;
    font-weight: 500;
    @media (max-width: 900px), (max-height: 900px) {
        font-size: 24px;
        margin: 5px;
    }
`;

// Misc Misc //
export const Centered = styled.div`
    width: 100%;
    margin: ${props => props.margin ? props.margin : "auto"};
    text-align: center;
    padding: ${props => props.padding ? props.padding : "0"};
    max-width: ${props => props.width ? props.width : "100%"};
`;

export const MiddleDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Hr = styled.div`
    border-bottom: 2px solid ${props => props.theme?.colors?.primary || "black"};
    margin: ${props => props.margin ? props.margin : "15px 0"};
    width: ${props => props.width ? props.width : "100%"};
    float: ${props => props.selected ? props.selected : "none"};
    display: ${props => props.display ? props.display : "block"}
`;

export const FullWidth = styled.div`
    background-color: ${props => props.color ? props.color : props.theme.colors.primary};
    height: ${props => props.height ? props.height : "5px"};
    padding: ${props => props.padding ? props.padding : "0"};
`;

export const Recaptcha = styled.div`
    position: relative;
    max-width: 304px;
    margin: 10px auto;
`;

export const OverflowXAuto = styled.div`
    overflow-x: auto;
`;

export const Div = styled.div`
    margin: ${props => props.margin ? props.margin : "0"};
    display: ${props => props.display ? props.display : "block"};
`;

export const VerticalAlign = styled.span`
    text-align: center;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
`;

export const DevAlert = styled.div`
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;
    background-color: ${props => rgba(props.theme?.colors?.red, 0.7) || rgba("darkred", 0.7)};
    text-align: center;
    padding: 5px 0;
    color: white;
    font-size: 8px;
    font-family: ${props => props.theme?.fonts?.body || 'Arial'};
    letter-spacing: 2px;
    opacity: 1;
    transition: opacity 0.5s;
    &:hover {
        opacity: 0;
    }
`

// Modal
export const ModalCard = styled.div`
    text-align: left;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: ${props => props.theme?.colors?.background ?? "white"};
    border-radius: 5px;
    transition: 0.3s;
    padding: 30px 60px;
    width: 40%;
    z-index: 10;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 900px) {
        padding: 15px 30px;
        width: 80%;
    }

    // CSS Snippet to ensure internal words like in the message field are still readable
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
`

export const ModalContainer = styled.div`
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => rgba(props.theme.colors.grey, 0.7)};
`;

export const ProductCard = styled.div`
    display: flex;
    height: 450px;
    border: 4px solid ${props => props.theme.colors.font.body};
    transition: all 0.3s linear;
    margin: 25px 0;
    background: transparent;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    #img-container {
        max-width: 300px;
        max-height: 300px;
        padding: 0 20px;
        margin: 0 auto;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    h4 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    p {
        font-weight: 900;
    }
    
    @media (max-width: 1400px) {
        h4 {
            font-size: 18px !important;
            margin: 0;
        }
        p {
            font-size: 18px !important;
            margin: 0;
        }
    }

    &:hover {
        h4 {
            -webkit-line-clamp: 4;
            white-space: normal;
            overflow: visible;
            text-overflow: none;
        }
        p {
            color: white;
        }
        background: ${props => props.theme.colors.tertiary};
        
    }
`;

// Timeline
export const TimelineWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 25px;
    justify-content: center;
    @media (max-width: 900px) {
        flex-direction: column;
        justify-content: none;
        margin-top: 40px;
    }
`;

export const TimelineContainer = styled.div`
    flex: 33.33%;
    width: 100%;
    height: 450px;
    max-width: 450px;
    margin: 10px;
    @media (max-width: 1199px) {
        ${(props) => props.$expanded && `
            #timeline-dropdown {
                opacity: 1;
                display: block;
                margin-bottom: 25px;
            }
        `}
        margin: auto;
    }

    @media (max-height: 800px) {
        height: 300px;
        max-width: 300px;
    }
    
    &:hover {
        #timeline-dropdown {
            opacity: 1;
        }
    }
`;
export const TimelineCard = styled.div`
    &:hover {
        background: ${props => `linear-gradient(0deg, ${transparentize(0.3, props.theme.colors.tertiary)}, ${transparentize(0.3, props.theme.colors.tertiary)}), url(${props.$bgImg})`};
        background-size: cover;
        background-repeat: no-repeat;
    }
    h2 {
        color: black !important;
    }
    p {
        color: black;
        position: absolute;
        bottom: 0;
    }
    
    background: ${props => `linear-gradient(0deg, ${transparentize(0.55, "white")}, ${transparentize(0.55, "white")}), url(${props.$bgImg})`};
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.3s linear;
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    /* max-width: 400px; */
    border: 4px solid white;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-color: white;
    @media (max-width: 1600px) {
        
        p {
            font-size: 14px;
        }
    }

    @media (max-width: 1199px) {
        margin: auto;
        margin-bottom: 25px;
        height: 300px;
        max-width: 300px;
    }
`;

export const TimelineDropdown = styled.div`
    transition: all 0.3s linear;
    /* max-width: 425px; */
    opacity: 0;
    padding: 15px 10px;
    margin-top: 25px;
    background-color: black;
    border: 4px solid white;

    @media (max-width: 1199px) {
        display: none;
        margin: auto;
        max-width: 300px;
    }
`;

export const TimelineDdLink = styled(LLink)`
    text-align: center;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 28px;
    ${HeadingFont}
    color: white;
    margin: 8px 5px;

    span {
        position: relative;
        padding: 0 .1em;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: bottom;
        box-decoration-break: clone;
        z-index: 5;
    }

    @media (max-width: 900px) {
        font-size: 20px;
    }

    &:hover {
        span {
            background-image: linear-gradient(to right,${props => transparentize(0.01, props.theme.colors.tertiary)} 0%,${props => transparentize(0.01, props.theme.colors.tertiary)} 100%);
        }
        
        color: white;
    }
`;

// Images galleries
export const InstagramTag = styled.div`
    ${Centered}
    display: flex;
    align-items: center;
    background-color: black;
    transition: all 0.3s linear;
    text-align: center;
    width: 100%;
    height: auto;
    padding: 20px 0;
    position: relative;
    z-index: 1;
    
    span {
        ${HeadingFont}
        display: inline-flex;
        text-transform: uppercase;
        font-size: 60px;
        letter-spacing: 4px;
        color: white;
        margin: 10px 0;
        align-items: center;
    }

    img {
        width: 100%;
        margin-right: 20px;
        height: auto;
        max-width: 50px;
        display: inline-flex;
        align-items: center;
    }

    &:hover {
        background-color: white;
        span {
            color: ${props => props.theme.colors.primary};
        }
    }

    @media (max-width: 900px) {
        padding: 20px 0;

        span {
            font-size: 35px;
        }

        img {
            max-width: 50px;
        }
    }
`;

export const InstagramGallery = styled.div`
    margin: 0 0 -10% -30%;
    max-height: 1200px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: white;

    div {   
        position: relative;
        white-space: nowrap;
        margin-bottom: -10px;
    }
    
    img {
        max-width: 30%;
        width: 100%;
        height: auto;
        background-size:cover;
        &:hover {
            opacity: .6;
        }
    }

    @media (max-width: 900px) {
        margin: 0 0 20vh -30%;
        
        max-height: 1200px;
        img {
            max-width: 40%;
        }
    }
`;

export const FeaturedGallery = styled.div` 
    display: ${props => props.shown ? "flex" : "none"};
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const FeaturedCell = styled.div`
    flex: auto;
    position: relative;
    text-align: center;
    transition: all 0.3s linear;
    background: ${props => props.theme.colors.tertiary};
    #bg {
        width: 100%;
        max-width: 1000px;
        height: auto;
        max-height: 100%;
        opacity: 1;
        object-fit: cover;
    }

    div {
        position: absolute;
        visibility: hidden;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    &:hover h4 {
        z-index: 5;
        color: black;
        visibility: visible;
    }

    &:hover #bg {
        opacity: 0.1;
    }
`;

// Full width header
export const FullWidthHeaderImg = styled.div`
    position: relative;
    width: 100%;
    height: ${props => props.height ? props.height : "300px"};
    background-image: url(${props => props.src});
    background-position: 50% 50%; // change me around to move up and down!
    background-size: cover;
    div {
        position: absolute;
        ${HeadingBoldFont};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
export const MapContainer = styled.div`
    max-width: 80%;
    margin: 0 auto;
    padding-top: 30px;
    @media (max-width: 900px) {
     
        max-width: 100%;

    }
`;

// Map
export const FullWidthMap = styled.img`
        max-width: 100%;
    /* background: ${props => props.src} no-repeat center center fixed;  */
    background-image:    ${props => props.src};
    background-size:     cover;  
    background-repeat:   no-repeat;
    background-position: center center;   
    
`;

// Scrollable Splash on homepage
export const ScrollableSplash = styled.div`
    position: relative;
    height: 100vh;
    max-height: 1400px;
    width: 100%;
    background-color: black;
    padding-top: 50px;

    svg {
        position: absolute;
        left: 50%;
        bottom: 75px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        ${bounceArrowAnimation};
    }

    @media (max-width: 900px) {
        padding-top: 100px;
        height: 100vh;
        svg {
            bottom: 100px;
            left: 45%;
        }
    }

    @media (max-width: 450px) {
        padding-top: 100px;
        height: 80vh;
        svg {
            bottom: 40px;
            left: 45%;
        }

    }
`;

export const ClikClakContainer = styled.div`
    &:hover #clik {
        ${clikAnimation};
    }

    &:hover #clak {
        ${clakAnimation};
    }
`;

export const Clik = styled.h1`
    transition: all 0.1s linear;
    ${ClikClakLogoFont};
    color: white;
    margin: 0;
    opacity: 1;
    font-size: 22.5vw;
    letter-spacing: 1.3vw;
    line-height: 1;
    text-transform: uppercase;
    
    @media (max-width: 900px) {
        font-size: 30vw;
    }
`;

export const Clak = styled.h1`
    transition: all 0.1s linear;
    ${ClikClakLogoFont};
    color: white;
    opacity: 1;
    margin: 0 0 40px 0;
    line-height: 0.8;
    font-size: 22.5vw;
    text-transform: uppercase;

    @media (max-width: 900px) {
        font-size: 30vw;
    }
`;

export const SplashTaglineItem1 = styled.div`
    ${slideFromRightAnimation};
    animation-delay: 0;
    opacity: 0;
    color: white;
    ${BodyFont};
    font-weight: 900;
    font-size: 3vw;

    @media (max-width: 900px) {
        font-size: 5vw;
    }
`;
export const SplashTaglineItem2 = styled.div`
    ${slideFromRightAnimation};
    animation-delay: 1s;
    opacity: 0;
    color: white;
    ${BodyFont};
    font-weight: 900;
    font-size: 3vw;

    @media (max-width: 900px) {
        font-size: 5vw;
    }
`;

export const SplashTaglineItem3 = styled.div`
    ${slideFromRightAnimation};
    animation-delay: 2s;
    color: white;
    opacity: 0;
    ${BodyFont};
    font-weight: 900;
    font-size: 3vw;

    @media (max-width: 900px) {
        font-size: 5vw;
    }
`;

export const SplashTaglineItem4 = styled.div`
    ${slideFromRightAnimation};
    animation-delay: 3s;
    color: white;
    opacity: 0;
    ${BodyFont};
    font-weight: 900;
    font-size: 3vw;

    @media (max-width: 900px) {
        font-size: 5vw;
    }
`;

export const SplashTaglineItem5 = styled.div`
    ${slideFromRightAnimation};
    animation-delay: 4s;
    color: white;
    opacity: 0;
    ${BodyFont};
    font-weight: 900;
    font-size: 3vw;
    margin-top: 35px;

    @media (max-width: 1300px) {
        font-size: 5vw;
        
        button {
            font-size: 14px;
            padding: 5px 25px;
            font-weight: 600;
        }
    }
`;

export const SplashHeroContainer = styled.div`
    margin: auto;
    width: 90%;
    padding: 1%;

    @media (max-width: 900px) {
        margin: auto;
        width: 90%;
        padding: 1%;

        
        h1 {
            font-size: 30px;
        }

        p {
            font-size: 16px;
        }

    }
`;



export const TabsContainer = styled.div`
    .tab-list {
        margin: 25px 0;
        padding: 0;
    }

    .tab-list-item {
        display: inline-block;
        cursor: pointer;
        list-style: none;
        padding-right: 2vw;
        color: ${props => props.theme.colors.font.body};
        span {
            ${HeadingFont}
            text-transform: uppercase;
            font-size: 24px;
            position: relative;
            padding: 0 .1em;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: bottom;
            box-decoration-break: clone;
            z-index: 5;
        }
        &:hover {
            span {
                background-image: ${props => `linear-gradient(to right,${transparentize(0.01, (props.theme.value !== SCHEMES.DARK ? props.theme.colors.tertiary : props.theme.colors.primary))} 0%,${transparentize(0.01, (props.theme.value !== SCHEMES.DARK ? props.theme.colors.tertiary : props.theme.colors.primary))} 100%)`};
            }
        }
    }

    .tab-list-active {
        span {
            position: relative;
            padding: 0 .1em;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: bottom;
            box-decoration-break: clone;
            z-index: 5;
            background-image: ${props => `linear-gradient(to right,${transparentize(0.01, (props.theme.value !== SCHEMES.DARK ? props.theme.colors.tertiary : props.theme.colors.primary))} 0%,${transparentize(0.01, (props.theme.value !== SCHEMES.DARK ? props.theme.colors.tertiary : props.theme.colors.primary))} 100%)`};
        }
    }

    @media (max-width: 900px) {
        font-size: 20px;
    }
`;


export const IframeContainer = styled.div`
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
`;


export const Iframe = styled.iframe.attrs({ 
    frameBorder:"0" ,
    allowFullScreen: true,
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
})`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

// Flip card
export const FlipCardGroup = styled.div`
/* can delete this later if unused */
`;

export const FlipCardContainer = styled.div`
    background-color: transparent;
    width: 100%;
    height: 400px;
    perspective: 1000px;
    margin: 10px 0;

    .fcard-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        :first-child {
            h1 {
                transition: opacity 1s;
                opacity: 1;
                color: ${props => props.theme.value === SCHEMES.DARK ? "black" : "white"} !important;
            }
        }
    }

    
    @media (max-width: 900px) {
        
        ${(props) => (props.flipped) && `
            .fcard-inner {
                transform: rotateY(180deg);
                :first-child {
                    h1 {
                        opacity: 0;
                        color: transparent !important;
                    }
                }
            }
        `}
    }

    @media (min-width: 901px) {
        &:hover {
            .fcard-inner {
                transform: rotateY(180deg);
                :first-child {
                    h1 {
                        color: transparent !important;
                    }
                }
            }
        }
    }


    
`;

export const FlipCardFront = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-color: ${props => props.theme.value === SCHEMES.DARK ? "white" : "black"};
    h1 {
        color: ${props => props.theme.value === SCHEMES.DARK ? "black" : "white"} !important;
    }
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
`;

export const FlipCardBack = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    background-color: white;
    background: ${props => `linear-gradient(0deg, ${transparentize(0.2, props.color)}, ${transparentize(0.2, props.color)}), url(${props.$bgImg})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-clip: content-box;
    div {
        padding: 10px;
    }
    p {
        color: black;
        font-size: 20px;
        
        @media (max-width: 900px) {
            font-size: 18px;
        }
    }
    h1 {
        color: transparent;
    }
    transform: rotateY(180deg);
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
`;

export const FaqContainer = styled.div`
    max-width: 60%;
    margin: 0 auto;
            
    @media (max-width: 1200px) {
        max-width: 100%;
    }
`;