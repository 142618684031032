import styled, { css }  from 'styled-components';
import { Field } from "formik";
import { BodyFont } from './text';
import 'react-quill/dist/quill.snow.css';

export const FField = styled(Field)`
    font-size: 16px;
    ${BodyFont}
    width: ${props => props.width ? props.width : "100%"};
    padding: 14px;
    margin: 0 0 5px 0;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 2px;
    background-color: white;
    resize: none;
    /* Set focus if error */
    outline-color: ${props => props.error ? props.theme.colors.red : "none"};
    box-shadow: 0 0 2pt 1pt ${props => props.error ? props.theme.colors.red : "none"};

    &:focus {
        outline-color: ${props => props.theme.colors.primary};
        box-shadow: 0 0 2pt 1pt ${props => props.theme.colors.primary};
    }

    ${props => props.component === "textarea" && css`
        height: 20vh;
        padding: 15px;
    `}

    @media (max-width: 900px) {
        width: 90%;
    }
`;

export const SearchField = styled(Field)`
    font-size: 14px;
    ${BodyFont}
    width: ${props => props.width ? props.width : "100%"};
    padding: 8px;
    margin: 0;
    box-sizing: border-box;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 2px;
    background-color: white;
    resize: none;
    /* Set focus if error */
    outline-color: ${props => props.error ? props.theme.colors.red : "none"};
    box-shadow: 0 0 2pt 1pt ${props => props.error ? props.theme.colors.red : "none"};

    &:focus {
        outline-color: ${props => props.theme.colors.primary};
        box-shadow: 0 0 2pt 1pt ${props => props.theme.colors.primary};
    }

    ${props => props.component === "textarea" && css`
        height: 20vh;
        padding: 15px;
    `}
`;


export const Input = styled.input`
    font-size: 16px;
    ${BodyFont}
    width: ${props => props.width ? props.width : "100%"};
    padding: 10px;
    margin: 0 0 5px 0;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 2px;
    background-color: white;
    resize: none;
    
    &:focus {
        outline-color: ${props => props.theme.colors.primary};
        box-shadow: 0 0 2pt 1pt ${props => props.theme.colors.primary};
    }

    ${props => props.component === "textarea" && css`
        height: 150px;
        padding: 15px;
    `}
    
    @media (max-width: 900px) {
        width: 90%;
    }
`;

export const RField = styled(Field)`
    transform: scale(1.25);
    margin: 15px 15px 0 0;
`;

export const CField = styled(Field)`
    transform: scale(1.25);
    margin: 15px 15px 0 0;
`;

export const SField = styled(Field)`
    padding: 12px;
    option {
        ${BodyFont};
    }
`;

// File input
export const FileInputLabel = styled.label`
    display: block;
    cursor: pointer;
    color: ${props => props.selected ? props.theme.colors.red : props.theme.colors.font.body};
    border: 2px solid ${props => props.selected ? props.theme.colors.red : props.theme.colors.primary};
    font-size: 18px;
    padding: 20px;
    ${BodyFont};
`;
export const FileInput = styled.input`
    display: none;
`;

// Tags
export const TagsContainer = styled.div`
    margin: 5px 0 0 0;
    .tags-input {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        min-height: 45px;
        width: 480px;
        padding: 0 8px;
        border: 1px solid black;
        border-radius: 2px;
        &:focus-within {
            border: 1px solid #0052cc;
        }
        input {
            flex: 1;
            border: none;
            height: 45px;
            font-size: 18px;
            ${BodyFont};
            &:focus {
                outline: transparent;
            }
        }
    }

    #tags {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 8px 0 0 0;
    }

    .tag {
        width: auto;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        ${BodyFont};
        color: #fff;
        padding: 0 8px;
        font-size: 18px;
        list-style: none;
        border-radius: 6px;
        margin: 0 8px 8px 0;
        background: ${props => props.theme.colors.primary};
        .tag-close-icon {
            display: block;
            width: 20px;
            height: 20px;
            line-height: 18px;
            text-align: center;
            font-size: 18px;
            margin-left: 8px;
            color: #0052cc;
            border-radius: 50%;
            background: #fff;
            cursor: pointer;
        }
    }

    @media screen and (max-width: 567px) {
        .tags-input {
            width: calc(100vw - 32px);
        }
    }
`;
// Search
export const SearchContainer = styled.div`
    transition: all 0.3s linear;
    width: 100%;
    max-width: ${props => props.width ? "100%" : "300px"};
    position: relative;
    display: inline-flex;
    justify-content: center;
    svg {
        position: absolute;
        transition: all 0.1s linear;
        top: 17px;
        color: black;
        left: 15px;
        &:hover {
            top: 20px;
            color: ${props => props?.theme?.colors?.green ?? "green"};
            cursor: pointer;
        }
    }

    input {
        padding-left: 40px;  
    }

    &:hover {
        width: 100%;
        max-width: ${props => props.width ? "100%" : "300px"};
    }

    @media (max-width: 900px) {
        width: 100% !important;
        
        svg {
            top: 20px;
            left: 30px;
            transform: scale(1.3);
            color: black;
        }
    }
`;