// ** font constants and colors only used as defaults if nothing loaded
// ** to import custom local font under globalStyle since I will always have to hardcode the font constant because not a default html font nor a Google font if importing from webfontloader library
export const BTYPES = {
    INVERTED: "inverted",
    TEXTED: "texted",
}

export const PLACEHOLDER = {
    FIRST_NAME: "Taylor",
    LAST_NAME: "Doe",
    EMAIL: "taylor_doe@email.com",
    PHONE: "+1 (123) 456-7890",
    BODY: "Detail what you want to say here.",
    PASSWORD: "*********************",
}

export const SIZES = {
    XXS: "xxs",
    XS: "xs",
    SM: "sm",
    MD: "md",
    LG: "lg",
    XL: "xl",
    XXL: "2xl",
    XXXL: "3xl",
};

export const DEFAULT_SITE = {
    NAME: "Fire React Base",
    LOGO: {
        WIDTH: 100,
        URL: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Flogos%2Flogo192.png?alt=media&token=d327bc99-6ee8-496e-86c7-0206244b837b",
        SHOW_TITLE: true,
    },
    HERO: {
        HEADING: "Hero Section",
        BODY: `This is the homepage hero section, customize it as you please, please. Dolore irure deserunt occaecat tempor. 
            Dolore reprehenderit ut consequat anim officia amet. Laboris officia ea eu elit consectetur sit dolor duis adipisicing reprehenderit reprehenderit deserunt reprehenderit quis. 
            Fugiat est reprehenderit quis labore aute anim in labore officia non ut aliquip mollit. In laboris amet amet occaecat. Laboris minim culpa cillum veniam adipisicing et deserunt sit.`,
        CTA: {
            LINK: "/about",
            TEXT: "Call to Action",
            SIZE: SIZES.LG,
            COLOR: "black",
        },
        BANNER: "https://firebasestorage.googleapis.com/v0/b/test-fire-react-base.appspot.com/o/public%2Fbanners%2FDSC_0047.JPG?alt=media&token=8d4ff53c-11c2-4849-9479-6cd091598635",
    },
    EMAILS: {
        MESSENGERS: [
            "hi@minute.tech",
        ],
        SUPPORT: "help@minute.tech",
        NOREPLY: "noreply@minute.tech",
    },
    THEME: {
        FONTS: {
            BODY: "Roboto Regular",
            HEADING: "Roboto Bold",
        },
        SCHEMES: {
            LIGHT: {
                VALUE: "light",
                COLORS: {
                    PRIMARY: "dodgerblue",
                    SECONDARY: "hotpink",
                    TERTIARY: "tomato",
                    RED: "firebrick",
                    GREEN: "green",
                    YELLOW: "gold",
                    GREY: "grey",
                    LIGHT_GREY: "lightgrey",
                    BLUE: "navy",
                    FONT: {
                        HEADING: "black",
                        BODY: "black",
                        LINK: "navy",
                    },
                    BACKGROUND: "white",
                },
            },
            DARK: {
                VALUE: "dark",
                COLORS: {
                    PRIMARY: "dodgerblue",
                    SECONDARY: "hotpink",
                    TERTIARY: "tomato",
                    RED: "firebrick",
                    GREEN: "green",
                    YELLOW: "gold",
                    GREY: "grey",
                    LIGHT_GREY: "lightgrey",
                    BLUE: "navy",
                    FONT: {
                        HEADING: "white",
                        BODY: "white",
                        LINK: "lightblue",
                    },
                    BACKGROUND: "#242423",
                },
            },
        },
    },
};

export const SCHEMES = {
    LIGHT: "light",
    DARK: "dark",
};

export const EXAMPLE_PRODUCTS = [
    {
        name: "Tee Shirt",
        sku: "CC-SHIRT",
        description: `Ullamco minim irure cillum ad. 
        Deserunt qui nisi cillum dolore. Ad duis commodo consequat do aute est mollit adipisicing ut in. 
        Qui deserunt ullamco adipisicing adipisicing minim. Elit irure aliqua laborum exercitation est nostrud veniam cupidatat ad laboris incididunt. 
        Culpa cillum elit ex ea cillum veniam do Lorem pariatur tempor aliquip velit.`,
        photos: [
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest1-removebg-preview.png?alt=media&token=3b4bd3c3-3287-4b26-9e37-1c66bbbe1219", 
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest2-removebg-preview.png?alt=media&token=bf2d49bf-9afd-4cde-bc1c-80ef19208daf",
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest4-removebg-preview.png?alt=media&token=b66a2337-53e5-4505-8a6c-3046429d9aa8",
        ],
        _tags: [
            "eco",
            "custom",
            "apparel-accessories"
        ]
    },
    {
        name: "Gift Kit",
        sku: "CC-KIT",
        description: `Esse sint labore amet cillum exercitation aliquip velit aute amet. 
        Cillum sit tempor dolor reprehenderit culpa quis. Minim aute elit id commodo irure non non incididunt. 
        Reprehenderit irure duis incididunt sunt. Eu amet aute officia aliquip laboris aute.`,
        photos: [
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest3-removebg-preview.png?alt=media&token=7f4aff52-722e-4ac2-bac7-b17aeca4cb60", 
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest2-removebg-preview.png?alt=media&token=bf2d49bf-9afd-4cde-bc1c-80ef19208daf",
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest1-removebg-preview.png?alt=media&token=3b4bd3c3-3287-4b26-9e37-1c66bbbe1219", 
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftester-removebg-preview.png?alt=media&token=5234abbb-a78f-45da-b8f8-07d519ece481", 
        ],
        _tags: [
            "eco",
            "in-stock",
            "kit",
            "trendy"
        ]
    },
    {
        name: "Warm Gloves",
        sku: "CC-GLOVES",
        description: `Aute ex commodo quis irure incididunt tempor. 
        Labore elit cupidatat ex fugiat mollit nulla magna ut voluptate Lorem aliquip sint et occaecat. 
        Culpa eu labore voluptate dolore consectetur ipsum duis.`,
        photos: [
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest4-removebg-preview.png?alt=media&token=b66a2337-53e5-4505-8a6c-3046429d9aa8", 
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest1-removebg-preview.png?alt=media&token=3b4bd3c3-3287-4b26-9e37-1c66bbbe1219", 
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest2-removebg-preview.png?alt=media&token=bf2d49bf-9afd-4cde-bc1c-80ef19208daf",
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftester-removebg-preview.png?alt=media&token=5234abbb-a78f-45da-b8f8-07d519ece481", 
        ],
        _tags: [
            "eco",
            "in-stock",
            "beauty-wellness",
            "classics"
        ]
    },
    {
        name: "Travel Backpack",
        sku: "CC-BACKPACK",
        description: `Eu deserunt occaecat laboris consectetur cillum proident ea commodo deserunt proident commodo tempor qui consectetur. 
        Veniam amet cillum magna laboris labore adipisicing laboris adipisicing aliqua qui id ut. Ullamco cupidatat consequat qui dolore pariatur eu.
        Qui elit voluptate aute dolore ex. Et cillum in tempor excepteur laboris duis ea. Amet consequat pariatur exercitation excepteur nulla. 
        Irure consectetur reprehenderit mollit magna voluptate laborum voluptate Lorem incididunt tempor eu ea commodo incididunt.`,
        photos: [
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest2-removebg-preview.png?alt=media&token=bf2d49bf-9afd-4cde-bc1c-80ef19208daf", 
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest2-removebg-preview.png?alt=media&token=bf2d49bf-9afd-4cde-bc1c-80ef19208daf",
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftester-removebg-preview.png?alt=media&token=5234abbb-a78f-45da-b8f8-07d519ece481", 
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest1-removebg-preview.png?alt=media&token=3b4bd3c3-3287-4b26-9e37-1c66bbbe1219", 
        ],
        _tags: [
            "eco",
            "bags",
            "quick-turn",
            "beauty-wellness",
            "trendy"
        ]
    },
    {
        name: "Ceramic Mug",
        sku: "CC-MUG",
        description: `Eu labore elit fugiat proident adipisicing. 
        Magna dolore ea exercitation proident. Non eu ipsum ut ipsum sint. 
        Aliquip nostrud proident sit elit.`,
        photos: [
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest6-removebg-preview.png?alt=media&token=3e40ece3-1c02-4159-9b7d-1928cf51a0d1", 
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest2-removebg-preview.png?alt=media&token=bf2d49bf-9afd-4cde-bc1c-80ef19208daf",
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest4-removebg-preview.png?alt=media&token=b66a2337-53e5-4505-8a6c-3046429d9aa8",
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftester-removebg-preview.png?alt=media&token=5234abbb-a78f-45da-b8f8-07d519ece481", 
        ],
        _tags: [
            "eco",
            "quick-turn",
            "home-goods",
            "trendy"
        ]
    },
    {
        name: "Battery Bank",
        sku: "CC-BATTERY",
        description: `Eu labore elit fugiat proident adipisicing. 
        Magna dolore ea exercitation proident. Non eu ipsum ut ipsum sint. 
        Aliquip nostrud proident sit elit.`,
        photos: [
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftester-removebg-preview.png?alt=media&token=5234abbb-a78f-45da-b8f8-07d519ece481", 
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest2-removebg-preview.png?alt=media&token=bf2d49bf-9afd-4cde-bc1c-80ef19208daf",
            "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Ftest%2Ftest4-removebg-preview.png?alt=media&token=b66a2337-53e5-4505-8a6c-3046429d9aa8",
        ],
        _tags: [
            "eco",
            "custom",
            "office-tech",
            "travel",
            "trendy"
        ]
    },
]

// Remember to also replace Products page rconst state output of each category tags!
export const TAGS = [
    {
        name: "Bags + Pouches",
        type: "sub-category",
        abbrev: "bags-pouches",
    },
    {
        name: "Beauty",
        type: "sub-category",
        abbrev: "beauty"
    },
    {
        name: "Candles",
        type: "sub-category",
        abbrev: "candles"
    },
    {
        name: "Confections",
        type: "sub-category",
        abbrev: "confections"
    },
    {
        name: "Cosmetic Bags + Pouches",
        type: "sub-category",
        abbrev: "cosmetic-bags-pouches"
    },
    {
        name: "Fun + Games",
        type: "sub-category",
        abbrev: "fun-games"
    },
    {
        name: "Travel",
        type: "sub-category",
        abbrev: "travel"
    },
    {
        name: "Masks",
        type: "sub-category",
        abbrev: "masks"
    },
    {
        name: "Backpacks",
        type: "sub-category",
        abbrev: "backpacks"
    },
    {
        name: "Cases + Pouches",
        type: "sub-category",
        abbrev: "cases-pouches"
    },
    {
        name: "Fanny Packs + Crossbody Bags",
        type: "sub-category",
        abbrev: "fanny-packs-crossbody-bags"
    },
    {
        name: "Glasses",
        type: "sub-category",
        abbrev: "glasses"
    },
    {
        name: "Jewelry",
        type: "sub-category",
        abbrev: "jewelry"
    },
    {
        name: "Keychains + Accessories",
        type: "sub-category",
        abbrev: "keychains-accessories"
    },
    {
        name: "Socks",
        type: "sub-category",
        abbrev: "socks"
    },
    {
        name: "Stickers, Patches + Pins",
        type: "sub-category",
        abbrev: "stickers-patches-pins"
    },
    {
        name: "Totes + Duffles",
        type: "sub-category",
        abbrev: "totes-duffles"
    },
    {
        name: "Hats + Scarves",
        type: "sub-category",
        abbrev: "hats-scarves"
    },
    {
        name: "Quick Turn",
        type: "main-category",
        abbrev: "quick-turn"
    },
    {
        name: "In Stock",
        type: "main-category",
        abbrev: "in-stock"
    },
    {
        name: "Custom",
        type: "main-category",
        abbrev: "custom"
    },
    {
        name: "New",
        type: "special",
        abbrev: "new"
    },
    {
        name: "Coming Soon",
        type: "special",
        abbrev: "coming-soon"
    },
];

export const projects = [
    {
        name: "Test Project A",
        photos: [
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2F415-155154.jpg?alt=media&token=5ae6d4b1-63a8-4d93-9931-bf1124190e6a",
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2Fdougs-favorite1.jpg?alt=media&token=37d7d768-5140-4fb0-b389-82bb52d5fbbb",
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2Fdougs-favorite8.png?alt=media&token=c78cc616-5a62-4b7e-9793-50a2d9ff8c60",
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2Fana-tester-1237.jpg?alt=media&token=8842add6-2aa0-47ca-8cbf-4c4945c4db51"
        ],
        description: `Consectetur laboris Lorem enim duis ipsum occaecat minim. Irure sint nostrud eu aliqua fugiat adipisicing. 
        Veniam cillum quis excepteur deserunt qui amet cillum. Aute non est nisi ipsum magna est velit nostrud sit mollit excepteur veniam consequat. 
        Anim culpa cillum cillum non duis in ad do aute. Irure ut esse aute esse voluptate cillum esse.`
    },
    {
        name: "Test Project B",
        photos: [
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2Fdougs-favorite8.png?alt=media&token=c78cc616-5a62-4b7e-9793-50a2d9ff8c60",
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2Fana-tester-1237.jpg?alt=media&token=8842add6-2aa0-47ca-8cbf-4c4945c4db51",
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2F415-155154.jpg?alt=media&token=5ae6d4b1-63a8-4d93-9931-bf1124190e6a",
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2Fdougs-favorite1.jpg?alt=media&token=37d7d768-5140-4fb0-b389-82bb52d5fbbb",
        ],
        description: `Consectetur laboris Lorem enim duis ipsum occaecat minim. Irure sint nostrud eu aliqua fugiat adipisicing. 
        Veniam cillum quis excepteur deserunt qui amet cillum. Aute non est nisi ipsum magna est velit nostrud sit mollit excepteur veniam consequat. 
        Anim culpa cillum cillum non duis in ad do aute. Irure ut esse aute esse voluptate cillum esse.`
    },
    {
        name: "Test Project C",
        photos: [
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2Fdougs-favorite1.jpg?alt=media&token=37d7d768-5140-4fb0-b389-82bb52d5fbbb",
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2F415-155154.jpg?alt=media&token=5ae6d4b1-63a8-4d93-9931-bf1124190e6a",
            "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fproducts%2Fana-tester-1237.jpg?alt=media&token=8842add6-2aa0-47ca-8cbf-4c4945c4db51",
        ],
        description: `Consectetur laboris Lorem enim duis ipsum occaecat minim. Irure sint nostrud eu aliqua fugiat adipisicing. 
        Veniam cillum quis excepteur deserunt qui amet cillum. Aute non est nisi ipsum magna est velit nostrud sit mollit excepteur veniam consequat. 
        Anim culpa cillum cillum non duis in ad do aute. Irure ut esse aute esse voluptate cillum esse.`
    },
];

export const DEFAULT_PRODUCT_IMAGES = {
    COMING_SOON: "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fsite%2Fcoming-soon.jpg?alt=media&token=6c0acac0-cde5-46a2-a7ab-6e4f7637fc0b",
    MISSING: "https://firebasestorage.googleapis.com/v0/b/test-clikclak-com.appspot.com/o/public%2Fsite%2Fno-image.png?alt=media&token=964013dd-93a4-4284-b15f-a3e1ed02a78e",
}

