import React, { Component } from 'react'
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet-async';
import { withTheme } from 'styled-components';

import { DEFAULT_PRODUCT_IMAGES, SCHEMES, SIZES, TAGS } from '../../../utils/constants';
import { withRouter } from '../../../utils/hocs';
import { Button } from '../../../utils/styles/buttons';
import { Centered, FullWidth, Hr, Iframe, IframeContainer, MdContainer, OverflowXAuto, Spinner, Table, Tbody, TColumnHead, Td, Th, Thead, Tr, Wrapper } from '../../../utils/styles/misc';
import { Body, H2, LLink } from '../../../utils/styles/text';
import PhotoGallery from "../../misc/PhotoGallery";
import Tabs from '../misc/Tabs';

function makeTagsString(arr) {
    let tagsArr = [...arr];
    var newTagIndex = tagsArr.indexOf("new");
    if (newTagIndex !== -1) {
        tagsArr.splice(newTagIndex, 1);
    }
    if (tagsArr.length === 1) return tagsArr[0];
    const firsts = tagsArr.slice(0, tagsArr.length - 1);
    const last = tagsArr[tagsArr.length - 1];
    
    return firsts.join(', ') + ', ' + last;
}

class ProductPage extends Component {
    render() {
        // console.log("this.props.location.state?.prevQuerySet: " + (this.props.location.state?.prevQuerySet ?? "/products/query/search=all"));
        if(!this.props.product){
            return (
                <Wrapper>               
                    <Helmet>
                        <title>{this.props.product.name} {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
                    </Helmet>
                    <Centered>
                        <H2>Loading product... <Spinner /></H2>
                    </Centered>
                </Wrapper>
            )
        } else {
            let namedTags = [];
            const productTags = this.props.product._tags;
            // Now loop through queried tags and see if they exist in categories constant. If they do, grab the label, if they don't, then return invalid search
            productTags.forEach((productTag, q) =>
                TAGS.forEach((constantTag, c) => {
                    if (constantTag.abbrev === productTag) {
                        namedTags.push(constantTag);
                    }
                })
            );

            return (
                <>
                <Wrapper>               
                    <Helmet>
                        <title>{this.props.product.name} {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
                    </Helmet>
                    {(this.props.readOnlyFlags.isAdmin && !this.props.product.isShown) && (
                        <FullWidth color={this.props.theme.colors.red} padding="5px" height="auto">
                            <Body center>Product not currently shown publicly!</Body>
                        </FullWidth>
                    )}
                    <Body margin="5px 0">
                        <LLink 
                            color={this.props.theme.colors.font.body} 
                            to={`/products`}
                            hovercolor={this.props.theme.colors.tertiary}
                        >
                            Products
                        </LLink>
                        &nbsp;/&nbsp;
                        <LLink 
                            color={this.props.theme.colors.font.body} 
                            italic={namedTags.length === 1 ? true : undefined} 
                            hovercolor={this.props.theme.colors.tertiary}
                            to={`/products/query/tags=${namedTags[0].abbrev}=page_0`}
                        >
                            {namedTags[0].name}
                        </LLink>
                        
                        {namedTags.length > 1 && (
                            <>
                            &nbsp;/&nbsp;
                            <LLink 
                                color={this.props.theme.colors.font.body} 
                                hovercolor={this.props.theme.colors.tertiary}
                                to={`/products/query/tags=${namedTags[1].abbrev}=page_0`}
                            >
                                {namedTags[1].name}
                            </LLink>
                            </>
                        )}
                        &nbsp;/&nbsp;
                        <i>{this.props.product.name}</i>
                    </Body>
                    
                    <Grid fluid>
                        <Row style={{margin: "50px 0"}}>
                            <Col lg={12} xl={6}>
                                <PhotoGallery 
                                    photos={this.props.product?.photos} 
                                    thumb={this.props.product?.thumb ? this.props.product?.thumb : (this.props.product?.photos ? this.props.product?.photos[0] : DEFAULT_PRODUCT_IMAGES.MISSING)}
                                    alt={this.props.product.name} 
                                    theme={this.props.theme}
                                />
                            </Col>
                            <Col lg={12} xl={6} style={{padding: "15px 0px"}}>
                                { this.props.product._tags && this.props.product._tags.includes("new") && (
                                    <Body size={SIZES.MD} margin="0" color={this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : this.props.theme.colors.tertiary} bold>NEW!</Body>
                                )}
                                { this.props.product._tags && this.props.product._tags.includes("coming-soon") && (
                                    <Body size={SIZES.MD} margin="0" color={this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : this.props.theme.colors.tertiary} bold>COMING SOON!</Body>
                                )}
                                <H2 margin="0" alignLeft normalCase>{this.props.product.name}</H2>
                                <Body margin="0" size={SIZES.XS}><b>Tags:</b> {this.props.product._tags.length !== 0 && makeTagsString(this.props.product._tags)}</Body>
                                { this.props.product.sku && ( <Body margin="0" size={SIZES.XS} color={this.props.theme.colors.grey}>SKU: {this.props.product.sku}</Body> ) }
                                <Body margin="0" size={SIZES.SM}  dangerouslySetInnerHTML={{__html: this.props.product.description}}></Body>
                                
                                <Hr margin={"100px 0 0 0"} display={this.props.product._tags.includes("custom") ? "none" : "block"} />
                                <Tabs>
                                    <div label="Pricing" hidden={this.props.product._tags.includes("custom") ? true : false}>
                                        {   
                                            (
                                                this.props.product.qtyPrice50 || 
                                                this.props.product.qtyPrice100 || 
                                                this.props.product.qtyPrice250 || 
                                                this.props.product.qtyPrice500 || 
                                                this.props.product.qtyPrice1000 || 
                                                this.props.product.qtyPrice2500 ||
                                                this.props.product.qtyPrice5000 
                                            ) && (
                                                    <OverflowXAuto>
                                                        <Table>
                                                            <Thead>
                                                                <Tr>
                                                                    <Th>QTY</Th>
                                                                    {this.props.product.qtyPrice50 ? <Th>50</Th> : null}
                                                                    {this.props.product.qtyPrice100 ? <Th>100</Th> : null}
                                                                    {this.props.product.qtyPrice250 ? <Th>250</Th> : null}
                                                                    {this.props.product.qtyPrice500 ? <Th>500</Th> : null}
                                                                    {this.props.product.qtyPrice1000 ? <Th>1000</Th> : null}
                                                                    {this.props.product.qtyPrice2500 ? <Th>2500</Th> : null}
                                                                    {this.props.product.qtyPrice5000 ? <Th>5000</Th> : null}
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                <Tr>
                                                                    <TColumnHead>PRICE (R)</TColumnHead>
                                                                    {this.props.product.qtyPrice50 ? <Td>{this.props.product.qtyPrice50}</Td> : null}
                                                                    {this.props.product.qtyPrice100 ? <Td>{this.props.product.qtyPrice100}</Td> : null}
                                                                    {this.props.product.qtyPrice250 ? <Td>{this.props.product.qtyPrice250}</Td> : null}
                                                                    {this.props.product.qtyPrice500 ? <Td>{this.props.product.qtyPrice500}</Td> : null}
                                                                    {this.props.product.qtyPrice1000 ? <Td>{this.props.product.qtyPrice1000}</Td> : null}
                                                                    {this.props.product.qtyPrice2500 ? <Td>{this.props.product.qtyPrice2500}</Td> : null}
                                                                    {this.props.product.qtyPrice5000 ? <Td>{this.props.product.qtyPrice5000}</Td> : null}
                                                                </Tr>
                                                            </Tbody>
                                                        </Table>
                                                    </OverflowXAuto>
                                            )
                                        }
                                        <br/>
                                        {this.props.product.priceIncludes && (<Body margin="5px 0" size={SIZES.SM}><b>Price Includes:</b> {this.props.product.priceIncludes}</Body>)}
                                        {this.props.product.runCharge && (<Body margin="5px 0" size={SIZES.SM}><b>Run Charge:</b> {this.props.product.runCharge}</Body>)}
                                        {this.props.product.leadTime && (<Body margin="5px 0" size={SIZES.SM}><b>Lead Time:</b> {this.props.product.leadTime}</Body>)}
                                        {this.props.product.setupCharge && (<Body margin="5px 0" size={SIZES.SM}><b>Setup:</b> {this.props.product.setupCharge}</Body>)}
                                        {this.props.product.sampleFee && (<Body margin="5px 0" size={SIZES.SM}><b>Sample Fee:</b> {this.props.product.sampleFee}</Body>)}
                                        {this.props.product.pricingMisc && (<Body margin="10px 0 0 0" size={SIZES.SM}><span className="remove-p-margin" dangerouslySetInnerHTML={{__html: this.props.product.pricingMisc}} /></Body>)}
                                    </div>
                                    <div label="Specs" hidden={this.props.product._tags.includes("custom") ? true : false}>
                                        {this.props.product.specsIncludes && (<Body margin="5px 0" size={SIZES.SM}><b>Includes:</b> {this.props.product.specsIncludes}</Body>)}
                                        {this.props.product.packagingIncluded && (<Body margin="5px 0" size={SIZES.SM}><b>Packaging included:</b> {this.props.product.packagingIncluded}</Body>)}
                                        {this.props.product.materials && (<Body preWrap margin="5px 0" size={SIZES.SM}><b>Material(s):</b> {this.props.product.materials}</Body>)}
                                        {this.props.product.closure && (<Body preWrap margin="5px 0" size={SIZES.SM}><b>Closure:</b> {this.props.product.closure}</Body>)}
                                        {this.props.product.dimensions && (<Body preWrap margin="5px 0" size={SIZES.SM}><b>Dimensions:</b> {this.props.product.dimensions}</Body>)}
                                        {this.props.product.colors && (<Body margin="5px 0" size={SIZES.SM}><b>Color(s):</b> {this.props.product.colors}</Body>)}
                                        {this.props.product.maxImprint && (<Body margin="5px 0" size={SIZES.SM}><b>Max Imprint size(s):</b> {this.props.product.maxImprint}</Body>)}
                                        {this.props.product.maxColors && (<Body margin="5px 0" size={SIZES.SM}><b>Max Color(s):</b> {this.props.product.maxColors}</Body>)}
                                        {this.props.product.additionalSpecs && (<Body margin="10px 0 0 0" size={SIZES.SM}><b>Additional Specs:</b><span className="remove-p-margin" dangerouslySetInnerHTML={{__html: this.props.product.additionalSpecs}} /></Body>)}
                                    </div>
                                    <div label="Add-ons" hidden={this.props.product.addOns ? false : true}>
                                        {this.props.product.addOns && (<Body margin="10px 0 0 0" size={SIZES.SM}><b>Add-ons:</b><span className="remove-p-margin" dangerouslySetInnerHTML={{__html: this.props.product.addOns}} /></Body>)}
                                    </div>
                                    <div label="Assets" hidden={this.props.product.assetsUrl ? false : true}>
                                        <a href={this.props.product.assetsUrl[0]} download target={"_blank"} rel="noreferrer">
                                            <Button type="button" size={SIZES.SM}>
                                                Download assets
                                            </Button>
                                        </a>
                                    </div>
                                
                                </Tabs>
                            </Col>
                        </Row>
                    </Grid>
                    <br/>
                    <Hr display={this.props.product._tags.includes("custom") ? "none" : "block"}/>
                    {this.props.product.videoUrl && (
                        <MdContainer>
                            <IframeContainer>
                                <Iframe
                                    src={this.props.product.videoUrl || "https://player.vimeo.com/video/738667227?h=cffc5dbed5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}
                                    id={`${this.props.product.id}`}
                                    title={`${this.props.product.name} product video`}
                                />
                                <script src="https://player.vimeo.com/api/player.js"></script>
                            </IframeContainer>
                        </MdContainer>
                    )}
                </Wrapper>
                </>
               
            )
        }
        
    }
}

export default withTheme(withRouter(ProductPage));