import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet-async';
import { FaChevronDown } from 'react-icons/fa';
import { withTheme } from 'styled-components';
import { firestore } from '../../../Fire';
import { SIZES } from '../../../utils/constants';
import { urlify } from '../../../utils/misc';

import { Button } from '../../../utils/styles/buttons';
import { Clik, Clak, ScrollableSplash, SplashTaglineItem2, SplashTaglineItem1, SplashTaglineItem3, SplashTaglineItem4, SplashTaglineItem5, FeaturedGallery, FeaturedCell, FullWidth, ClikClakContainer, InstagramTag, Img, SplashHeroContainer } from '../../../utils/styles/misc';
import { ALink, Body, BoldHeading, H1, H4, LLink } from '../../../utils/styles/text';

class Home extends Component {
    constructor(props) {
        super(props)

        this.myRef = React.createRef()  
        this.state = {
            featuredProducts: "",
            loadingFeaturedProducts: true,
        } 
    }

    componentDidMount = async () => {
        const q = query(collection(firestore, "featuredProducts"), orderBy("position"), limit(6));
        const querySnapshot = await getDocs(q);
        let tempFeaturedProducts = [];
        querySnapshot.forEach((productDoc) => {
            let docWithMore = Object.assign({}, productDoc.data());
            docWithMore.id = productDoc.id;
            tempFeaturedProducts.push(docWithMore)
        });

        this.setState({
            featuredProducts: tempFeaturedProducts,
            loadingFeaturedProducts: false,
        })
    }

    scrollToContent = () => this.myRef.current.scrollIntoView({
        behavior: "smooth",
    });
    
    render() {
        return (
            <>
                <Helmet>
                    <title>Home {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
                </Helmet>
                <ScrollableSplash>
                    <Grid fluid>
                        <Row center="xs" middle="xs">
                            <Col md={12} lg={6}>
                                <ClikClakContainer> 
                                    <Clik id="clik">
                                        Clik
                                    </Clik>
                                    <Clak id="clak">
                                        Clak
                                    </Clak>
                                </ClikClakContainer>
                               
                            </Col>
                            <Col sm={12} md={6}>
                                {/* ** I had to split these up because passing in "delay" as a prop to the styled component wasnt doing anything!! they call came in at the same time! */}
                                <SplashTaglineItem1>
                                    DISRUPTORS
                                </SplashTaglineItem1>
                                <SplashTaglineItem2>
                                    CREATORS
                                </SplashTaglineItem2>
                                <SplashTaglineItem3>
                                    PROBLEM SOLVERS
                                </SplashTaglineItem3>
                                <SplashTaglineItem4>
                                    PARTNERS
                                </SplashTaglineItem4>
                                <SplashTaglineItem5>
                                    <LLink to="/who-we-are">
                                        <Button
                                            size={SIZES.LG} 
                                            color={this.props.theme.colors.primary}
                                        >
                                            Learn more 
                                        </Button>
                                    </LLink>
                                    
                                </SplashTaglineItem5>
                            </Col>
                        </Row>
                    </Grid>
                    <FaChevronDown size={30} style={{color:"white", cursor:"pointer"}} onClick={() => document.getElementById("main").scrollIntoView({ block: "center",  behavior: "smooth" })} />
                </ScrollableSplash>
                <FullWidth ref={this.myRef} height={"auto"} color="#e0d2e6" style={{textAlign: "center", padding: "50px 0", margin:"0 auto"}} id={"main"}>
                    <SplashHeroContainer>
                        <H1 margin="0" color={"black"}>From Conception to Completion<br/><BoldHeading>We Make it happen.</BoldHeading></H1>
                        <Body color={"black"} size={SIZES.LG}>
                            Clik Clak is a leading supplier of promotional and private label products. <br/>We design, develop, manufacture, and deliver unrivaled product. We offer solutions. 
                        </Body>
                                
                        <LLink to={"/products"}>
                            <Button 
                                size={SIZES.MD} 
                                color={this.props.theme.colors.primary}
                            >
                                DIVE IN
                            </Button>
                        </LLink>
                    </SplashHeroContainer>
                    
                </FullWidth>
                
                <FeaturedGallery shown={!this.state.loadingFeaturedProducts && this.state.featuredProducts.length >= 3}>
                    {
                        this.state.featuredProducts && this.state.featuredProducts.map((featured, f) => {
                            if(f < 3){
                                return (
                                        <LLink key={f} to={featured.groupType === "single" ? `/products/${urlify(featured.name)}=page_0`  : `/products/query/tags=${urlify(featured.tag)}=page_0`}>
                                            <FeaturedCell>
                                                <img 
                                                    id="bg"
                                                    src={featured.photo[0]}
                                                    alt={`featured section background ${f}`}
                                                />
                                                <div>
                                                    <H4 margin="0">{featured.name}</H4>
                                                </div>
                                            </FeaturedCell>
                                        </LLink>
                                )
                            } else {
                                return null;
                            }
                        })
                    }
                </FeaturedGallery>
                <FeaturedGallery shown={!this.state.loadingFeaturedProducts && this.state.featuredProducts.length === 6}>
                    {
                        this.state.featuredProducts &&this.state.featuredProducts.map((featured, f) => {
                            if(f >= 3){
                                return (
                                        <LLink key={f} to={featured.groupType === "single" ? `/products/${urlify(featured.name)}`  : `/products/query/tags=${urlify(featured.tag)}`}>
                                            <FeaturedCell>
                                                <img 
                                                    id="bg"
                                                    src={featured.photo[0]}
                                                    alt={`featured section background ${f}`}
                                                />
                                                <div>
                                                    <H4 margin="0">{featured.name}</H4>
                                                </div>
                                            </FeaturedCell>
                                        </LLink>
                                )
                            } else {
                                return null;
                            }
                        })
                    }
                </FeaturedGallery>
                <FullWidth />
                <ALink target="_blank" rel="noopener" href="https://www.instagram.com/clikclakco/">
                    <InstagramTag>
                        <span>
                            <Img 
                                width="75px" 
                                margin="0 15px 0 0"
                                src={require("../../../assets/images/icons/insta.png")}
                                alt={"custom icon"}
                            />
                            @clikclak        
                        </span>
                    </InstagramTag>    
                </ALink>
            </>
        );
    }
}

export default withTheme(Home)