import styled, { css }  from 'styled-components';
import { Link } from "react-router-dom";
import { SIZES } from '../constants';

export const ClikClakLogoFont = css`
    font-family: ${props => "DIN Condensed" ?? "Arial, Helvetica, sans-serif"};
`;

export const HeadingFont = css`
    font-family: ${props => props.theme?.fonts?.heading ?? "Arial, Helvetica, sans-serif"};
`;

export const HeadingBoldFont = css`
    font-family: ${props => "New Hero Bold" ?? "Arial, Helvetica, sans-serif"};
`;

export const BoldHeading = styled.span`
    ${HeadingBoldFont};
`;

export const BodyFont = css`
    font-family: ${props => props.theme?.fonts?.body ?? "Arial, Helvetica, sans-serif"};
`;

export const XxsText = css`
    font-size: 10px;
    @media (max-width: 900px) {
        font-size: 8px;
    }
`;

export const XsText = css`
    font-size: 12px;
    @media (max-width: 900px) {
        font-size: 10px;
    }
`;

export const SmText = css`
    font-size: 16px;
    @media (max-width: 900px) {
        font-size: 14px;
    }
`;

export const MdText = css`
    font-size: 20px;
    @media (max-width: 900px) {
        font-size: 18px;
    }
`;

export const LgText = css`
    font-size: 24px;
    @media (max-width: 900px) {
        font-size: 20px;
    }
`;

export const XlText = css`
    font-size: 28px;
    @media (max-width: 900px) {
        font-size: 24px;
    }
`;

export const XxlText = css`
    font-size: 36px;
    @media (max-width: 900px) {
        font-size: 30px;
    }
`;

// Headings //
export const H1 = styled.h1`
    font-weight: normal;
    text-align: ${props => props.alignLeft ? "left"  : "center"};
    text-transform: ${props => props.normalCase ? "none" : "uppercase"};
    position: relative;
    z-index: 1;
    font-size: ${props => props.size ? props.size : "50px"};
    letter-spacing: 2px;
    ${HeadingFont};
    color: ${props => props.color ? props.color : (props.theme?.colors?.font?.heading ?? "black")};
    margin: ${props => props.margin ? props.margin : "10px 0"};
    display: ${props => props.inline ? "inline" : "block"};
    float: ${props => props.floatLeft ? "left"  : "center"};
    @media (max-width: 900px) {
        font-size: 42px;
    }
`;

export const H2 = styled.h2`
    font-weight: normal;
    text-align: ${props => props.alignLeft ? "left"  : "center"};
    text-transform: ${props => props.normalCase ? "none" : "uppercase"};
    letter-spacing: 2px;
    font-size: 40px;
    ${HeadingFont}
    color: ${props => props.color ? props.color : (props.theme?.colors?.font?.heading ?? "black")};
    margin: ${props => props.margin ? props.margin : "10px 0"};
    display: ${props => props.inline ? "inline" : "block"};
    float: ${props => props.floatLeft ? "left"  : "center"};
    @media (max-width: 900px) {
        font-size: 35px;
        /* margin-bottom: 15px; */
    }
`;

export const H3 = styled.h3`
    font-weight: normal;
    text-align: ${props => props.alignLeft ? "left"  : "center"};
    text-transform: ${props => props.normalCase ? "none" : "uppercase"};
    letter-spacing: 1px;
    font-size: 32px;
    ${HeadingFont};
    color: ${props => props.color ? props.color : (props.theme?.colors?.font?.heading ?? "black")};
    margin: ${props => props.margin ? props.margin : "10px 0"};
    display: ${props => props.inline ? "inline" : "block"};
    float: ${props => props.floatLeft ? "left"  : "center"};
    @media (max-width: 900px) {
        font-size: 25px;
    }
`;

export const H4 = styled.h4`
    font-weight: normal;
    text-align: ${props => props.alignLeft ? "left"  : "center"};
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 24px;
    ${HeadingFont};
    color: ${props => props.color ? props.color : (props.theme?.colors?.font?.heading ?? "black")};
    margin: ${props => props.margin ? props.margin : "10px 0"};
    display: ${props => props.inline ? "inline" : "block"};
    @media (max-width: 900px) {
        font-size: 20px;
    }
`;

export const H5 = styled.h5`
    font-weight: normal;
    text-align: ${props => props.alignLeft ? "left"  : "center"};;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 24px;
    ${HeadingFont};
    color: ${props => props.color ? props.color : (props.theme?.colors?.font?.heading ?? "black")};
    margin: ${props => props.margin ? props.margin : "10px 0"};
    display: ${props => props.inline ? "inline" : "block"};
    @media (max-width: 900px) {
        font-size: 18px;
    }
`;

// Paragraph Body
export const Body = styled.p`
    margin: ${props => props.margin ? props.margin : "1em 0"};
    display: ${props => props.display ? props.display : "block"};
    visibility: ${props => props.hidden ? "hidden" : "visible"};
    color: ${props => props.color ? props.color : (props.theme?.colors?.font?.body ?? "black")};
    text-align: ${props => props.center ? "center" : ""};
    font-weight: ${props => props.bold ? 900 : 0};
    ${BodyFont};
    
    white-space: ${props => props.preWrap ? "pre-wrap" : "normal"};

    ${(props) => (props.size === SIZES.XXS) && `
        ${XxsText}
    `};

    ${(props) => (props.size === SIZES.XS) && `
        ${XsText}
    `};

    ${(props) => (props.size === SIZES.SM) && `
        ${SmText}
    `};

    ${(props) => (props.size === SIZES.MD || !props.size) && `
        ${MdText}
    `};

    ${(props) => (props.size === SIZES.LG) && `
        ${LgText}
    `};

    ${(props) => (props.size === SIZES.XL) && `
        ${XlText}
    `};

    ${(props) => (props.hovercolor) && `
        &:hover {
            color: ${props.hovercolor};
            cursor: pointer;
        }
    `};
    
`;


// Links
export const ALink = styled.a`
    color: ${props => props.color ? props.color : (props.theme?.colors?.font?.link ?? "navy")};
    cursor: pointer;
    ${BodyFont}
    text-decoration: none;
    transition: color 0.15s linear;
    margin: ${props => props.margin ? props.margin : "0"};
    &:hover {
        color: ${props => (props.theme?.colors?.primary || "gold")};
        text-decoration: none;
    }
`;


export const LLink = styled(Link)`
    color: ${props => props.color ? props.color : (props.theme?.colors?.font?.link || "navy")};
    font-style: ${props => props.italic ? "italic" : "normal"};
    ${(props) => (props.size === SIZES.SM) && `
        ${SmText}
    `};

    ${(props) => (props.size === SIZES.MD) && `
        ${MdText}
    `};

    ${(props) => (props.size === SIZES.LG) && `
        ${LgText}
    `};

    ${(props) => (props.size === SIZES.XL) && `
        ${XlText}
    `};

    ${(props) => (props.size === SIZES.XXL) && `
        ${XxlText}
    `};

    ${(props) => (props.hovercolor) && `
        &:hover {
            color: ${props.hovercolor} !important;
            cursor: pointer;
        }
    `};

    cursor: pointer;
    ${BodyFont}
    margin: ${props => props.margin ? props.margin : "0"};
    text-decoration: none;
    transition: color 0.15s linear;
    &:hover {
        color: ${props => (props.theme?.colors?.yellow ?? "gold")};
        text-decoration: none;
    }
`;

// export const HLink = styled(HashLink)`
//     color: ${props => props.color ? props.color : (props.theme?.colors?.font?.link ?? 'navy')};
//     cursor: pointer;
//     ${BodyFont}
//     text-decoration: none;
//     transition: color 0.15s linear;
//     &:hover {
//         color: ${props => (props.theme?.colors?.yellow ?? 'gold')};
//         text-decoration: none;
//     }
// `;

export const SLink = styled.span`
    color: ${props => props.color ? props.color : (props.theme?.colors?.font?.link ?? "navy")};
    cursor: pointer;
    ${BodyFont}
    text-decoration: none;
    transition: color 0.15s linear;
    margin: ${props => props.margin ? props.margin : "0"};
    &:hover {
        color: ${props => (props.theme?.colors?.yellow ?? "gold")};
        text-decoration: none;
    }
`;

export const Anchor = styled.a`
    font-size: 0;
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
`;

//  Misc
export const Label = styled.label`
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 2px;
    display: inline-block;
    ${BodyFont};
    color: ${props => (props.theme?.colors?.font?.body ?? "black")};
`;

export const ErrorText = styled.div`
    color: ${props => (props.theme?.colors?.red ?? "firebrick")};
    margin: ${props => props.margin ? props.margin : "0px"};
    ${BodyFont}
    font-weight: 900;
`;

export const Ol = styled.ol`
    ${BodyFont}
    color: ${props => (props.theme?.colors?.font?.body ?? "black")};
`;

export const Ul = styled.ul`
    ${BodyFont};

    ${(props) => (props.size === SIZES.SM) && `
        ${SmText}
    `};

    ${(props) => (props.size === SIZES.MD || !props.size) && `
        ${MdText}
    `};

    ${(props) => (props.size === SIZES.LG) && `
        ${LgText}
    `};

    ${(props) => (props.size === SIZES.XL) && `
        ${XlText}
    `};

    color: ${props => (props.theme?.colors?.font?.body ?? "black")};

    li {
        
        margin: ${props => props.margin ? props.margin : "0px"};
    }
`;

export const Li = styled.li`
    ${BodyFont}
    color: ${props => (props.theme?.colors?.font?.body ?? "black")};
`;

export const OurStorySection = styled.div`
    padding: 0 50px;
    @media (max-width: 900px) {
        padding: 0 5px;
    }
`;

export const FaqH3 = styled.h3`
    font-weight: normal;
    text-align: center;
    font-size: 32px;
    ${HeadingFont};
    color: ${props => props.color ? props.color : (props.theme?.colors?.font?.heading ?? "black")};
    margin: 0;
    @media (max-width: 900px) {
        font-size: 20px;
    }
`;