import { css, keyframes } from "styled-components"


export const bounce = keyframes`
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
   40% {transform: translateY(-10px);} 
   60% {transform: translateY(-5px);} 
`;

export const wiggle = keyframes`
    0%, 7% {
        transform: rotateZ(0);
    }
    15% {
        transform: rotateZ(-15deg);
    }
    20% {
        transform: rotateZ(10deg);
    }
    25% {
        transform: rotateZ(-10deg);
    }
    30% {
        transform: rotateZ(6deg);
    }
    35% {
        transform: rotateZ(-4deg);
    }
    40%, 100% {
        transform: rotateZ(0);
    }
`

export const spin = keyframes`
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
`

export const zoomIn = keyframes`
    0% {
        font-size: 1px
    }
    100% {
        font-size: 19vw;
    }
`;

export const slideFromRight = keyframes`
    0% {
        transform:translateX(200%);
        opacity: 1;
    }
    100% {
        transform:translateX(0);
        opacity: 1;
    }
`;

export const oldClick = keyframes`
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(100px);
    }

    49% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(0px);
    }
`;

export const click = keyframes`
    0% {
        opacity: 0
    }
    100% {
        font-size: 1
    }
`;

export const clack = keyframes`
    0% {
        font-size: 1px
    }
    100% {
        font-size: 22vw;
    }
`;

export const clikAnimation = css`
  animation-name: ${click};
  animation-duration: 2s; 
  animation-timing-function: ease-out; 
  animation-delay: 0s;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running; 
`;

export const clakAnimation = css`
  animation-name: ${click};
  animation-duration: 2s;
  animation-timing-function: ease-out; 
  animation-delay: 1s;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running; 
`;

export const slideFromRightAnimation = css`
  animation-name: ${slideFromRight};
  animation-duration: 2s; 
  animation-timing-function: linear; 
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running; 
`;

export const bounceArrowAnimation = css`
  animation-name: ${bounce};
  animation-duration: 1.5s; 
  animation-timing-function: linear; 
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-play-state: running; 
`;