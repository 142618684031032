import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { BiError } from 'react-icons/bi';
import { FaChevronLeft } from 'react-icons/fa';

import { Button } from '../../../utils/styles/buttons';
import { Wrapper } from '../../../utils/styles/misc';
import { Body, H1, LLink } from '../../../utils/styles/text';

export default class Product404 extends Component {
  render() {
    return (
        <Wrapper>
            <Helmet>
                <title>Product 404 Error {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
            </Helmet>
            <LLink to="/products">
                <Button type="button">
                    <FaChevronLeft />
                    &nbsp; Return to products
                </Button>
            </LLink>
            <H1><BiError /> Product Not Found</H1>
            <Body>Sorry, but it looks like the product you were looking for was not found in our directory. Please check the address and try again!</Body>
        </Wrapper>
    );
  }
}
