import { rgba, transparentize } from 'polished';
import { BiSearch } from 'react-icons/bi';
import { CgClose } from 'react-icons/cg';
import { NavLink } from 'react-router-dom';
import styled  from 'styled-components';
import { SCHEMES } from '../constants';
import { BodyFont, HeadingFont } from './text';

const activeClassName = "active";

export const HeaderContainer = styled.span`

`;

export const TopNav = styled.header`
    background-color: white;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    border-bottom: solid 5px ${props => props.theme.colors.primary};
    transition: all 0.3s linear;
    
    @media (max-width: 900px) {
        flex-wrap: wrap;
        height: 75px;
        ${(props) => (!props.isSearchHidden) && `
            height: 125px;
        `};
    }
`;

// Branding
export const BrandContainer = styled.div`
    width: 100%;
    
    @media (max-width: 900px) {
        flex: 1 0 33%;
    }
`;

export const BrandLink = styled(NavLink)`
        display: inline-flex;
        align-items: center;
        text-decoration: none;
`;

export const NavLogo = styled.img`
    width: 100%;
    height: auto;
    max-width: ${props => props.width ? `${props.width}px` : "150px"}; 
    margin: 0 20px;
    
    @media (max-width: 900px) {
        max-width: ${props => props.width ? `${props.width * .6}px` : "100px"}; 
        margin: 10px;
    }
`;

export const NavTitle = styled.span`
    ${HeadingFont};
    font-size: 18px; 
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    transition: color 0.15s linear;

    &:hover {
        text-decoration: none;
        color: ${props => props.theme.colors.secondary};
    }
    
    @media (max-width: 1400px) {
        display: none;
    }
`;

// Nav Menu
export const NavMenuContainer = styled.nav`
    display: none;
/*     
    @media (max-width: 900px) {
        display: none;
    } */
`;

export const NavLinks = styled.span`
    ${BodyFont}
    float: right;
    padding-right: 25px;
`;

export const NavLLink = styled(NavLink)`
    transition: color 0.15s linear, border-bottom 0.15s linear;
    border-bottom: 2px solid transparent;
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    font-size: 20px;
    margin: 0 18px;
    padding: 5px 0;

    &.${activeClassName} {
        border-bottom: 2px solid ${props => props.theme.colors.secondary};
    }

    &:hover {
        border-bottom: 2px solid ${props => props.theme.colors.secondary};
        font-weight: 700;
        color: ${props => props.theme.colors.secondary};
    };

    @media (max-width: 900px) {
        font-size: 14px;
    }

`;

// Burger Menu 
export const BgOverlay = styled.div`
    display: ${({ open }) => open ? 'inline' : 'none'};
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${props => rgba(props.theme.colors.grey, 0.7)};
    transition: opacity 0.8s;
    z-index: 7;
`;

export const BurgerNavContainer = styled.span`
    display: inline;
    /* position: fixed; */
    @media (max-width: 900px) {
        flex: 1 0 33%;
    }
`;

export const Burger = styled.button`
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2.5rem;
    height: 2.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-right: 25px;
    &:focus {
        outline: none;
    }

    div {
        position: absolute;
        z-index: 9;
        width: 2.5rem;
        height: .25rem;
        border-radius: 0px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        background-color: ${(props) => props.open ? "white" : (props.theme.value !== SCHEMES.DARK ? props.theme.colors.primary : "white")};
        
        :first-child {
            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }

        :nth-child(2) {
            opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
    @media (max-width: 900px) {
        float: right;
    }
        
`;

export const BurgerNavLeft = styled.nav`
    display: flex;
    flex-direction: column;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    height: 100vh;
    width: 70%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 7;
    transition: transform 0.3s ease-in-out;

    .brand-section {
        display: block;
        background: white;
        padding: 25px 10px;
        img {
            max-width: 350px;
        }
        p {
            font-weight: 900;
            color: black;
            font-size: 1.8rem;
            margin: 0;
            text-transform: uppercase;
        }
    }
    

    .bg-section {
        z-index: 8;
        height: 100%;
        background-color: ${props => props.color ? props.color : props.theme.colors.primary};
        background-image: url(${props => props.bgUrl});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @media (max-width: 900px), (max-height: 400px) {
        width: 100%;

        .brand-section {
            display: none;
        }

        .bg-section {
            display: none;
        }
    }
`;

export const BurgerNavRight = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${props => props.color ? props.color : props.theme.colors.background};
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    height: 100vh;
    text-align: center;
    width: 30%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 8;
    transition: transform 0.3s ease-in-out;

    @media (max-width: 900px) {
        width: 100%;

    }
`;

export const BurgerNavLink = styled(NavLink)`
    ${BodyFont};
    font-weight: normal;
    font-size: 2vw;
    text-transform: uppercase;
    padding: 3.2vh;
    letter-spacing: 0.3rem;
    color: white;
    text-decoration: none;
    transition: all 0.3s linear;
    span {
        position: relative;
        padding: 0 .1em;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: bottom;
        box-decoration-break: clone;
        z-index: 5;
    }

    @media (max-width: 900px) {
        font-size: 1.8rem;
        padding: 1rem 0;
        text-align: center;
    }

    &:hover {
        span {
            background-image: linear-gradient(to right,${props => transparentize(0.01, props.theme.colors.tertiary)} 0%,${props => transparentize(0.01, props.theme.colors.tertiary)} 100%) !important;
        }
    }

    &.${activeClassName} {
        /* background-color: ${props => props.theme.colors.primary}; */
        
        span {
            background-image: linear-gradient(to right, ${props => transparentize(0.01, props.theme.colors.tertiary)} 0%,${props => transparentize(0.01,props.theme.colors.tertiary)} 100%);
        }
    }
`;

export const BurgerSLink = styled.span`
    ${BodyFont}
    font-size: 3rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: white;
    text-decoration: none;
    transition: all 0.3s linear;

    /* display: ${props => props.hidden ? "none" : "inline"}; */
    @media (max-width: 900px) {
        font-size: 2.4rem;
        text-align: center;
    }

    &:hover {
        background-color: ${props => props.theme.colors.primary};
    }

`;


// Search
export const HSearchContainer = styled.div`
    transition: all 0.3s linear;
    width: 500px;
    max-width: 80%;
    position: relative;
    display: inline-flex;
    justify-content: center;
    float: right;
    margin-top: 5px;
    svg {
        position: absolute;
        transition: all 0.1s linear;
        top: 13px;
        color: ${props => props?.theme?.colors?.primary};
        right: 15px;
        &:hover {
            top: 18px;
            color: ${props => props?.theme?.colors?.green ?? "green"};
            cursor: pointer;
        }
    }

    input {
        padding-right: 40px;
    }

    &:hover {
        max-width: 100%;
    }
    
    @media (max-width: 900px) {
        transition: width 0.1s linear;
        max-width: 100%;
        width: 80vw;
        margin-top: 0px;
    }
`;


export const HeaderSearchDiv1 = styled.div`
    position: relative;
    z-index: 10;
    margin: ${props => props.margin ? props.margin : "0"};
    display: block;
    @media (max-width: 900px) {
        display: none;
    }

`;

export const HeaderSearchDiv2 = styled.div`
    margin: 0;
    display: none;
    position: relative;
    z-index: 10;
    @media (max-width: 900px) {
        flex-basis: 100%; 
        
        display: ${props => props.hidden ? "none" : "flex"};
        justify-content: center;
        align-items: center;
    }
`;

export const SearchIcon = styled(BiSearch)`
    transition: all 0.3s linear;
    cursor: pointer;
    margin-right: 10px;
    display: none !important;
    /* color: */
    @media (max-width: 900px) {
        ${(props) => (props.hidden) && `
            display: none !important;
        `};
        ${(props) => (!props.hidden) && `
            display: inline !important;
        `};
    }
`;

export const CloseIcon = styled(CgClose)`
    transition: all 0.3s linear;
    cursor: pointer;
    margin-right: 10px;
    display: none !important;
    
    ${(props) => (props.ispathhome) && `
        color: white !important;
    `};

    ${(props) => (!props.ispathhome) && `
        color: ${props.theme.value !== SCHEMES.DARK ? props.theme.colors.primary : "white"}; 
    `};


    @media (max-width: 900px) {
        ${(props) => (props.hidden) && `
            display: none !important;
        `};
        ${(props) => (!props.hidden) && `
            display: inline !important;
        `};
    }
`;