import React, { Component } from 'react'
import { collection, query, orderBy, startAfter, limit, getDocs, onSnapshot, doc, endAt, limitToLast, deleteDoc, updateDoc, increment } from "firebase/firestore";  
import { FaChevronLeft, FaChevronRight,  } from 'react-icons/fa';
import { CgTrash } from 'react-icons/cg';
import { withTheme } from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { toast } from 'react-toastify';

import { Hr, Img, OverflowXAuto, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '../../../../utils/styles/misc'
import { Body, H1, H2, LLink } from '../../../../utils/styles/text'
import { firestore } from '../../../../Fire';
import { Button } from '../../../../utils/styles/buttons';
import { readTimestamp, urlify } from '../../../../utils/misc';
import { BTYPES, SIZES } from '../../../../utils/constants.js';
import { withRouter } from '../../../../utils/hocs';
import ConfirmAlert from '../../../misc/ConfirmAlert';
import { confirmAlert } from 'react-confirm-alert';
import AddFeaturedProducts from './AddFeaturedProducts';
import { BsEye } from 'react-icons/bs';


class ManageFeaturedProducts extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            featuredProducts: "",
            currentPage: 0,
            beginCursor: "",
            finalCursor: "",
            loading: true,
            loadingCounts: true,
            featuredProductsCount: 0,
            featuredProductsPerPage: 10,
            shownFeaturedProducts: [],
            showAddFeatured: false,
        }
    }
    
    componentDidMount = async () =>{
        this.unsubCounts = onSnapshot(doc(firestore, "site", "counts"), (countsDoc) => {
            if(countsDoc.exists()){
                let countsData = countsDoc.data();
                this.setState({
                    featuredProductsCount: countsData.featuredProducts || 0,
                    loadingCounts: false
                });
            } else {
                console.log("No custom site set, can't properly count featuredProducts.")
                this.setState({
                    loadingCounts: false
                });
            }
        });

        // Get first page of featuredProducts
        const currentPageQuery = query(
            collection(firestore, "featuredProducts"), 
            orderBy("position", "asc"), 
            limit(this.state.featuredProductsPerPage)
        );
        const pageDocSnaps = await getDocs(currentPageQuery);
        // Get the last visible document cursor so we can reference it for the next page
        const finalCursor = pageDocSnaps.docs[ pageDocSnaps.docs.length - 1 ];

        // Get content from each doc on this page 
        let tempFeaturedProducts = [];
        let shownFeaturedProducts = []
        pageDocSnaps.forEach((doc) => {
            const docWithMore = Object.assign({}, doc.data());
            docWithMore.id = doc.id;
            tempFeaturedProducts.push(docWithMore);
            shownFeaturedProducts.push(false)
        });

        this.setState({
            featuredProducts: tempFeaturedProducts,
            finalCursor: finalCursor,
            currentPage: 1,
            loadingFeaturedProducts: false,
            shownFeaturedProducts: shownFeaturedProducts
        })
    }

    componentWillUnmount(){
        if(this.unsubCounts){
            this.unsubCounts();
        }
    }

    toggleAddFeatured = (newStatus) => {
        this.setState({
            showAddFeatured: newStatus
        })
    }

    deleteFeatured  = (featuredId) => {
        deleteDoc(doc(firestore, "featuredProducts", featuredId)).then(() => {
            console.log("Successfully deleted featuredProducts!")
            updateDoc(doc(firestore, "site", "counts"), {
                featuredProducts: increment(-1)
            }).then(() => {
                console.log(`Incremented featuredProducts count`)
                toast.success(`featuredProducts deleted successfully!`);
            }).catch(error => {
                console.error(`Error incrementing featuredProducts count: ${error}`)
            })
        }).catch((error) => {
            console.error("Error deleting featuredProducts: " + error)
        });
    }
    
    getPrevPage = async () => {
        if(this.state.currentPage !== 1){
            this.setState({
                loadingFeaturedProducts: true
            })
            // Construct a new query starting at this document,
            const currentPageQuery = query(
                collection(firestore, "featuredProducts"), 
                orderBy("position", "asc"),
                endAt(this.state.beginCursor),
                limitToLast(this.state.featuredProductsPerPage) // Adding this seemed to solve the going abck issue, but now everything is jumbled when going back
            );
            const pageDocSnaps = await getDocs(currentPageQuery);
            const beginCursor = pageDocSnaps.docs[ 0 ];
            const finalCursor = pageDocSnaps.docs[ pageDocSnaps.docs.length - 1 ];
            const prevPage = this.state.currentPage - 1;

            // Set data in docs to state
            let tempFeaturedProducts = [];
            let shownFeaturedProducts = []
            pageDocSnaps.forEach((doc) => {
                const docWithMore = Object.assign({}, doc.data());
                docWithMore.id = doc.id;
                tempFeaturedProducts.push(docWithMore);
                shownFeaturedProducts.push(false)
            });

            this.setState({
                featuredProducts: tempFeaturedProducts,
                beginCursor: beginCursor,
                finalCursor: finalCursor,
                currentPage: prevPage,
                loadingFeaturedProducts: false,
            })
        }
    }

    getNextPage = async () => {
        if(this.state.currentPage !== Math.ceil(this.state.featuredProductsCount/this.state.featuredProductsPerPage)){
            this.setState({
                loadingFeaturedProducts: true
            })
            // Construct a new query starting at this document,
            const currentPageQuery = query(
                collection(firestore, "featuredProducts"), 
                orderBy("position", "asc"),
                startAfter(this.state.finalCursor),
                limit(this.state.featuredProductsPerPage)
            );
            const pageDocSnaps = await getDocs(currentPageQuery);
            const beginCursor = pageDocSnaps.docs[ 0 ];
            const finalCursor = pageDocSnaps.docs[ pageDocSnaps.docs.length - 1 ];
            const nextPage = this.state.currentPage + 1;

            // Set data in docs to state
            let tempFeaturedProducts = [];
            let shownFeaturedProducts = []
            pageDocSnaps.forEach((doc) => {
                const docWithMore = Object.assign({}, doc.data());
                docWithMore.id = doc.id;
                tempFeaturedProducts.push(docWithMore);
                shownFeaturedProducts.push(false)
            });

            this.setState({
                featuredProducts: tempFeaturedProducts,
                beginCursor: beginCursor,
                finalCursor: finalCursor,
                currentPage: nextPage,
                loadingFeaturedProducts: false,
            })
        }
    }

    render() {
        if(this.state.loadingFeaturedProducts && this.state.loadingCounts){
            return (
                <>
                    <H2>Loading... <Spinner /> </H2> 
                </>
            )
        } else {
            return (
                <>
                    <Helmet>
                        <title>Featured Products {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
                    </Helmet>
                    <Button size={SIZES.SM} onClick={() => this.props.navigate(-1)}>
                        <FaChevronLeft  />
                        &nbsp; Go back
                    </Button>
                    <H1>Featured Products: {this.state.featuredProductsCount}</H1>
                    {this.state.featuredProductsCount === 0 && (
                        <Body color={this.props.theme.colors.red} bold size={SIZES.LG}>No featured products yet!</Body>
                    )}
                    {this.state.featuredProductsCount !== 0 && (
                        <>
                        <OverflowXAuto>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>Timestamp</Th>
                                        <Th>Name</Th>
                                        <Th>Position</Th>
                                        <Th>Group Type</Th>
                                        <Th>Photo</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    { this.state.featuredProducts.length !== 0 && this.state.featuredProducts.map((featured, i) => {
                                        return (
                                            <Tr key={i}>
                                                <Td>
                                                    {readTimestamp(featured.timestamp).date} @ {readTimestamp(featured.timestamp).time}
                                                </Td>
                                                <Td>
                                                    {featured.name}
                                                </Td>
                                                <Td>
                                                    {featured.position}
                                                </Td>
                                                <Td>
                                                    {featured.groupType}
                                                </Td>
                                                <Td>
                                                    <Img
                                                        src={featured.photo[0]}
                                                        alt={"thumb of featured"}
                                                    />
                                                </Td>
                                                <Td>
                                                    <Button type="button" size={SIZES.SM}>
                                                        <LLink to={featured.groupType === "single" ? `/products/${urlify(featured.name)}`  : `/products/query/tags=${urlify(featured.tag)}=page_0`}>
                                                            view <BsEye size={18} />
                                                        </LLink>
                                                    </Button>
                                                    
                                                    <Button
                                                        type="button"
                                                        btype={BTYPES.INVERTED} 
                                                        size={SIZES.SM}
                                                        color={this.props.theme.colors.red}
                                                        onClick={() =>         
                                                            confirmAlert({
                                                                customUI: ({ onClose }) => {
                                                                    return (
                                                                        <ConfirmAlert
                                                                            theme={this.props.theme}
                                                                            onClose={onClose} 
                                                                            headingText={`Delete Featured Product`}
                                                                            body={`Are you sure you want to delete <${featured.name}>? This operation is not reversible.`}
                                                                            yesFunc={() => this.deleteFeatured(featured.id)} 
                                                                            yesText={`Yes`} 
                                                                            noFunc={function () {}} 
                                                                            noText={`No`}   
                                                                        />
                                                                    );
                                                                }
                                                            })}        
                                                    >
                                                        <CgTrash size={18} />
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </Table>
                        </OverflowXAuto>
                        <Hr/>
                        <Grid fluid>
                            <Row center="xs" middle="xs">
                                <Col xs={12} sm={4}>
                                    {this.state.currentPage !== 1 && (
                                        <Button onClick={() => this.getPrevPage()}>
                                            <FaChevronLeft /> Previous page    
                                        </Button>
                                    )}
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Body size={SIZES.LG}>Page {this.state.currentPage} of {Math.ceil(this.state.featuredProductsCount/this.state.featuredProductsPerPage)}</Body>
                                </Col>
                                <Col xs={12} sm={4}>
                                    {this.state.currentPage !== Math.ceil(this.state.featuredProductsCount/this.state.featuredProductsPerPage) && (
                                        <Button onClick={() => this.getNextPage()}>
                                            Next page <FaChevronRight /> 
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Grid>
                        </>
                    )}
                    {!this.state.showAddFeatured && (
                        <Button onClick={() => this.setState({showAddFeatured: true})}>
                            Add a new featured products
                        </Button>
                    )}
                    {this.state.showAddFeatured && (
                        <Button 
                            btype={BTYPES.INVERTED}
                            color={this.props.theme.colors.red} 
                            onClick={() => this.toggleAddFeatured(false)}
                        >
                            Never mind, don't add featured
                        </Button>
                    )}
                    {this.state.showAddFeatured && (
                        <AddFeaturedProducts site={this.props.site} user={this.props.user} toggleAddFeatured={this.toggleAddFeatured} />
                    )}
                </>
            ) 
        }
        
        
    }
}

export default withTheme(withRouter(ManageFeaturedProducts))