import React from 'react'
import { Masonry } from "masonic";

import { Centered, Img, Wrapper } from '../../../utils/styles/misc'
import { Button } from '../../../utils/styles/buttons'
import { Body, H1, LLink } from '../../../utils/styles/text'
import { SIZES } from '../../../utils/constants';

const MasonryCard = ({ index, data: { src }, width }) => (
    <Img
        width={"100%"}
        src={src}
    />
);

export default function CustomProjects() {
    return (
        <Wrapper>
            <H1>Custom Projects</H1>
            <Masonry 
                items={customProjects} 
                render={MasonryCard} 
                columnGutter={4}
                rowGutter={4}
                columnWidth={400}
                overscanBy={2}
            />
            <Centered>
                <Body>Contact us today to start your custom project!</Body>
                <LLink to="/work-with-us"><Button size={SIZES.SM}>Contact us</Button></LLink>
            </Centered>
        </Wrapper>
    )
}

const customProjects = [
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FAPRON.jpg?alt=media&token=1e21de13-9dd5-4e85-bfdc-a4d77fb301b6",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FARTIZIA.jpg?alt=media&token=87d751db-840b-432f-83d0-f552664468b6",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FCHIPOTLE.png?alt=media&token=3e2975c4-3f4b-44f3-b3fe-8c8db6ab7d84",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FArizona%20Cooler.jpg?alt=media&token=965e66ee-fc12-4ce3-82b6-86f9fb7ce1ad",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FBenefit%20Set.jpg?alt=media&token=5c4119eb-07b6-4c6e-8e0d-58b4323426e5",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FBillie%20set.jpg?alt=media&token=d89bca87-e873-46b8-9e85-6cbedf2a56a6",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FBloomies%20set.jpg?alt=media&token=03240c52-eddd-4e9a-aea4-83b36d8595a4",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FCANARY.jpg?alt=media&token=c3b48535-a95e-4d21-a149-d372339034e5",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FCHAIRS.jpg?alt=media&token=a6cb3fc9-d394-4594-b1d4-e8015b890865",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FCOOKIE%20JARS_.png?alt=media&token=86191a49-7b91-47ee-a162-59ed27397060",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FColorHealth%20set.jpg?alt=media&token=2df75266-3f5f-4eba-ab27-c1fac0591123",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FDOVE.jpg?alt=media&token=820c7bfe-b061-4f18-8abe-3740da464a3d",
    },
    // {
    //     src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FFANNY%20PACKS.jpg?alt=media&token=c07c0e93-9142-4c3e-864f-f60d5a9d7105",
    // },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FGROUP.png?alt=media&token=d9faeb6b-ccda-4f21-9355-d31fb49f6f4a",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FHAT.png?alt=media&token=eeb7bc30-20eb-4ddc-8885-901e0cf0ae11",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FIMG_4082.jpg?alt=media&token=c9972313-c38c-48a6-ac72-28b90bc29bca",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FIMG_4327.jpg?alt=media&token=32c61e5b-d4e8-4078-a939-e1f82b5116d9",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FIMG_4678.jpg?alt=media&token=582e50cb-b576-4c08-b27e-59286738bca8",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FIMG_4685.jpg?alt=media&token=cd016221-5034-453c-a074-22d538750df0",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FIMG_4690.jpg?alt=media&token=e98661c4-4045-48f0-bdba-13d53e0cf703",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FIMG_4982.jpg?alt=media&token=7f4a6a48-20ec-48fd-bc33-d577759a29c1",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FIMG_5704a%20copy.jpg?alt=media&token=af3751f4-2399-4005-b3f3-2a36d5f25c50",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FJOHNNIEO.jpg?alt=media&token=9ba87bb7-f951-417d-94d0-0a17d60d62e8",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FJPG.jpg?alt=media&token=5672a00c-ee88-458c-a4f3-0ca4a09a51fb",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FKITH.jpg?alt=media&token=807db13e-cf78-47fc-be0c-fdfc5c350526",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FLBAP_LHAP%20Set.jpg?alt=media&token=5cd45a14-c842-4e40-9884-202739de0527",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FLUGGAGE%20TAG_2.png?alt=media&token=0c131674-61a6-412d-9de2-c6e907d6f39d",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FMERC1.jpg?alt=media&token=5e9d72ef-0539-46a6-9a83-5d0593d70294",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FMERC2.jpg?alt=media&token=699a09f8-6933-4e10-a5a0-436391bc7430",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FMERC3REV.jpg?alt=media&token=b3191c26-50cc-482e-b1cd-693da0805fab",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FMERC4.jpg?alt=media&token=ac5eb06a-300a-409e-9c4f-41a7dd5d262c",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FMERC5.jpg?alt=media&token=08480485-42a6-4976-be8b-c307c75de47e",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FMJ%20glasses.jpg?alt=media&token=b05ce510-c287-4a10-8748-d5b02ea5f24d",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FMJ2.jpg?alt=media&token=6f3bd5e5-3023-4586-929e-98f34e4c6665",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FNJLottery.png?alt=media&token=dc8f4b3d-581f-4803-884e-913dd8e99d5e",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FPEPSI%20CLOCK.jpg?alt=media&token=39f64808-12d3-487e-9dc1-d1a5dd99065b",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FPEPSI.jpg?alt=media&token=fc73c074-3415-486c-8625-c280b2252b22",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FPOT%20HOLDER.jpg?alt=media&token=ed46f0a6-6e42-427d-9a87-704aa34bb7e9",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FPaco%20rabanne%20Set.jpg?alt=media&token=392ee341-1c70-4533-910b-e17134e7872a",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FRodan%2BFields%20bag.jpg?alt=media&token=57fd648a-a73a-4e80-962e-54fd1bd1f9bb",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FSEPHORA.jpg?alt=media&token=26f2f4bf-adfc-47d9-b637-a2f971e1b1b6",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FSPONGEBOB%20HATS.jpg?alt=media&token=5a6baaf4-e209-407d-8ab6-b5664b267655",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FSPOTIFY%20MAILERS.jpg?alt=media&token=51e05569-3420-493d-8bda-8189523fc78b",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FSTUSSY%20DOMINOS.jpg?alt=media&token=5ff85616-56a5-487a-ae5a-524d61a0ca0e",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FTRUIST.jpg?alt=media&token=a4ed2a6d-5818-469f-8d80-097d31812da9",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FTWICE.jpg?alt=media&token=8f32b549-f52e-4c60-ad22-451b025ec03d",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FTWITCH%20CUSTOM%20BOX.jpg?alt=media&token=ac333419-cc05-4883-8cdf-46fd84acef65",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FThe%20Orange%20Therory.jpg?alt=media&token=45ceca42-95e1-4946-9f96-7f251b0e8974",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2FWHISTLE%20MAILER.jpg?alt=media&token=b5d1299c-6783-4acd-a361-1f7c55ed9c56",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2Fa24%20puzzle.jpg?alt=media&token=25860693-7ebb-49dc-926c-611946cc792e", 
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2Fdominos.jpg?alt=media&token=f06b44aa-8e9c-492f-8847-24150fdf7dfb",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2Flunch%20box%20set.png?alt=media&token=2d95ec25-829e-4d25-9104-e082b89e351c",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2Fpillowblanketrev.jpg?alt=media&token=30a10810-e48a-4275-b1db-e49ecf867f70",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2Fskull%20candle.jpg?alt=media&token=98ecebe4-13f7-4f05-a0a2-d7a7b8c875c6",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2Fstackball.jpg?alt=media&token=b6d3937d-2c30-4fb0-a1a2-430318abe178",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2Ft-mobile.png?alt=media&token=279fd051-728f-40f7-96e1-53526f207bb0",
    },
    {
        src: "https://firebasestorage.googleapis.com/v0/b/clikclak-com.appspot.com/o/public%2Fcustom%2Ftravelcornhole.jpg?alt=media&token=ad406c45-5d24-41f7-89bd-3ee880a7407e",
    },
    
]
