import React, { Component } from 'react'
import { doc, updateDoc, increment, getDoc, addDoc, collection } from "firebase/firestore";  
import { withTheme } from 'styled-components';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Hr } from '../../../../utils/styles/misc'
import { Body, H2, Label } from '../../../../utils/styles/text'
import { firestore } from '../../../../Fire';
import { Button } from '../../../../utils/styles/buttons';
import { onKeyDown, urlify } from '../../../../utils/misc';
import { SIZES } from '../../../../utils/constants.js';
import { withRouter } from '../../../../utils/hocs';
import { addFeaturedSchema } from '../../../../utils/formSchemas';
import { FField, RField } from '../../../../utils/styles/forms';
import FormError from '../../../misc/FormError';
import FileUpload from '../../../misc/FileUpload';

class AddFeaturedProducts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            submittingNewFeatured: false,
            featuredPhoto: "",
            uploadFeaturedPhotoComplete: false,
        }
    }

    newFeaturedProducts = async (values, resetForm) => {
        if(!this.state.uploadFeaturedPhotoComplete){
            toast.error(`Upload featured product image first!`);
        } else if(values.groupType === "query" && !values.tag) {
            toast.error(`If the group type is a query, you need to add a tag!`);
        } else {
            this.setState({ submittingNewFeatured: true });
            const docId = urlify(values.name);
            const docRef = doc(firestore, "products", docId);
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
                console.log("Document exists:", docSnap.data());
                toast.error(`A product with that name already exists, try a new name!`);
                this.setState({ submittingNewProduct: false });
            } else {
                addDoc(collection(firestore, "featuredProducts"), {
                    name: values.name,
                    groupType: values.groupType,
                    tag: values.tag,
                    photo: this.state.featuredPhoto,
                    position: values.position,
                    timestamp: Date.now(),
                }).then(() => {
                    updateDoc(doc(firestore, "site", "counts"), {
                        featuredProducts: increment(1)
                    }).then(() => {
                        console.log(`Incremented featuredProducts count`)
                    }).catch(error => {
                        console.error(`Error incrementing featuredProducts count: ${error}`)
                    });
    
                    this.setState({
                        featuredPhoto: "",
                        uploadFeaturedPhotoComplete: false,
                        submittingNewFeatured: false 
                    });
                    this.props.toggleAddFeatured(false)
                    resetForm();
                    console.log(`Saved: `);
                    console.log(values);
                    toast.success(`Product submitted successfully!`);
                    window.scrollTo(0, 0);
                }).catch(error => {
                    console.error(`Error submitting product: ${error}`)
                    toast.error(`Error submitting product: ${error}`);
                    this.setState({ submittingNewProduct: false });
                });
            }
        }
    }

    setFeaturedPhoto = (urls) => {
        console.log("urls: ")
        console.log(urls)
        this.setState({
            featuredPhoto: urls,
            uploadFeaturedPhotoComplete: true
        })
    }
    
    render() {
        return (
            <div>
                <Hr/>
                <H2>Add a new featured product</H2>
                <Formik
                    initialValues={{
                        name: "",
                        groupType: "single",
                        tag: "",
                        position: 0,
                    }}
                    onSubmit={(values, actions) => {
                        this.newFeaturedProducts(values, actions.resetForm);
                    }}
                    enableReinitialize={false}
                    validationSchema={addFeaturedSchema}
                >
                    {props => (
                    <Form onKeyDown={onKeyDown}>
                        <Grid fluid>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Label>Featured Products Name:</Label>
                                    <br/>
                                    <FField
                                        type="text"
                                        onChange={props.handleChange}
                                        placeholder={"Awesome Mugs"}
                                        name="name"
                                        value={props.values.name || ''}
                                        onKeyUp={() => this.setState({ errors: { name: false } })}
                                        onClick={() => this.setState({ errors: { name: false } })}
                                        error={ ((props.errors.name && props.touched.name) || this.state?.errors?.name) ? 1 : 0 }
                                    />
                                    <FormError
                                        yupError={props.errors.name}
                                        formikTouched={props.touched.name}
                                        stateError={this.state?.errors?.name}
                                    /> 
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Label>Position:</Label>
                                    <br/>
                                    <FField
                                        type="number"
                                        onChange={props.handleChange}
                                        placeholder={0}
                                        name="position"
                                        value={props.values.position || ''}
                                        onKeyUp={() => this.setState({ errors: { position: false } })}
                                        onClick={() => this.setState({ errors: { position: false } })}
                                        error={ ((props.errors.position && props.touched.position) || this.state?.errors?.position) ? 1 : 0 }
                                    />
                                    <FormError
                                        yupError={props.errors.position}
                                        formikTouched={props.touched.position}
                                        stateError={this.state?.errors?.position}
                                    /> 
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Label>Featured product type?:</Label>
                                    <br />
                                    <RField
                                        type="radio"
                                        id="groupType"
                                        name="groupType"
                                        value={"single"}
                                        checked={props.values.groupType === "single"}
                                        onChange={() => props.setFieldValue("groupType", "single")}
                                    />
                                    <Body display="inline">
                                        Single product
                                    </Body>
                                    <br />
                                    <RField
                                        type="radio"
                                        id="groupType"
                                        name="groupType"
                                        value={"query"}
                                        checked={props.values.groupType === "query"}
                                        onChange={() => props.setFieldValue("groupType", "query")}
                                    />
                                    <Body display="inline">
                                        Tag of products
                                    </Body>
                                </Col>
                            </Row>
                            {props.values.groupType === "query" && (
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Label>Tag:</Label>
                                        <br/>
                                        <FField
                                            type="text"
                                            onChange={props.handleChange}
                                            placeholder={"trending bags"}
                                            name="tag"
                                            value={props.values.tag || ''}
                                            onKeyUp={() => this.setState({ errors: { tag: false } })}
                                            onClick={() => this.setState({ errors: { tag: false } })}
                                            error={ ((props.errors.tag && props.touched.tag) || this.state?.errors?.tag) ? 1 : 0 }
                                        />
                                        <FormError
                                            yupError={props.errors.tag}
                                            formikTouched={props.touched.tag}
                                            stateError={this.state?.errors?.tag}
                                        /> 
                                    </Col>
                                </Row>
                            )}
                            
                            {props.values.name && (
                                <Row>
                                    <Col xs={12}>
                                        <Label>Featured Product Images</Label>
                                        {(!this.state.uploadFeaturedPhotoComplete) && (
                                            <FileUpload
                                                name="featured-product-images"
                                                firestoreRef={`public/featuredProducts/${urlify(props.values.name)}`}
                                                selectBtn=""
                                                allowMultiple={false}
                                                accepts="image/png, image/jpg, image/jpeg"
                                                onUploadSuccess={this.setFeaturedPhoto}
                                                user={this.props.user}
                                            />
                                        )}
                                        {this.state.uploadFeaturedPhotoComplete && (
                                            <Body margin="0" size={SIZES.LG} color={this.props.theme.colors.green}>
                                                Featured products image uploaded successfully!
                                            </Body>
                                        )}
                                    </Col>
                                </Row>
                            )}
                            <Hr />
                            <Row center="xs">
                                <Col xs={12}>
                                    <Button 
                                        type="submit"
                                        disabled={this.state.submittingNewFeatured}
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Grid>
                    </Form>
                    )}
                </Formik>
            </div>
        )
    }
}

export default withTheme(withRouter(AddFeaturedProducts))
