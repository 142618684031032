import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet-async';
import { withTheme } from 'styled-components';
import { withRouter } from '../../../utils/hocs';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import algoliasearch from 'algoliasearch';

import { Hr, Img, ProductCard, Spinner, Wrapper } from '../../../utils/styles/misc';
import { Body, H1, H2, H3, H4, LLink } from '../../../utils/styles/text';
import { Button } from '../../../utils/styles/buttons';
import { DEFAULT_PRODUCT_IMAGES, SCHEMES, SIZES, TAGS } from '../../../utils/constants';
import { urlify } from '../../../utils/misc';

const algoliaClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID, 
    (process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_ALGOLIA_LIVE_SEARCH_KEY : process.env.REACT_APP_ALGOLIA_TEST_SEARCH_KEY)
);
const productsIndex = algoliaClient.initIndex((process.env.NODE_ENV !== 'development' ? 'products' : 'test_products'));

// function isNumeric(str) {
//     if (typeof str != "string") return false // we only process strings!  
//     return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
//            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
//   }

class ProductsQuery extends Component {
    constructor(props) {
        super(props)

        this.state = {
            products: [],
            currentPage: 0,
            loadingProducts: true,
            invalidSearch: false,
        }
    }
  
    componentDidMount = async () => {
        let querySet = this.props.params.querySet.split("=")
        console.log("querySet: ")
        console.log(querySet)
        if(querySet[0] === "search"){
            console.log("Searching...")
            productsIndex.search((querySet[1] === "all" ? "" : querySet[1]), {
                page: querySet[2] ? parseInt(querySet[2].split("_")[1]) : 0,
                filters: "isShown:true",
            }).then((response) => {
                console.log(response);
                this.setState({
                    products: response.hits,
                    currentPage: response.page,
                    totalPages: response.nbPages,
                    totalNumProducts: response.nbHits,
                    loadingProducts: false,
                })
            });
        } else if(querySet[0] === "tags"){
            console.log("Tagging...")
            productsIndex.search("", {
                tagFilters: querySet[1].split("_"),
                page: querySet[2] ? parseInt(querySet[2].split("_")[1]) : 0,
                filters: "isShown:true",
              }).then((response) => {
                console.log(response);
                this.setState({
                    products: response.hits,
                    currentPage: response.page,
                    totalPages: response.nbPages,
                    totalNumProducts: response.nbHits,
                    loadingProducts: false,
                })
            });
        } else {
            console.log("Unknown query type!")
            this.setState({
                products: [],
                currentPage: 0,
                loadingProducts: false,
                invalidSearch: true,
            })
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.params.querySet !== prevProps.params.querySet) {
          this.getPage();
        }
    } 

    componentWillUnmount(){
        if(this.unsubCounts){
            this.unsubCounts();
        }
    }
    
    getPage = async (direction = "") => {
        this.setState({
            loadingProducts: true
        })
        
        let querySet = this.props.params.querySet.split("=");

        let pageToGet = parseInt(querySet[2].split("_")[1]);

        if (direction === "next") {
            pageToGet = this.state.currentPage + 1;
        } else if (direction === "previous") {
            pageToGet = this.state.currentPage - 1;
        } else {
            pageToGet = this.state.currentPage;
        }

        let querySetWithoutPage = querySet[0].concat(`=${querySet[1]}`)

        // Update route for this page so we can go back in browser history to this page
        window.history.replaceState("", "", `${querySetWithoutPage}=page_${pageToGet}`);

        if(querySet[0] === "search"){
            console.log("Searching...")
            productsIndex.search((querySet[1] === "all" ? "" : querySet[1]), {
                page: pageToGet,
                filters: "isShown:true", // These filters need to be added as a Facet on the Algolia dashboard!
            }).then((response) => {
                console.log(response);
                this.setState({
                    products: response.hits,
                    currentPage: response.page,
                    totalPages: response.nbPages,
                    totalNumProducts: response.nbHits,
                    // productsPerPage: response.hitsPerPage,
                    loadingProducts: false,
                })
            });
        } else if(querySet[0] === "tags"){
            console.log("Tagging...")
            productsIndex.search("", {
                tagFilters: querySet[1].split("_"),
                page: pageToGet,
                filters: "isShown:true",
              }).then((response) => {
                console.log(response);
                this.setState({
                    products: response.hits,
                    currentPage: response.page,
                    totalPages: response.nbPages,
                    totalNumProducts: response.nbHits,
                    // productsPerPage: response.hitsPerPage, // this is changed in settings on algolia dashboard
                    loadingProducts: false,
                })
            });
        } else {
            console.log("Unknown query type!")
            this.setState({
                products: [],
                currentPage: 0,
                loadingProducts: false,
                invalidSearch: true,
            })
        }
        
        window.scrollTo(0, 0);
        
    }

    renderSpecialTag(tags){
        let verbiage = "";
        let isSpecial = false;
        if (tags.includes("new")) {
            verbiage = "NEW!"
            isSpecial = true;
        } else if (tags.includes("coming-soon")) {
            verbiage = "COMING SOON!"
            isSpecial = true;
        } else {
            verbiage = "."
            isSpecial = false;
        }
        
        return (
            <Body 
                size={SIZES.LG} 
                margin="5px" 
                hidden={!isSpecial}
                color={this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : this.props.theme.colors.tertiary} 
                bold
            >
                {verbiage}
            </Body>
        )
    }
      
    render() {
        if(this.state.loadingProducts){
            return (
                <>
                 <Wrapper>
                    <Helmet>
                        <title>Products {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
                    </Helmet> 
                    <H2>Loading... <Spinner /> </H2> 
                </Wrapper>
                </>
            )
        } else if(this.state.invalidSearch){
            return (
                <Wrapper>
                    <Helmet>
                        <title>Products {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
                    </Helmet> 
                    
                    <H1 margin="0 0 0 0" alignLeft>Invalid search</H1>
                    <Body>Sorry for the inconvenience, please return to the search page to try your query again! If you are still having trouble, contact <a href="mailto:help@minute.tech">help@minute.tech</a>.</Body>
                    
                    <LLink to="/products">
                        <Button>
                            <FaChevronLeft  />
                            &nbsp; Return to search
                        </Button>
                    </LLink>
                </Wrapper>
            )
        } else {
            const searchOrTags = this.props.params.querySet.split("=")[0];
            let namedTags = [];
            if (searchOrTags === "tags") {
                // Is tags type query not search, so we CAN render as path
                const queriedTags = this.props.params.querySet.split("=")[1].split("_")
                // Now loop through queried tags and see if they exist in categories constant. If they do, grab the label, if they don't, then return invalid search
                queriedTags.forEach((queriedTag, q) =>
                    TAGS.forEach((constantTag, c) => {
                        if (constantTag.abbrev === queriedTag) {
                            namedTags.push(constantTag);
                        }
                    })
                );
            }
            
            return (
                <Wrapper>
                    <Helmet>
                        <title>Products {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
                    </Helmet> 
                    
                    {/* <Button size={SIZES.SM} onClick={() => this.props.navigate(-1)} type="button">
                        <FaChevronLeft  />
                        &nbsp; Go back
                    </Button> */}
                    {namedTags.length > 0 && (
                        <Body margin="5px 0">
                            <LLink 
                                color={this.props.theme.colors.font.body} 
                                to={`/products`}
                                hovercolor={this.props.theme.colors.tertiary}
                            >
                                Products
                            </LLink>
                            &nbsp;/&nbsp;
                            <LLink 
                                color={this.props.theme.colors.font.body} 
                                hovercolor={this.props.theme.colors.tertiary} 
                                italic={namedTags.length === 1 ? true : undefined} 
                                to={`/products/query/tags=${namedTags[0].abbrev}=page_0`}
                            >
                                {namedTags[0].name}
                            </LLink>
                            {namedTags.length > 1 && (
                                <>
                                    &nbsp;/&nbsp;
                                    <LLink 
                                        color={this.props.theme.colors.font.body} 
                                        hovercolor={this.props.theme.colors.tertiary}
                                        italic={true}
                                        to={`/products/query/tags=${namedTags[1].abbrev}=page_0`}
                                    >
                                        {namedTags[1].name}
                                    </LLink>
                                </>
                            )} 
                        </Body>
                    )}
                    {namedTags.length === 0 && (
                        <Body margin="5px 0">
                            <LLink 
                                color={this.props.theme.colors.font.body} 
                                to={`/products`}
                                hovercolor={this.props.theme.colors.tertiary}
                            >
                                Products
                            </LLink>
                            &nbsp;/&nbsp;<i>{this.props.params.querySet.split("=")[1].split("_").join(", ")}</i>
                        </Body>
                    )}
                    <Body margin={"5px 0"}>Showing {this.state.totalNumProducts} results</Body>
                   
                    {this.state.products.length === 0 && (
                        <H3 color={this.props.theme.colors.red}>No products found for<br/>"{this.props.params.querySet.split("=")[1]}"</H3>
                    )}
                    {this.state.products.length !== 0 && (
                        <>
                        <Grid fluid>
                            <Row center="xs">
                                {
                                    this.state.products.map((product, p) => {
                                        return (
                                            <Col key={p} sm={12} md={6} lg={6} xl={3}>
                                                <LLink to={`/products/${urlify(product.name)}`}>
                                                {/* <LLink to={`/products/${urlify(product.name)}`} state={{ prevQuerySet: `${this.props.params.querySet}=${this.state.currentPage}` }}> */}
                                                    <ProductCard>
                                                        <div id="img-container">
                                                            <Img
                                                                src={product?.thumb ? product?.thumb : (product?.photos ? product?.photos[0] : DEFAULT_PRODUCT_IMAGES.MISSING)}
                                                                alt={`${product.name}`}
                                                            />
                                                        </div>
                                                        <div>
                                                            {this.renderSpecialTag(product._tags)}
                                                            <H4>{product.name}</H4>
                                                        </div>
                                                    </ProductCard>
                                                </LLink>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Grid>
                        <Hr />
                        <Grid fluid>
                            <Row center="xs" middle="xs">
                                <Col xs={12} sm={4}>
                                    {this.state.currentPage !== 0 && (
                                        <Button onClick={() => this.getPage("previous")}>
                                            <FaChevronLeft /> Previous page    
                                        </Button>
                                    )}
                                </Col>
                                <Col xs={12} sm={4}>
                                    <Body margin={"0"} size={SIZES.LG}>Page {this.state.currentPage + 1} of {this.state.totalPages}</Body>
                                </Col>
                                <Col xs={12} sm={4}>
                                    {this.state.currentPage + 1 !== this.state.totalPages && (
                                        <Button onClick={() => this.getPage("next")}>
                                            Next page <FaChevronRight /> 
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Grid>
                        </>
                    )}
                </Wrapper>
            );
        }
    }
}

export default withRouter(withTheme(ProductsQuery));