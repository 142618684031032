import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { FaChevronUp } from 'react-icons/fa';
import { withTheme } from 'styled-components';
import { SIZES } from '../../utils/constants';
import { withRouter } from '../../utils/hocs';

import { FooterContainer } from "../../utils/styles/footer";
import { FullWidth, Img } from '../../utils/styles/misc';
import { ALink, Body, LLink, SLink} from "../../utils/styles/text";

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = { 
          year: new Date().getFullYear(),
        };
    }
    
    scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    render() {
        return (
            <FooterContainer>
                <FullWidth />
                <Grid fluid style={{ margin: "25px 0" }}>
                    <Row center={SIZES.XS}>
                        <Col sm={12} lg={8} xl={10}>
                            <Row>
                                <Col md={12} lg={6} xl={3}>
                                    <LLink id="big-link" to="/products">
                                        Products
                                    </LLink>
                                </Col>
                                <Col md={12} lg={6} xl={3}>
                                    <LLink id="big-link" to="/what-we-do">
                                        What We Do
                                    </LLink>
                                </Col>
                                <Col md={12} lg={6} xl={3}>
                                    <LLink id="big-link" to="/who-we-are">
                                        Who We Are
                                    </LLink>
                                </Col>
                                <Col md={12} lg={6} xl={3}>
                                    <LLink id="big-link" to="/work-with-us">
                                        Work With Us
                                    </LLink>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{margin: "10px 0"}} middle="xs">
                        <Col md={12} lg={4}>
                            <Row start="lg" center="md" >
                                <Col xs={12}>
                                    <Body margin="0" size={SIZES.XS}>CLIK CLAK</Body>
                                    <Body margin="0" size={SIZES.XS}>Norwalk, CT</Body>
                                    <Body margin="0" size={SIZES.XS}>info@clikclak.com</Body>
                                    <Body margin="0" size={SIZES.XS}>ASI#: 98289  |  PPAI#: 217084</Body>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} lg={4}>
                            <Row center="xs">
                                <Col xs={12} style={{margin: "25px 0 10px 0"}}>
                                    <Img
                                        width={"175px"}
                                        src={require("../../assets/images/logos/cc-logo-v4.png")} 
                                    />
                                    
                                </Col>
                                <Col xs={12}>
                                    <Body size={SIZES.XS} margin="0">Clik Clak &copy; {this.state.year}</Body>
                                    <Body size={SIZES.XS} margin="0"><LLink to="/privacy-policy">Privacy Policy</LLink></Body>
                                    <Body size={SIZES.XS} margin="0"><LLink to="/terms-conditions">Terms &amp; Conditions</LLink></Body>
                                    <Body size={SIZES.XS}>All other trademarks and copyrights are property of their respective owners.</Body>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} lg={4}>
                            <Row end="lg" center="md">
                                <Col xs={12}>
                                    <ALink href="https://www.wbenc.org/" target="_blank" rel="noopener">
                                        <Img
                                            width={"150px"}
                                            src={this.props.theme.value !== "dark" ? require("../../assets/images/misc/wbenc-new.png") : require("../../assets/images/misc/women-owned-white.png")}
                                        />
                                    </ALink>
                                </Col>
                            </Row>
                            
                        </Col>
                        
                        <Col xs={12} style={{margin: "10px 0"}}>
                            <SLink onClick={() => this.scrollTop()}>
                                Back to top <FaChevronUp style={{ verticalAlign: "middle", display: "inline", paddingBottom: "0.5%" }} /> 
                            </SLink>
                        </Col>
                    </Row>
                </Grid>
            </FooterContainer>
        )
    }
}

export default withTheme(withRouter(Footer))