import React, { Component } from 'react'
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';

import { withRouter } from './utils/hocs';
import { Wrapper } from './utils/styles/misc';
import { urlify } from './utils/misc';
import { projects } from './utils/constants';

// Pages //
// Misc
import Home from './components/pages/misc/Home';
import WhoWeAre from './components/pages/misc/WhoWeAre';
import WhatWeDo from './components/pages/misc/WhatWeDo';
import WorkWithUs from './components/pages/misc/WorkWithUs';
// Products
import Products from './components/pages/products/Products';
import ProductPage from './components/pages/products/ProductPage';
import ProductsQuery from './components/pages/products/ProductsQuery';

import Credits from './components/pages/misc/Credits';
import PrivacyPolicy from './components/pages/misc/PrivacyPolicy';
import TermsConditions from './components/pages/misc/TermsConditions';
import ErrorBoundary from './components/pages/misc/ErrorBoundary';
import Page404 from './components/pages/misc/Page404';
import Product404 from './components/pages/misc/Product404';
// User
import Login from './components/pages/user/auth/Login';
import Register from './components/pages/user/auth/Register';
import LoggingIn from './components/pages/user/auth/LoggingIn';
import Dashboard from './components/pages/user/dashboard/Dashboard';
import Profile from './components/pages/user/dashboard/Profile';
// Admin
import AdminDashboard from './components/pages/user/admin/AdminDashboard';
import ManageMessages from './components/pages/user/admin/ManageMessages';
import ManageUsers from './components/pages/user/admin/ManageUsers';
import ManageProducts from './components/pages/user/admin/ManageProducts';
import ManageFeaturedProducts from './components/pages/user/admin/ManageFeaturedProducts';
import CustomProjects from './components/pages/misc/CustomProjects';

class Views extends Component {
    constructor(props) {
        super(props)

        this.state = {
            projects: projects
        }
    }

    render() {
        return (
            <Routes>
                {/* Anyone routes */}
                <Route 
                    index 
                    path="/" 
                    element={<ErrorBoundary><Home site={this.props.site} /></ErrorBoundary>}
                />
                

                <Route path={"/products"} element={<Outlet />}>
                    <Route 
                        index
                        element={
                            <Products
                                site={this.props.site}
                            />
                        }
                    />

                    <Route 
                        path={"/products/query/:querySet"}
                        element={
                            <ProductsQuery
                                site={this.props.site}
                            />
                        }
                    />

                    {
                        this.props.products.map((product, p) => {
                            let productUrl = urlify(product.name);
                            if(product.isShown || this.props.readOnlyFlags.isAdmin){
                                return (
                                    <Route 
                                        key={p}
                                        path={productUrl} 
                                        element={
                                            <ErrorBoundary>
                                                <ProductPage
                                                    product={product}
                                                    readOnlyFlags={this.props.readOnlyFlags}
                                                    site={this.props.site} 
                                                />
                                            </ErrorBoundary>
                                        }
                                    />
                                );
                            } else {
                                return null;
                            }
                            
                        })
                    }
                    
                    <Route path="*" element={<ErrorBoundary><Product404 site={this.props.site} /></ErrorBoundary>} />
                </Route>
                <Route 
                    path="/what-we-do" 
                    element={<ErrorBoundary><WhatWeDo site={this.props.site} /></ErrorBoundary>}
                />

                <Route 
                    path="/who-we-are" 
                    element={<ErrorBoundary><WhoWeAre site={this.props.site} /></ErrorBoundary>}
                />

                <Route 
                    path="/work-with-us" 
                    element={<ErrorBoundary><WorkWithUs site={this.props.site} /></ErrorBoundary>}
                />

                <Route 
                    path="/credits" 
                    element={<ErrorBoundary><Credits site={this.props.site} /></ErrorBoundary>}
                />

                <Route 
                    path="/custom-projects" 
                    element={<ErrorBoundary><CustomProjects site={this.props.site} /></ErrorBoundary>}
                />

                <Route 
                    path="/privacy-policy" 
                    element={<ErrorBoundary><PrivacyPolicy site={this.props.site} /></ErrorBoundary>}
                />

                <Route 
                    path="/terms-conditions" 
                    element={<ErrorBoundary><TermsConditions site={this.props.site} /></ErrorBoundary>}
                />

                <Route 
                    path="/logging-in" 
                    element={
                        <ErrorBoundary>
                            <LoggingIn
                                site={this.props.site} 
                                fireUser={this.props.fireUser}
                                userLoggingIn={this.props.userLoggingIn}  
                            />
                        </ErrorBoundary>
                    }
                />
                
                {/* Visitor ONLY routes */}
                <Route 
                    element={
                        <ErrorBoundary>
                            <VisitorRoutes 
                                site={this.props.site} 
                                isUser={this.props.fireUser} 
                                isLoggingIn={this.props.isLoggingIn} 
                            />
                        </ErrorBoundary>
                    }
                >
                    <Route 
                        path="/register" 
                        element={
                            <Register 
                                site={this.props.site} 
                                fireUser={this.props.fireUser}
                                userLoggingIn={this.props.userLoggingIn}
                            />
                        }
                    />
                    <Route 
                        path="/login"
                        element={
                            <Login 
                                site={this.props.site} 
                                fireUser={this.props.fireUser}
                                userLoggingIn={this.props.userLoggingIn}
                            />
                        }
                    />
                </Route>
                
                {/* User ONLY routes */}
                <Route 
                    element={
                        <ErrorBoundary>
                            <UserRoutes 
                                site={this.props.site} 
                                isUser={this.props.fireUser} 
                                isLoggingIn={this.props.isLoggingIn} 
                            />
                        </ErrorBoundary>
                    }
                >
                    <Route 
                        path="dashboard"
                        element={  
                            <Wrapper>
                                <Outlet />
                            </Wrapper> 
                        }
                    >
                        <Route 
                            index
                            element={
                                <Dashboard 
                                    site={this.props.site} 
                                    fireUser={this.props.fireUser} 
                                    readOnlyFlags={this.props.readOnlyFlags}
                                    user={this.props.user}
                                    userLoggingOut={this.props.userLoggingOut} 
                                />
                            }
                        />
                        <Route 
                            path="profile" 
                            element={
                                <Profile 
                                    site={this.props.site} 
                                    fireUser={this.props.fireUser} 
                                    readOnlyFlags={this.props.readOnlyFlags}
                                    user={this.props.user}
                                />
                            }
                        />
                        {/* Admin ONLY routes */}
                        <Route 
                            element={
                                <ErrorBoundary>
                                    <AdminRoutes 
                                        site={this.props.site} 
                                        isAdmin={this.props?.readOnlyFlags?.isAdmin} 
                                        isLoggingIn={this.props.isLoggingIn} 
                                    />
                                </ErrorBoundary>
                            }
                        >
                            <Route 
                                path="admin" 
                                element={ <Outlet /> }
                            >
                                <Route 
                                    index
                                    element={
                                        <AdminDashboard
                                            site={this.props.site} 
                                            fireUser={this.props.fireUser} 
                                            readOnlyFlags={this.props.readOnlyFlags}
                                            user={this.props.user}
                                        />
                                    }
                                />
                                <Route 
                                    path="products" 
                                    element={
                                        <ManageProducts
                                            site={this.props.site} 
                                            products={this.props.products}
                                            fireUser={this.props.fireUser} 
                                            readOnlyFlags={this.props.readOnlyFlags}
                                            user={this.props.user}
                                        />
                                    }
                                />
                                <Route 
                                    path="messages" 
                                    element={
                                        <ManageMessages
                                            site={this.props.site} 
                                            fireUser={this.props.fireUser} 
                                            readOnlyFlags={this.props.readOnlyFlags}
                                            user={this.props.user}
                                        />
                                    }
                                />
                                <Route 
                                    path="featured-products" 
                                    element={
                                        <ManageFeaturedProducts
                                            site={this.props.site} 
                                            fireUser={this.props.fireUser} 
                                            readOnlyFlags={this.props.readOnlyFlags}
                                            user={this.props.user}
                                        />
                                    }
                                />
                                <Route 
                                    path="users" 
                                    element={
                                        <ManageUsers
                                            site={this.props.site} 
                                            fireUser={this.props.fireUser} 
                                            readOnlyFlags={this.props.readOnlyFlags}
                                            user={this.props.user}
                                        />
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                </Route>

                <Route path="*" element={<ErrorBoundary><Page404 site={this.props.site} /></ErrorBoundary>} />
            </Routes>
        )
    }
}

function VisitorRoutes({ isUser, isLoggingIn }) {
    let location = useLocation();
    if (isUser && !isLoggingIn) {
        // ** ID needed to be defined so doesnt render twice:
        // https://stackoverflow.com/questions/62578112/react-toastify-showing-multiple-toast
        toast.warn("Sorry, but you need to be signed out to access this page.", {
            toastId: 'visitor',
        });
        return <Navigate to="/dashboard" state={{ from: location }} />;
    } else {
        return <Outlet />;
    }
  
}

function UserRoutes({ isUser, isLoggingIn }) {
    let location = useLocation();
    if (!isUser && !isLoggingIn) {
        toast.warn("Sorry, but you need to be signed in to access this page.", {
            toastId: 'user',
        });
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
    } else {
        return <Outlet />;
    }
}

function AdminRoutes({ isAdmin, isLoggingIn }) {
    let location = useLocation();
    if (!isAdmin && !isLoggingIn) {
        toast.warn("Sorry, but you need to be an administrator to access this page.", {
            toastId: 'admin',
        });
        return <Navigate to="/dashboard" state={{ from: location }} />;
    } else {
        return <Outlet />;
    }
}



export default withRouter(Views);