import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { withTheme } from 'styled-components';
import Faq from 'react-faq-component';

import { FullWidthMap, Wrapper, Img, FullWidthHeaderImg, FaqContainer, MapContainer } from '../../../utils/styles/misc';
import { BoldHeading, FaqH3, H1, Li, Ul } from '../../../utils/styles/text';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { BiPlus } from 'react-icons/bi';

class WhatWeDo extends Component {
  render() {
    return (
        <>
        <Helmet>
            <title>What We Do {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
        </Helmet>
        
        <FullWidthHeaderImg src={require("../../../assets/images/what-we-do/WHAT_WE_DO_PG_BANNER.jpg")} height={"600px"}>
            <div>
                <H1 color="black" margin="0" size={"78px"}><BoldHeading>WE MAKE IT HAPPEN.</BoldHeading></H1>
            </div>
        </FullWidthHeaderImg>
        <Wrapper padding={"6vh 0"}>
            <H1>What We Do</H1>
            <FaqContainer>
                <Faq 
                    data={data}
                    styles={{
                        transitionDuration: "0.5s",
                        timingFunc: "linear",
                        bgColor: this.props.theme.colors.background,
                        arrowColor: this.props.theme.colors.font.body,
                    }}
                    config={{
                        arrowIcon: <BiPlus size={25}/>,    
                        expandIcon: "+",
                        collapseIcon: "-",
                        tabFocus: true,
                        animate: true,
                    }}
                />
            </FaqContainer>
        
        </Wrapper>
        <MapContainer>

            <FullWidthMap src={require("../../../assets/images/misc/map-outline.png")} />
        </MapContainer>
        </>
    );
  }
}

const data = {
    rows: [
      {
        title:<FaqH3>Design and Development</FaqH3>,
        content: <>
            <Grid fluid>
                <Row>
                    <Col lg={12} xl={6}>
                        <Ul margin="20px 0">
                            <Li>Product knowledge and inspiration</Li>
                            <Li>Presentations and decks</Li>
                            <Li>Virtuals and renderings</Li>
                            <Li>Trend forecasting and industry research</Li>
                            <Li>Tech packs</Li>
                            <Li>Package design</Li>
                            <Li>Swag boxes and strategic marketing initiatives</Li>
                        </Ul>
                    </Col>
                    <Col lg={12} xl={6}>
                        <Img
                            width={"100%"}
                            src={require("../../../assets/images/what-we-do/SERVICES_DESIGN_AND_DEVELOPMENT.jpg")}
                            alt={"sustainable"}
                            style={{borderRadius: "20px"}}
                        />
                    </Col>
                </Row>
            </Grid>
        </>
      },
      {
        title:<FaqH3>Sourcing and Manufacturing</FaqH3>,
        content: <>
            <Grid fluid>
                <Row>
                    <Col lg={12} xl={6}>
                        <Ul margin="20px 0">
                            <Li>Domestic and overseas sourcing and manufacturing </Li>
                            <Li>Vast network of trusted factories</Li>
                            <Li>Capabilities across a wide variety of modalities</Li>
                            <Li>Strong relationships with worldwide suppliers </Li>
                            <Li>Expedited development and pre-production sampling </Li>
                            <Li>Fast production turnaround </Li>
                            <Li>Low custom MOQs</Li>
                        </Ul>
                    </Col>
                    <Col lg={12} xl={6}>
                        <Img
                            width={"100%"}
                            src={require("../../../assets/images/what-we-do/SOURCING_MANUFACTURING.jpg")}
                            alt={"sustainable"}
                            style={{borderRadius: "20px"}}
                        />
                    </Col>
                </Row>
            </Grid>
        </>
      },
      {
        title:<FaqH3>Delivery and Logistics</FaqH3>,
        content: <>
            <Grid fluid>
                <Row>
                    <Col lg={12} xl={6}>
                        <Ul margin="20px 0">
                            <Li>Global operations team</Li>
                            <Li>Wide network of freight partners</Li>
                            <Li>Fulfillment and warehousing </Li>
                            <Li>Drop shipping and kitting</Li>
                            <Li>Quality control: testing, auditing and inspecting</Li>
                            <Li>Strict adherence to global manufacturing standards and ethics</Li>
                        </Ul>
                    </Col>
                    <Col lg={12} xl={6}>
                        <Img
                            width={"100%"}
                            src={require("../../../assets/images/what-we-do/SERVICES_DELIVERY_FULFILLMENT_AND_LOGISTICS.jpg")}
                            alt={"sustainable"}
                            style={{borderRadius: "20px"}}
                        />
                    </Col>
                </Row>
            </Grid>
        </>
      },
      {
        title:<FaqH3>Sustainability</FaqH3>,
        content: <>
            <Grid fluid>
                <Row>
                    <Col lg={12} xl={6}>
                        <Ul margin="20px 0">
                            <Li>Commitment to sustainability throughout the supply chain</Li>
                            <Li>Use of eco-friendly materials and packaging when possible</Li>
                            <Li>Factory compliance with environmental regulations and standards</Li>
                            <Li>Solar-powered main office and warehouse facility</Li>
                            <Li>BSCI, Sedex, and OEKO-TEX certified vendors</Li>
                            <Li>Mitigation of environmental footprint through carbon offsets</Li>
                        </Ul>
                    </Col>
                    <Col lg={12} xl={6}>
                        <Img
                            width={"100%"}
                            src={require("../../../assets/images/what-we-do/SERVICES_SUSTAINABILITY.jpg")}
                            alt={"sustainable"}
                            style={{borderRadius: "20px"}}
                        />
                    </Col>
                </Row>
            </Grid>
        </>
      },
    ]
  }

export default withTheme(WhatWeDo)