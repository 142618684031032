import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { Helmet } from 'react-helmet-async';
import { withTheme } from 'styled-components';
import { SCHEMES, SIZES } from '../../../utils/constants';

import { Centered, FlipCardBack, FlipCardContainer, FlipCardFront, Hr, Img, Spinner, Wrapper } from '../../../utils/styles/misc';
import { ALink, Body, H1, H2, OurStorySection } from '../../../utils/styles/text';

const Headshot = (props) => {
    return (
        <>
        <Img
            width={"200px"}
            alt="headshot"
            src={props.picPath}
        />
        <Centered>
          <Body margin="0px" size={SIZES.XL} style={{ textTransform: 'uppercase'}}>{props.name}</Body>
          <Body margin="0px" size={SIZES.SM} color={props.theme.colors.grey} style={{ textTransform: 'uppercase'}}>{props.title}</Body>
        </Centered>
        </>
    )
  } 

  const flipCards = [
    {
        title: "Disrupters",
        body: "We don't follow trends, we make them. From the beginning, we've gone where others haven't, redefining what promotional merchandise can, and should, be.",
        bgPath: require("../../../assets/images/who-we-are/disrupters.jpg"),
        bgColor: "#f4d7e8"
    },
    {
        title: "Creators",
        body: "At square one and not sure where to begin? Have a general concept but need help with the details? Our team works with you to bring your product to life, from inspiration and ideas to product illustrations, custom artwork, and packaging.",
        bgPath: require("../../../assets/images/who-we-are/creators.jpg"),
        bgColor: "#d8bfdb"
    },
    {
        title: "Problem Solvers",
        body: "We say yes when others say no.  Whatever the idea, we always do our very best to find a way and make it happen. ",
        bgPath: require("../../../assets/images/who-we-are/problem-solvers.jpg"),
        bgColor: "#b7e2c8"
    },
    {
        title: "Partners",
        body: "Our design, production, and logistics teams are there with you every step of the way, making sure your project gets produced, on time and gets where it needs to be, when it needs to be there, at the best price possible. And if there are any bumps in the road, we're here to work through them together.  We aren't just looking for orders, we are looking to build relationships.",
        bgPath: require("../../../assets/images/who-we-are/partners.jpg"),
        bgColor: "#efceab"
    },
  ]

class WhoWeAre extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fakeLoading: true, // ** this is put in place to fix the learn more button being pressed on homepage, it would auto click the first card on mobile
            expanded: [
                false, false, false, false
            ],
            clikClakTeam: [
                {
                    name: "Amy Katz",
                    title: "Co-founder",
                    picPath: require("../../../assets/images/headshots/AMY_HS_FINAL.jpg"),
                },
                {
                    name: "Donna Slavitt",
                    title: "Co-founder",
                    picPath: require("../../../assets/images/headshots/DONNA_HS.jpg"),
                },
                {
                    name: "Gianni Calisi",
                    title: "Creative Director",
                    picPath: require("../../../assets/images/headshots/GIANNI_HS.png"),
                },
                {
                    name: "Reena Hall",
                    title: "Accounting & Operations",
                    picPath: require("../../../assets/images/headshots/REENA_HS.png"),
                },
                {
                    name: "Paola Mejia",
                    title: "Customer Service",
                    picPath: require("../../../assets/images/headshots/PAOLA_HS.jpg"),
                },
                {
                    name: "Laura Lawson",
                    title: "Product Development",
                    picPath: require("../../../assets/images/headshots/LAURA_HS.jpg"),
                },
                {
                    name: "Fernando Gomez",
                    title: "Operations & Logistics ",
                    picPath: require("../../../assets/images/headshots/FERNANDO_HS.jpg"),
                },
                {
                    name: "Anthony Yau",
                    title: "Warehouse & Shipping",
                    picPath: require("../../../assets/images/headshots/ANTHONY_HS.jpg"),
                },
            ]
        }
    }

    toggleCardClick = (index) => {
        let tempExpanded = [false, false, false, false];
        tempExpanded[index] = !this.state.expanded[index];
        this.setState({
            expanded: tempExpanded
        })
    }

    componentDidMount(){
        this.timer = setTimeout(() => {
            this.setState({
                fakeLoading: false,
            })
        }, 500);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    } 

  render() {
    if(this.state.fakeLoading === true){
        return (
            <Wrapper>
                <H2>Loading... <Spinner /> </H2> 
            </Wrapper>
        )
    } else {
        return (
            <>
            <Helmet>
                <title>Who We Are {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
            </Helmet>
            <Wrapper style={{ position: "relative", zIndex: "1" }}>
                <H1>Who We Are</H1>
                <Grid fluid>
                    <Row>
                        {flipCards.map((card, c) => {
                            return (
                                <Col lg={12} xl={6} key={c}>
                                    <FlipCardContainer flipped={this.state.expanded[c]} onClick={() => this.toggleCardClick(c)}>
                                        <div className="fcard-inner">
                                            <FlipCardFront>
                                                <H1 normalCase><i>{card.title}</i></H1>
                                            </FlipCardFront>
                                            <FlipCardBack color={card.bgColor} $bgImg={card.bgPath}>
                                                <div>
                                                    <Body>
                                                        {card.body}
                                                    </Body> 
                                                </div>   
                                            </FlipCardBack>
                                        </div>
                                    </FlipCardContainer>
                                </Col>
                            )
                        })}
                    </Row>
                </Grid>
            </Wrapper>
            <Grid fluid>
                <Row center="xs" middle="xs">
                    <Col lg={12} xl={6}>
                        <OurStorySection>
                            <H1>Our Story</H1>
                            <Body>
                                Amy Katz and Donna Slavitt are the founders of Clik Clak. Amy and Donna met in college and launched their careers in corporate law and restaurant consulting, respectively. 
                                They took their unique knowledge and experience and reunited in 1996 to launch Clik Clak. From Manhattan to Brooklyn to Norwalk, Clik Clak has grown into one of the most unique and leading promotional suppliers in the industry. 
                                From day one, Clik Clak has stood apart. It all started when Donna and Amy introduced mints in tins to the promotional world. 
                                They revolutionized product offerings, and now 25 years later, their product line continues to stand out by including the most innovative items in the industry. 
                                With their creative flair and business acumen, they break barriers, grow the business and disrupt the industry. 
                                Donna and Amy are passionate, incredibly driven, creative, and problem solvers, all of which have made Clik Clak a leader in the field.
                            </Body>
                            <Body margin={"5%"} color={this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : this.props.theme.colors.tertiary} center>
                                Clik Clak has been a leading promotional supplier for over 25 years. 
                                We are CREATIVE, we INSPIRE, we are FAST, we are COMPETITIVE, we are PASSIONATE, we DELIVER. 
                            </Body>
                            <Body margin={"5%"} color={this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : this.props.theme.colors.tertiary} center>
                                We want to be your PARTNER and bring YOUR vision to life.
                            </Body>
                            <Body margin={"5%"} color={this.props.theme.value !== SCHEMES.DARK ? this.props.theme.colors.primary : this.props.theme.colors.tertiary} center>
                                Clik Clak is a 100% woman owned company.
                            </Body>
                            <Centered>
                                <ALink href="https://www.wbenc.org/" target="_blank" rel="noopener">
                                    <Img 
                                        width="225px" 
                                        margin="15px"
                                        src={this.props.theme.value !== "dark" ? require("../../../assets/images/misc/wbenc-new.png") : require("../../../assets/images/misc/women-owned-white.png")}
                                        alt={"WBENC Certified"}
                                    />
                                </ALink>
                            </Centered>
                        </OurStorySection>
                    </Col>
                    <Col lg={12} xl={6}>
                        <Img 
                            width="700px" 
                            src={require("../../../assets/images/headshots/ABOUT_MYANDDONNA.jpg")}
                            alt={"team shot"}
                        />
                    </Col>
                </Row>
            </Grid>
            <Wrapper padding="50px 0 0 0">
                
                <Hr/>
                
            </Wrapper>
            <Centered padding={"50px 0"}>
                <Img 
                    width="700px" 
                    src={require("../../../assets/images/misc/amy-donna-guy.JPG")}
                    alt={"team shot"}
                />
            </Centered>
            <Wrapper padding="0">
                
                <Hr/>
                <Grid fluid>
                    <Row center="xs">
                        <Col xs={12}>
                            <H1>Our Team</H1>
                        </Col>
                    </Row>
                    <Row>
                        {this.state.clikClakTeam.map((member, m) => {
                            return (
                                <Col key={m} xs={12} sm={6} md={4} style={{ paddingBottom: "25px", textAlign: "center"}}>
                                    <Headshot 
                                        name={member.name}
                                        title={member.title}
                                        picPath={member.picPath}
                                        theme={this.props.theme}
                                    />
                                </Col>
                                
                            )
                        })}
                    </Row>
                </Grid>
            </Wrapper>
            </>
        );
    }
    
  }
}

export default withTheme(WhoWeAre)