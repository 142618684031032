import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { withTheme } from 'styled-components';

import { SIZES, TAGS } from '../../../utils/constants';
import { Button } from '../../../utils/styles/buttons';
import { urlify } from '../../../utils/misc';
import { Centered, Div, ProductsTopWrapper, TimelineContainer, TimelineWrapper } from '../../../utils/styles/misc';
import { Body, H1, H2, LLink } from '../../../utils/styles/text';
import { withRouter } from '../../../utils/hocs';
import { TimelineCard, TimelineDropdown, TimelineDdLink } from '../../../utils/styles/misc';

class Products extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        submittingSearch: false,
        deviceWidth: window.innerWidth,
        timelines: [
            {
                name: "in stock",
                details: "Our in-house line of product: we stock it, print it, and ship it!",
                bgImg: require("../../../assets/images/timelines/STOCK.jpg"),
                // Remember to also replace Products page rconst state output of each constant tags!
                categories: [
                    "bags-pouches",
                    "candles",
                    "beauty",
                    "confections",
                    "travel",
                    "fun-games",
                    "masks",
                ],
                expanded: false
            },
            {
                name: "quick-turn",
                details: "The best of both worlds: custom product, shipped fast!",
                bgImg: require("../../../assets/images/timelines/QT.jpg"),
                categories: [
                    "socks",
                    "totes-duffles",
                    "cosmetic-bags-pouches",
                    "backpacks",
                    "fanny-packs-crossbody-bags",
                    "candles",
                    "beauty",
                    "keychains-accessories",
                    "stickers-patches-pins",
                    "hats-scarves",
                    "fun-games",
                    "jewelry",
                    "glasses",
                ],
                expanded: false
            },
            {
                name: "custom",
                details: "Developed from scratch: you dream it, we make it!",
                bgImg: require("../../../assets/images/timelines/CUSTOM.jpg"),
                categories: [],
                expanded: false
            },
        ]
      }
    }

    updateDimensions = () => {
        this.setState({ deviceWidth: window.innerWidth });
    };

    componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions);
    }
    
    toggleCardClick = (index) => {
        let tempTimelines = this.state.timelines;
        tempTimelines[index].expanded = !this.state.timelines[index].expanded
        this.setState({
            timelines: tempTimelines
        })
    }

    willScrollPeak = (timelineName) => {
        // After first hover, dont keep triggering this because its annoying
        if(timelineName !== "custom" && !this.state.scrollPeakDone){
            window.scrollTo({ top: 200, behavior: 'smooth' });
            this.setState({ scrollPeakDone: true });
        }
    }

    render() {
        return (
            <div style={{position: "relative", background: "black", paddingBottom: "1000px"}}>
                <ProductsTopWrapper>
                    <Helmet>
                        <title>Products {this.props.site.name ? `| ${this.props.site.name}` : ""}</title>
                    </Helmet>
                    <H1 margin="0" color="white">Products</H1>
                    <Centered>
                        <LLink to="/products/query/search=all=page_0">
                            <Body color="white" margin="0 0 25px 0" size={SIZES.LG} hovercolor={this.props.theme.colors.primary}>
                                View all products
                            </Body>
                        </LLink>
                    </Centered>
                    
                </ProductsTopWrapper>
                <TimelineWrapper>
                {
                        this.state.timelines.map((timeline, t) => {
                            return (
                                <TimelineContainer $expanded={timeline.expanded} key={t}>
                                    <TimelineCard 
                                        $bgImg={timeline.bgImg} 
                                        onClick={() => this.toggleCardClick(t)} 
                                        onMouseOver={() => this.willScrollPeak(timeline.name)}
                                    >
                                        <H2>{timeline.name}</H2>
                                        <Centered margin="0"> 
                                            <LLink to={timeline.name === "custom" ? "/custom-projects" : `/products/query/tags=${urlify(timeline.name)}=page_0`}>
                                                <Button 
                                                    type="button"
                                                    color={"black"}
                                                    size={SIZES.SM}
                                                >
                                                    View all
                                                </Button>
                                            </LLink>
                                        </Centered>
                                        <Body bold center style={{ padding: "0 10px" }}>{timeline.details}</Body>
                                    </TimelineCard>
                                
                                    {timeline.categories.length > 0 && (
                                        <TimelineDropdown id="timeline-dropdown">
                                            {timeline.categories.map((category, c) => {
                                                return (
                                                    <Div margin="15px 0" key={c}>
                                                        <TimelineDdLink to={`/products/query/tags=${urlify(timeline.name)}_${category}=page_0`}>
                                                            <span>
                                                                { TAGS.find(tag => tag.abbrev === category).name }
                                                            </span>
                                                        </TimelineDdLink>
                                                    </Div>
                                                );
                                            })}
                                        </TimelineDropdown>
                                    )}
                                </TimelineContainer>
                            );
                        })
                    }
                </TimelineWrapper>
                       
            </div>
           
        );
    }
}

export default withTheme(withRouter(Products));